import React from 'react';
import Header from "../components/Header/Header";
import ModalOpenWorkspace from '../components/Modal/ModalOpenWorkspace';
import ModalSaveWorkspaceAs from '../components/Modal/ModalSaveWorkspaceAs';
import ModalSendMessage from '../components/Modal/ModalSendMessage';
import imageFile from '../../img/finEDge_logo.svg';
import {STATUS} from '../../actions/actiontypes'

export default class ClientHeader extends React.Component {
    image;
    btns;
    counterInterval;
    distance;
    counterColor;
    constructor(props) {
        super(props);
        this.state = { 
        counter         : this.initialCounter
        ,openWorkspaceDlg : false
        ,openSaveWorkspaceAsDlg : false
        ,openCardDlg : false
        ,openChallengeDlg : false
        ,referenceToggleName: "Reference Period"
        ,openSendAdminMessageDlg : false
        }

        this.image = {
            src: imageFile,
            alt: 'Finedge, Navigating Financial Decisions',
        };

        this.btns = [
        {
          label: 'Reference Period',
          button: 'reference'
        }
        ];
        this.openWorkspace              = this.openWorkspace.bind(this);
        this.restoreWorkspace           = this.restoreWorkspace.bind(this);
        this.startcounter               = this.startcounter.bind(this);
        this.enableCountdown            = this.enableCountdown.bind(this);
        this.handleOpenWorkspace        = this.handleOpenWorkspace.bind(this);
        this.handleCloseModalWorkspace  = this.handleCloseModalWorkspace.bind(this);
        this.saveWorkspace              = this.saveWorkspace.bind(this);
        this.saveWorkspaceAs            = this.saveWorkspaceAs.bind(this);
        this.handleSaveWorkspaceAs      = this.handleSaveWorkspaceAs.bind(this);
        this.handleCloseSaveWorkspaceAs = this.handleCloseSaveWorkspaceAs.bind(this);
        this.openAddCardDlg             = this.openAddCardDlg.bind(this);
        this.handleAddCard              = this.handleAddCard.bind(this);
        this.handleCloseModalCardDlg    = this.handleCloseModalCardDlg.bind(this);
        this.openChallenge              = this.openChallenge.bind(this);
        this.handleOpenChallenge        = this.handleOpenChallenge.bind(this);
        this.handleCloseModalChallengeDlg= this.handleCloseModalChallengeDlg.bind(this);
        this.confirmAction              = this.confirmAction.bind(this);
        this.displayWorkspaces          = this.displayWorkspaces.bind(this);
        this.handleReferenceToggle      = this.handleReferenceToggle.bind(this);
        this.sendMessage                = this.sendMessage.bind(this);
        this.handleSendMessage          = this.handleSendMessage.bind(this);
        this.handleCloseSendMessage     = this.handleCloseSendMessage.bind(this);
        this.viewRanking                = this.viewRanking.bind(this);
        this.deleteWorkspace            = this.deleteWorkspace.bind(this);
        this.openDoc           		 	= this.openDoc.bind(this);

    }
        
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ((!prevProps.currentChallenge.hasOwnProperty("challengeid")
                && this.props.currentChallenge.hasOwnProperty("challengeid"))
            || 
            (prevProps.currentChallenge.hasOwnProperty("challengeid")
                && this.props.currentChallenge.hasOwnProperty("challengeid")
                && (prevProps.currentChallenge.challengeid !== this.props.currentChallenge.challengeid))
            )
        {
            if (this.props.currentChallenge.challengestatus === STATUS.START)
            {
                this.restartCountdown();
            }
            else
            {
                this.resetCountdown();
            }
        }
        else if ((prevProps.currentChallenge.hasOwnProperty("challengeid")
            && this.props.currentChallenge.hasOwnProperty("challengeid")
            && (prevProps.currentChallenge.challengeid === this.props.currentChallenge.challengeid))
            && ((prevProps.currentChallenge.challengestatus !== this.props.currentChallenge.challengestatus)
            ))
        {
            switch (this.props.currentChallenge.challengestatus)
            {
                case STATUS.INITIAL:  
                case STATUS.ACTIVE: 
                case STATUS.RESET:  
                    this.resetCountdown();
                break;  
                case STATUS.START: 
                    this.restartCountdown(); 
                break;
                case STATUS.PAUSE:
                case STATUS.STOP:
                case STATUS.BLUR:
                    this.disableCountdown();
                break;
                default:
                break;
            }
        }
        else if (this.props.currentChallenge.hasOwnProperty("challengeid")
                 &&(this.props.currentChallenge.challengestatus === STATUS.START)
                 &&(prevProps.currentChallenge.hasOwnProperty("currentsegment")
                 && this.props.currentChallenge.hasOwnProperty("currentsegment")
                 && prevProps.currentChallenge.currentsegment !== this.props.currentChallenge.currentsegment))
        {
            this.restartCountdown();
        }
        else if ((prevProps.currentChallenge.hasOwnProperty("challengeid")
                && this.props.currentChallenge.hasOwnProperty("challengeid")
                && (prevProps.currentChallenge !== this.props.currentChallenge))
            )
        {
            if (this.props.currentChallenge.challengestatus === STATUS.ACTIVE)
            {
                this.resetCountdown();
            }
        }
    }
    /*
    enableCountdown()
    {            
        let [hourStr, minStr, secStr] = this.state.counter.split(":");
        let hour = parseInt(hourStr);
        let min = parseInt(minStr);
        let sec = parseInt(secStr);
        
        let countDownDate = new Date().getTime() + (hour*60*60*1000) + (min*60*1000) + (sec*1000) ;
        let now = new Date().getTime();
        this.distance = (countDownDate - now)/1000;
        
        this.counterInterval = setInterval(this.startcounter, 1000);
    }
    
    startcounter()
    { 
        --this.distance;        
        // Time calculations for hours, minutes and seconds
        let hours = Math.floor(this.distance / (60 * 60));
        let minutes = Math.floor((this.distance % (60 * 60)) / 60);
        let seconds = Math.floor(this.distance % 60);
        
        if (hours === 0 && minutes === 0 && seconds === 30)
        {
            document.getElementById("countdowntimer").style.color = "#FFFF00";
        }  
        if (hours === 0 && minutes === 0 && seconds === 10)
        {
            document.getElementById("countdowntimer").style.color = "#FF0000";
        }            
        this.setState({counter : String(hours).padStart(2,"0") 
                                + ":"+String(minutes).padStart(2,"0")
                                + ":"+String(seconds).padStart(2,"0")
            });
        if (hours === 0 && minutes === 0 && seconds === 0)
        {
            this.disableCountdown();
            return;
        }
    }
    */
    enableCountdown()
    {            
        if (this.counterInterval !== undefined)
        {
            this.disableCountdown();
        }
        let [minStr, secStr] = this.state.counter.split(":");
        let min = parseInt(minStr);
        let sec = parseInt(secStr);
        
        let countDownDate = new Date().getTime() + (min*60*1000) + (sec*1000) ;
        let now = new Date().getTime();
        this.distance = (countDownDate - now)/1000;
        
        this.counterInterval = setInterval(this.startcounter, 1000);
    }
    
    startcounter()
    { 
        --this.distance;        
        // Time calculations for hours, minutes and seconds
        let minutes = Math.floor((this.distance/ 60));
        let seconds = Math.floor(this.distance % 60);
        
        let oldCounterColor = this.counterColor;
        this.counterColor = this.colorSelect(minutes, seconds);
        //some optimization 
        if (this.counterColor !== oldCounterColor)
        {
            document.getElementById("countdowntimer").style.color = this.counterColor;
            oldCounterColor = this.counterColor;
        }
        
        if (minutes < 0 && seconds < 0)
        {
            this.disableCountdown();
            this.greyset = false;
            return;
        }
        this.setState({counter : String(minutes).padStart(2,"0")
                                + ":"+String(seconds).padStart(2,"0")
            });
        if (minutes === 0 && seconds === 0)
        {
            this.disableCountdown();
            return;
        }
    }
    
    
    colorSelect(minutes, seconds)
    {
        if (minutes > 0)
        {
            return "";
        }
        else if (minutes === 0)
        {
            if (seconds > 30)
            {
            return "";
            }
            else if ((seconds < 31) && (seconds > 10))
            {
                return "#FFFF00";
            }
            else if (seconds < 11)
            {
                return "#FF0000";
            }
        }
    }
    
    resetCountdown()
    {   
        this.disableCountdown();
        this.initCountdown();        
    }
    
    disableCountdown()
    {        
        if (this.counterInterval !== undefined)
        {
            clearInterval(this.counterInterval);
        }
    }
    
    setInitColor()
    {
        let [minStr, secStr] = this.props.currentChallenge.segmentduration.split(":");
        let min = parseInt(minStr);
        let sec = parseInt(secStr);
        let initColor = this.colorSelect(min, sec);
        document.getElementById("countdowntimer").style.color = initColor;
    }
    
    initCountdown()
    {
        if (this.props.currentChallenge.hasOwnProperty("segmentduration"))
        {
            this.setInitColor();
            this.setState({counter : this.props.currentChallenge.segmentduration});
        }
    }
    
    restartCountdown()
    {
        if (this.props.currentChallenge.hasOwnProperty("segmentduration"))
        {
            this.setInitColor();
            this.setState({counter : this.props.currentChallenge.segmentduration}, this.enableCountdown);
        }
    }
    
    isValid()
    {
        if (!this.props.currentChallenge.hasOwnProperty("challengeid"))
        {
            this.props.setErrorMessage("Invalid challengeid or challenge not created ");
            return false;
        }
        return true;
    }
    
    signOut()
    {
        document.cookie = "sessiontoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        document.cookie = "userinfo= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.reload(true);
        console.log("signout");
    }
    
    restoreWorkspace()
    {
        console.log("restoreWorkspace");
        this.props.restoreWorkspace();
    }
    
    openWorkspace()
    {
        console.log("openWorkspace");
        this.setState({openWorkspaceDlg: true});
    }
    
    saveWorkspace()
    {
        console.log("saveWorkspace");
        if (this.props.appList.name !== "default")
        {
            this.props.setWorkspaceStatus(STATUS.PERSIST);
        }
        else
        {   
            this.saveWorkspaceAs();
        }
    }
    
    saveWorkspaceAs()
    {
        console.log("saveWorkspaceAs");
        this.setState({openSaveWorkspaceAsDlg: true});
        //this.props.setWorkspaceStatus(STATUS.PERSIST);
    }
    
    handleSaveWorkspaceAs()
    {
        let name = document.getElementById("saveworkspaceas").value;
        let pref = document.getElementById("msw.saveasdefault").checked;
        let data = {
            name : name
            ,id : name.replace(" ", "_")
            ,preferred: pref
        }
        this.props.setWorkspaceData(data);
        this.props.setWorkspaceStatus(STATUS.PERSIST);

        this.setState({openSaveWorkspaceAsDlg: false});
    }
    
    handleCloseSaveWorkspaceAs()
    {
        console.log("handleCloseSaveWorkspaceAs");
        this.setState({openSaveWorkspaceAsDlg: false});
    }
    
    sendMessage()
    {
        console.log("sendMessage");
        this.setState({openSendAdminMessageDlg: true});
    }

    viewRanking()
    {
        console.log("viewRanking");
        this.props.showRankings(true);
    }

    handleSendMessage()
    {
        let name = document.getElementById("sendadminmessage").value;
        let data = {
            challengeid: this.props.currentChallenge.challengeid
            ,message : name
        }
        this.props.sendAdminMessage(data);
        this.setState({openSendAdminMessageDlg: false});
    }

    handleCloseSendMessage()
    {
        console.log("handleCloseSendMessage");
        this.setState({openSendAdminMessageDlg: false});
    }

    handleOpenWorkspace(data)
    {
        this.props.setApplist(data);
        this.setState({openWorkspaceDlg: false});
    }

    handleCloseModalWorkspace()
    {
        console.log("handleCloseModalWorkspace");
        this.setState({openWorkspaceDlg: false});
    }

    openAddCardDlg()
    {
        console.log("openAddCardDlg");
        this.setState({openCardDlg: true});
    }
    
    handleAddCard(id)
    {
        console.log("handleAddCard");
        let data = {x: 0, y: 0, w:6, h:8, i:id, minW:2, minH:5};
        this.props.addNewCard(data);
    }
    
    handleCloseModalCardDlg()
    {
        console.log("handleCloseModalCardDlg");
        this.setState({openCardDlg: false});
    }
    
    displayMissingCards()
    {
        let missingCards = [];
        Object.keys(this.props.cards).forEach((cardkey) => {
            let found = this.props.appList.layout.find(currentCard=> {
                return currentCard.i ===  cardkey;
            });
            if (found === undefined)
            {
                missingCards.push({k: cardkey, v: this.props.cards[cardkey]});
            }                
        });
        return missingCards;
    }
    displayAllChallenges()
    {
        let challengesObj = this.props.challenges.map(challenge=>(
            {k: challenge.challengeid, v: challenge.challengename}));
        return challengesObj;
    }
    displayWorkspaces()
    {
        let workspaceObj = Object.keys(this.props.workspace.workspaces).map(workspace=>(
            {k: workspace, v: this.props.workspace.workspaces[workspace]}
            ));
        return workspaceObj;
    }
    openChallenge()
    {
        console.log("openChallenge");
        this.props.getAllChallenges("ACTIVE");
        this.setState({openChallengeDlg: true});
    }
    
    handleCloseModalChallengeDlg()
    {
        console.log("handleCloseModalChallengeDlg");
        this.setState({openChallengeDlg: false});
    }
    
    handleOpenChallenge(id)
    {
        console.log("handleOpenChallenge for "+id);
        let challengeid = parseInt(id);
        this.props.getChallenge(challengeid);
        let challenge = this.props.challenges.find(data=>{
                              return data.challengeid === challengeid;
                            });
        this.props.getEquitiesInPortfolio(challenge.portfoliouniverseid);
        this.props.getChallengeStats(challengeid);
        this.props.subscribeChallenge(challengeid);
        this.props.getPosition(challengeid);
        this.props.getReturns(challengeid);
        this.props.catchupData(challengeid);
        this.props.getUniverseReferenceData(challengeid);
        this.setState({openChallengeDlg: false});
    }
    
    handleReferenceToggle()
    {        
        if (this.state.referenceToggleName === "Portfolio")
        {
            this.props.setApplist("previous");
            this.setState({referenceToggleName: "Reference Period"});
        }
        else
        {
            this.props.setApplist("reference");
            this.setState({referenceToggleName: "Portfolio"});
        }
    }
    
    deleteWorkspace(name, id)
    {
        let deleteData = {name: name, preferencetype:"client", id: id};
        console.log("deleteWorkspace: "+JSON.stringify(deleteData));
        let dialogData = {
            title: "Delete workspace"
            ,message: "Are you sure you want to delete this workspace: "+name+"?"
            ,proceedFunction: this.props.deleteWorkspace.bind(this,deleteData)
        };
        
        this.props.openDialogMessage(dialogData);
    }
    
	openDoc()
	{
		window.open("/docs/manual.html");
	}
	
    confirmAction()
    {
        console.log("confirmAction");
        let dialogData = {
            title: "Run command"
            ,message: "Are you sure you want to run this command?"
        }
        this.props.openDialogMessage(dialogData);
    }
    
    render()
    {
        let totalValue = this.props.holding.holdingValue + this.props.holding.cashBalance;
        const headerprops = {
        logoLink         :this.image.src
        ,logoAlt          :this.image.alt
        ,counter          :this.state.counter
        ,segmentCurrent   :this.props.currentChallenge.currentsegment
        ,segmentTotal     :this.props.currentChallenge.totalsegment
        ,dayCurrent       :this.props.currentChallenge.currentday
        ,dayTotal         :this.props.currentChallenge.totaldays
        ,buttonData       :this.btns
        ,challengeName    :this.props.currentChallenge.challengename
        ,referenceToggleName    :this.state.referenceToggleName
        ,topPerformers    :this.props.currentChallenge.topPerformers
        ,bottomPerformers :this.props.currentChallenge.bottomPerformers
        ,portfolioValue   :this.props.holding.holdingValue.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
        ,cashAvailable    :this.props.holding.cashBalance.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
        ,totalValue       :totalValue.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
        ,quizBonus        :this.props.holding.quizBonus.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
        ,beta             :this.props.holding.beta.toLocaleString()
        ,portofolioReturn :(((totalValue - this.props.holding.cash)/this.props.holding.cash) * 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
        ,signOutClick             : this.signOut
        ,addCardClick             : this.openAddCardDlg      
        ,openWorkspaceClick       : this.openWorkspace
        ,restoreWorkspaceClick    : this.restoreWorkspace
        ,saveWorkspaceClick       : this.saveWorkspace
        ,saveWorkspaceAsClick     : this.saveWorkspaceAs
        ,newChallengeClick        : this.newChallenge 
        ,cloneChallengeClick      : this.cloneChallenge 
        ,openChallengeClick       : this.openChallenge
        ,referenceClick           : this.handleReferenceToggle
        ,sendMessageClick         : this.sendMessage
        ,viewRankingClick         : this.viewRanking
		,openDoc				  : this.openDoc
        ,username                 : this.props.userinfo.fname + " " + this.props.userinfo.lname
        };
		
        return (
        <div>
        <Header {...headerprops}/>
            <ModalOpenWorkspace 
                        data={this.displayWorkspaces()} 
                        handleOpenWorkspace={this.handleOpenWorkspace} 
                        openModal={this.state.openWorkspaceDlg}
                        handleClose = {this.handleCloseModalWorkspace}
                        title={"Open a workspace"}
                        canDelete={true}
                        deleteItemClick={this.deleteWorkspace}
            />
            <ModalSaveWorkspaceAs 
                        handleSaveWorkspaceAs={this.handleSaveWorkspaceAs} 
                        openModal={this.state.openSaveWorkspaceAsDlg}
                        handleClose = {this.handleCloseSaveWorkspaceAs}
            />
            <ModalOpenWorkspace 
                        data={this.displayMissingCards()} 
                        handleOpenWorkspace={this.handleAddCard} 
                        openModal={this.state.openCardDlg}
                        handleClose = {this.handleCloseModalCardDlg}
                        title={"Add card"}
                        canDelete={false}
            />
            <ModalOpenWorkspace 
                        data={this.displayAllChallenges()} 
                        handleOpenWorkspace={this.handleOpenChallenge} 
                        openModal={this.state.openChallengeDlg}
                        handleClose = {this.handleCloseModalChallengeDlg}
                        title={"Open Challenge"}
                        canDelete={false}
            />
            <ModalSendMessage 
                        handleSendMessage={this.handleSendMessage} 
                        openModal={this.state.openSendAdminMessageDlg}
                        handleClose = {this.handleCloseSendMessage}
            />
        </div>
        )
    }
}   