import React from 'react';
import TreeMap, { Tooltip } from 'devextreme-react/tree-map';
import '../../css/table.css';
//import {XAxis, YAxis, FlexibleXYPlot, LabelSeries, HeatmapSeries } from 'react-vis';

export default class TrendingStocks extends React.Component {
          
  constructor(props) {
    super(props);   

        this.customizeTooltip = this.customizeTooltip.bind(this);
  }
  
//  render(){
//       return (
//              <FlexibleXYPlot>
//              <HeatmapSeries
//                className="heatmap-series-example"
//                colorRange={["red", "yellow"]}
//                data={this.props.trendingStock}/>
//                <LabelSeries
//                data={this.props.trendingStock}/>
//        </FlexibleXYPlot>
//  );
//  }

    customizeTooltip(arg) {
    let data = arg.node.data;
      return {
        text: arg.node.isLeaf() ?
          `<span class="city">${data.company}</span> (${data.label})` :
          null
        };
    }
    
    render(){
       return (
               <div className="trending-contain">
                   <TreeMap
                      id="treemap"
                      dataSource={this.props.trendingStock}
                      valueField="value"
                      labelField="label"
                    >
                      <Tooltip
                        enabled={true}
                        format="thousands"
                        customizeTooltip={this.customizeTooltip}
                      />
                    </TreeMap>
                </div>
  );
  }
}
