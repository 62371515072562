import React from 'react';
import ReactDataGrid from 'react-data-grid';
import Chart, {
  CommonSeriesSettings
  ,Series
  ,ValueAxis
  ,ArgumentAxis
  ,Legend
  ,Tooltip
  ,Title
  ,Grid
  ,Format
  ,ZoomAndPan
} from 'devextreme-react/chart';

export default class StockDetails extends React.Component {
          
    constructor(props) {
        super(props);

        this.palette =['#F44336','#c3a80f','#4fc620']; 
        this.state = {
            lastDrawLocation: null
            ,crosshairValues: []
        };

    }  
    
    pctFormatter(value)
    {
        return `${value}%`;
    }
    
    shouldComponentUpdate(nextProps, nextState)
    {
        return true;
    }
    
    customizeTooltip(pointInfo) 
    {
        const items = pointInfo.valueText.split('\n');
        const color = pointInfo.point.getColor();

        items.forEach((item, index) => {
        if(item.indexOf(pointInfo.seriesName) === 0) {
          const element = document.createElement('span');

          element.textContent = item;
          element.style.color = color;
          element.className = 'active';

          items[index] = element.outerHTML;
        }
        });

        return { text: items.join('\n') };
    }
 
    onLegendClick({ target: series }) 
    {
        if(series.isVisible()) 
        {
            series.hide();
        } 
        else 
        {
            series.show();
        }
    }
    
    render(){
       return (
        <div className="sd_contain">
            <div className="smallerleft">
                {this.props.stockDetail.hasOwnProperty("equityid") ?
                (<div className="stock_info_contain"><p/>
                    <span className="stock_title">{this.props.stockDetail.equityname}</span><p/>
                    <div className="sd_section">
                        <span className="sd_label"> P/E Ratio:</span><span className="sd_data"> {this.props.stockDetail.peratio.toLocaleString()}</span>
                        <span className="sd_label"> EPS:</span><span className="sd_data"> {this.props.stockDetail.eps.toLocaleString()}</span>
                        <span className="sd_label"> Market Cap:</span><span className="sd_data"> {this.props.stockDetail.marketcap.toLocaleString()}</span>
                        <span className="sd_label"> Avg Volume:</span><span className="sd_data"> {this.props.stockDetail.avgvolume.toLocaleString()}</span>
                        <span className="sd_label"> Beta:</span><span className="sd_data"> {this.props.stockDetail.beta.toLocaleString()}</span>
                    </div><p/>
                    <div className="sd_description">
                        description of stock: {this.props.stockDetail.longdescription}
                    </div>
                </div>
                ) : (<span></span>)
                }
            </div>
            <div className="largerright">
            <Chart
                    id="StockDetailsChart"
                    palette="Vintage"
                    onLegendClick={this.onLegendClick}
                    dataSource={this.props.stockStats}
                    commonSeriesSettings={{
                        argumentField:"day"
                        ,point:{visible: false}
                        ,barPadding:0.1
                    }}
                    >
                    <Series
                      axis="volVal"
                      type="bar"
                      valueField="volume"
                      name="Volume"
                      color="#E6ECBB"
                    />
                    <Series
                      axis="returnPct"
                      type="spline"
                      valueField="pctval"
                      name={this.props.stockDetail.equityname !== undefined ? this.props.stockDetail.equityname : "Equity" }
                      color="#8BC34A"
                    />
                    <ValueAxis
                      name="returnPct"
                      position="left"
                      title={{text:"Return", font:{size: "11px"}}}
                      label={{format:this.pctFormatter}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ValueAxis
                      name="volVal"
                      position="right"
                      title={{text:"Volume", font:{size: "11px"}}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ArgumentAxis
                      name="returnArg"
                      minorTickInterval={1}
                      defaultVisualRange={{ length: 20 }} 
                      visualRangeUpdateMode={"auto"}/>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      font={{size: "11px"}}
                    />
                    <Tooltip
                      enabled={true}
                      shared={true}
                      customizeTooltip={this.customizeTooltip}
                    >
                      <Format
                        type="largeNumber"
                        precision={1}
                      />
                    </Tooltip>
                    <ZoomAndPan argumentAxis="both" />
            </Chart>
            </div>
        </div>
    );
    }
}
