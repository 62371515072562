import React from 'react';

import './Card.scss';

const MobileCard = props => {
  const {cardid} = props;
  return (
    <div className="app_card" id={cardid}>
      <div className="card_body">
        {props.children}
      </div>
    </div>
  )
}

export default MobileCard;
