import React from 'react';
import PropTypes from 'prop-types';
import './Formatter.scss';

class DecimalFormatter extends React.Component {
    constructor(props) {
        super(props);
    }  

    shouldComponentUpdate(nextProps) {
        return nextProps.value !== this.props.value;
    }

    getClass()
    {
        let tmp = this.props.value;
        if (tmp < 0)
        {
            return "negativedata";
        }
        return "positivedata";
    }
    
    getValueFormat()
    {
        let retval = "";
        let tmp = this.props.value;
        if (tmp  !== "")
        {
            if (tmp < 0)
            {
                retval = "("+this.props.value.toFixed(2).toLocaleString()+")";
            }
            else
            {
                retval = this.props.value.toFixed(2).toLocaleString();
            }
        }
        return retval;
    }
    
    render() {
        return <div title={this.props.value}><span className={this.getClass()}>{this.getValueFormat()}</span></div>;
    }
}

export default DecimalFormatter;