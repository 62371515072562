import React from 'react';
import ReactDataGrid from 'react-data-grid';
import '../../css/table.css';
import CheckboxForTable from '../components/CheckboxForTable';
import RemoveFromTable from '../components/RemoveFromTable';
import PctDisplay from '../components/PctDisplay';
import CommaFormatter from '../components/CommaFormatter';
import DecimalFormatter from '../components/DecimalFormatter';

import {STATUS, SIDE, ORDERTYPE} from '../../actions/actiontypes'
import { ReactReduxContext } from 'react-redux'
import _ from "lodash";

export default class MobileHoldingCreation extends React.Component {
          
    aeColumns;
    puColumns;
    unsubscribe;
    static contextType = ReactReduxContext;
    constructor(props) {
        super(props);
        this.defaultColumnProperties = {
            resizable: true
        };
           
        this.state = {
            filteredSRows : props.allEquitiesRows
            ,filteredHRows : props.equitiesInCurrentHolding
        }   

        this.onGridRowsUpdated      = this.onGridRowsUpdated.bind(this); 
        this.tradeOut               = this.tradeOut.bind(this);          
        this.sortSRows              = this.sortSRows.bind(this);
        this.sortHRows              = this.sortHRows.bind(this);
        this.sortNow                = this.sortNow.bind(this);
    }    
        
    getHoldingCols()
    {  
        let columns = [
            { key: 'equityid', name: 'x', formatter:<RemoveFromTable/>, events:{
                                                                              onClick:(ev,data)=>{this.handleRemove(ev, data); }}, width: 55,sortable: false}
                ,{ key: 'equityname', name: 'Name' , width: 80, sortable: true}
                ,{ key: 'lastprice', name: 'Price', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
                ,{ key: 'quantity', name: 'Qty', width: 50, editable: true, formatter:<CommaFormatter/>, sortable: true} 
                ,{ key: 'pctportfolio', name: 'Port%', width: 50, editable: true, formatter:<PctDisplay/>, sortable: true} 
                ,{ key: 'value', name: 'Value', width: 50, editable: true, formatter:<DecimalFormatter/>, sortable: true} 
                ,{ key: 'segmentpl', name: 'Seg P&L', width: 100, formatter:<DecimalFormatter/>, sortable: true} 
                ,{ key: 'totalpl', name: 'Total P&L', width: 100, formatter:<DecimalFormatter/>, sortable: true} 
            ].map(c => ({ ...c, ...this.defaultColumnProperties }));  
        return columns;
    }
    
    getCols()
    {
        let columns = [
                { key: 'equityid', name: '+', frozen:true, formatter:<CheckboxForTable checkClass={this.props.equityCheckClass}/>, events:{
                                                                                  onClick:(ev,data)=>{this.handleChange(ev, data); }}, width: 45, sortable: false}
                ,{ key: 'equityname', name: 'Name' , width: 80, frozen:true, sortable: true} 
                ,{ key: 'lastprice', name: 'Price', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
                ,{ key: 'startprice', name: 'Start Px', width: 60, formatter:<DecimalFormatter/>, sortable: true} 
                ,{ key: 'dailyreturn', name: 'Daily Return', width: 80, formatter:<PctDisplay/>, sortable: true} 
                ,{ key: 'volume', name: 'Volume', width: 59, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'company', name: 'Company', width: 130, sortable: true} 
          ,{ key: 'marketcapcategory', name: 'Category', width: 50, sortable: true} 
          ,{ key: 'marketcap', name: 'Market Cap', width: 50, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'avgvolume', name: 'Avg Volume', width: 50, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'peratio', name: 'P/E Ratio', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'ninetydayvol', name: '90 Day Vol', width: 50, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'thirtydayavg', name: '30 Day Avg', width: 50, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'fiftytwoprice', name: '52 wk Price', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'fiftytworeturn', name: '52 Return', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'periodreturn', name: 'Period Return', width: 50, formatter:<PctDisplay/>, sortable: true} 
          ,{ key: 'yield', name: 'Yield', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'beta', name: 'Beta', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'eps', name: 'EPS', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
            ].map(c => ({ ...c, ...this.defaultColumnProperties }));
        return columns;
    }
       
          
          
    handleChange (ev,data)  {    
        const idx = data.rowIdx;
        const equitydata = this.props.allEquitiesRows[idx];
        const pudata = this.props.equitiesInCurrentHolding.filter(item => item.equityid === equitydata.equityid)
        if  (pudata.length == 0)
        {
            console.log("adding to pu ");
            this.props.addEquitiesToHolding(equitydata);           
        }
        else
        {
            this.checkPosition(pudata[0]);
            //this.props.removeEquitiesFromHolding(equitydata);
        }
    }
          
    savePortfolioUniverse=()=>{
        let equities = this.props.equitiesInCurrentHolding.map(function(equity, i) {
            return equity.equityid;
        });

         const pname = document.getElementById('portfolioUniverseName').value;
         let data = {action:"createportfoliouniverse"
                        ,param:{"portfolioname":pname, "equities":equities}};
        this.props.onCreatePortfolioClick(data);
    }
 
    handleRemove (ev,data)  {   
        const idx = data.rowIdx;
        const equitydata = this.props.equitiesInCurrentHolding[idx];   
        this.checkPosition(equitydata)
            //this.props.removeEquitiesFromHolding(equitydata);
            console.log("handleRemove");
    }

    checkPosition(equitydata)
    {
        if (!(equitydata.hasOwnProperty("quantity") 
            && equitydata.quantity !== undefined
            && equitydata.quantity > 0
        ))
        {
            console.log("checkPosition can't find quantity so ok to remove "+equitydata.quantity);            
            this.props.removeEquitiesFromHolding(equitydata);
            return;
        }
        console.log("checkPosition quantity found "+equitydata.quantity);
        console.log("confirmAction");
        
        let dialogData = {
            title: "Trade out?"
            ,message: "You can only remove a holding if the quantity is zero. Are you sure you want to sell: "
                        +equitydata.quantity+" shares of "+equitydata.equityname+"?"
            ,proceedFunction: this.tradeOut
            ,proceedArg: equitydata
        }
        this.props.openDialogMessage(dialogData);  
    }
    
    tradeOut(equitydata)
    {
        console.log("checkPosition agreed to trade out");
        this.executeTrade(equitydata, equitydata.quantity, SIDE.ASK);
    }
    
    shouldComponentUpdate(nextProps, nextState)
    {
        let returnVal = false;
        if (!_.isEqual( this.props.allEquitiesRows, nextProps.allEquitiesRows ))
        {
            this.setState({filteredSRows : nextProps.allEquitiesRows});
            returnVal = true;
        }
        if (!_.isEqual( this.props.equitiesInCurrentHolding, nextProps.equitiesInCurrentHolding ))
        {
            this.setState({filteredHRows : nextProps.equitiesInCurrentHolding});
            returnVal = true;
        }
        if (!_.isEqual( this.state.filteredSRows, nextState.filteredSRows )
            || !_.isEqual( this.state.filteredHRows, nextState.filteredHRows ))
        {
            returnVal = true;
        }
        return returnVal;
    }
    
    
    sortSRows(initialRows, sortColumn, sortDirection)
    { 
        this.setState({filteredSRows: this.sortNow(initialRows, sortColumn, sortDirection)});
    }
    sortHRows(initialRows, sortColumn, sortDirection)
    { 
        this.setState({filteredHRows: this.sortNow(initialRows, sortColumn, sortDirection)});
    }
  
    sortNow(initialRows, sortColumn, sortDirection)
    {
        let rows = initialRows;
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } 
            else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return (sortDirection === "NONE" ? initialRows : [...rows].sort(comparer));
    }
    
    
    validateTrade(holdingdata)
    {
        if (holdingdata.pctportfolio > 10)
        {
            this.props.setWarningMessage("Single stock can't comprise more that 10% of portfolio " +holdingdata.equityname+" is "+holdingdata.pctportfolio+"%");
            holdingdata.pctportfolio = 10;
            this.setFromPctValue(holdingdata);
        }
        
        // check they arent spending more than they have
        let additionalCost = holdingdata.value;
        if (holdingdata.hasOwnProperty("oldcost"))
        {
            additionalCost = additionalCost - holdingdata.oldcost;            
        }
        if (additionalCost > this.props.cashBalance)
        {
            this.props.setWarningMessage("cash balance exceeded! resetting to quantity to reflect balance for " +holdingdata.equityname+" is "+holdingdata.pctportfolio+"%");
            holdingdata.value = this.props.cashBalance;            
            this.setFromStockValue(holdingdata);
        }
        holdingdata.oldcost = holdingdata.value;
    }
  
    setFromStockValue(holdingdata)
    {
        holdingdata.quantity = parseInt(holdingdata.value/holdingdata.lastprice);
        //update value again
        holdingdata.value = (holdingdata.quantity * holdingdata.lastprice);
        holdingdata.pctportfolio = parseInt((holdingdata.value/this.props.cash)*10000)/100.0;
    }
    
    setFromPctValue(holdingdata)
    {
        holdingdata.value = (this.props.cash *(holdingdata.pctportfolio/100));
        this.setFromStockValue(holdingdata);
    }
    
    onGridRowsUpdated({ fromRow, toRow, updated }) {         
            console.log("onGridRowsUpdated "+updated);
            if (updated === undefined)
            {
                return;
            }
        for (let i = fromRow; i <= toRow; i++) 
        {
            let currentHolding = this.props.equitiesInCurrentHolding[i];
            let previousQty = currentHolding.hasOwnProperty("quantity") ? currentHolding.quantity : 0;
            let holdingdata = {...currentHolding, ...updated};
            if (currentHolding.lastprice === undefined)
            {
                this.props.setWarningMessage("Market isn't available for "+currentHolding.equityname);
                continue;
            }
            if (holdingdata.hasOwnProperty("quantity") && isNaN(holdingdata.quantity))
            {
                this.props.setErrorMessage("quantity is not a number "+holdingdata.quantity);
                continue;
            }
            if (holdingdata.hasOwnProperty("value") && isNaN(holdingdata.value))
            {
                this.props.setErrorMessage("value is not a number "+holdingdata.quantity);
                continue;
            }
            if (holdingdata.hasOwnProperty("pctportfolio") && isNaN(holdingdata.pctportfolio))
            {
                this.props.setErrorMessage("percent is not a number "+holdingdata.pctportfolio);
                continue;
            }
            if (currentHolding.quantity !== holdingdata.quantity && holdingdata.quantity !== undefined)
            {
                holdingdata.value = (holdingdata.quantity * currentHolding.lastprice);
                holdingdata.pctportfolio = parseInt((holdingdata.value/this.props.cash)*10000)/100.0;
            }
            else if (currentHolding.pctportfolio !== holdingdata.pctportfolio && holdingdata.pctportfolio !== undefined)
            {
                if (currentHolding.pctportfolio > 100)
                {
                    this.props.setErrorMessage("Single stock can't be greater than 100% of portfolio " +holdingdata.equityname+" is "+holdingdata.pctportfolio+"%");
                    return;
                }
                this.setFromPctValue(holdingdata);
            }
            else if (currentHolding.value !== holdingdata.value && holdingdata.value !== undefined)
            {
                this.setFromStockValue(holdingdata);
            }
            
            this.validateTrade(holdingdata);
            let quantity = Math.abs(holdingdata.quantity - previousQty);
            if (quantity === 0)
            {
                this.props.setWarningMessage("cannot trade zero (0) quantity");
                return;                
            }
            let side = holdingdata.quantity > previousQty ? SIDE.BID : SIDE.ASK;
            this.executeTrade(holdingdata, quantity, side);
        }      
    }
    
    executeTrade(holdingdata, quantity, side)
    {
        console.log("quantity to trade = " + quantity);
        let tradeData = {
                    action:           "insertorder"
                    ,param: {
                        equityid:   holdingdata.equityid
                        ,equityname:     holdingdata.equityname
                        ,company:        holdingdata.company
                        ,sector:         holdingdata.sector
                        ,lastprice:      holdingdata.lastprice
                        ,challengeid:   this.props.currentChallenge.challengeid
                        ,price:         holdingdata.price
                        ,quantity:      quantity
                        ,side:          side
                        ,tradetype:    ORDERTYPE.MARKET
                        }
        };
        
        this.props.modifyHolding(holdingdata);
        this.props.insertOrder(tradeData);
        this.props.setInfoMessage("sent order to " + (side == SIDE.BID ? "buy " : "sell ") + quantity + " of "+holdingdata.equityname);        
    }
        
    render(){
        return (
        <div className="mobile_top_bottom_card">
            <div className="mobilehalfTabletop">
            <p></p><span className="label"> Stock Universe</span>
                <ReactDataGrid  columns={this.getCols()}
                          rowGetter={i => this.state.filteredSRows[i]}
                          rowsCount={this.state.filteredSRows.length}
                          minHeight={230} 
                          rowHeight={20}
                          onGridSort={(sortColumn, sortDirection) =>{
                            this.sortSRows(this.state.filteredSRows,sortColumn, sortDirection)}
                          }
                />
            </div>
            <div className="mobilehalfTablebottom">
            <div><p></p><span className="label">Portfolio</span>
            </div>
            <ReactDataGrid  columns={this.getHoldingCols()}
                          rowGetter={i => this.state.filteredHRows[i]}
                          rowsCount={this.state.filteredHRows.length}
                        minHeight={200} 
                        rowHeight={20}
                        onGridSort={(sortColumn, sortDirection) =>
                                this.sortHRows(this.state.filteredHRows,sortColumn, sortDirection)
                        }
                        onGridRowsUpdated={this.onGridRowsUpdated}
                        enableCellSelect={true}
              />
            </div>
        </div>
    );
    }
}