import { connect } from 'react-redux'
import EconomicIndicators from "../jsx/client/EconomicIndicators";

const mapStateToProps = state => {
  return {
    economicindicators : state.economicindicators
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const EconomicIndicatorsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EconomicIndicators)

export default EconomicIndicatorsContainer
