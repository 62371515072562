import { connect } from 'react-redux'
import MobilePortfolioComposition from "../jsx/client/MobilePortfolioComposition";


const getSectorCapData =(state)=> {
    let sectordata = state.holding.equitiesInCurrentHolding.reduce((sectors, holding)=>{
                            if (!holding.hasOwnProperty("value") 
                                || (holding.hasOwnProperty("value") && holding.value < 1))
                            {
                                return sectors;
                            }
                            let currentValue = holding.value + (isNaN(holding.segmentpl) ? 0 : holding.segmentpl);
                            let foundSector = false;
                            let foundMcap = false;
                            let sector = {};
                            let mcap = {};
                            for (sector of sectors.sector)
                            {
                                if (sector["label"] === holding.sector)
                                {
                                    sector["angle"] = sector["angle"] + currentValue;
                                    foundSector = true;
                                }
                            }
                            for (mcap of sectors.mcap)
                            {
                                if (mcap["label"] === holding.marketcapcategory)
                                {
                                    mcap["angle"] = mcap["angle"] + currentValue;
                                    foundMcap = true;
                                }
                            }
                            if (foundSector === false)
                            {
                                sectors["sector"].push({"label":holding.sector, "angle":currentValue});
                            }
                            if (foundMcap === false)
                            {
                                sectors["mcap"].push({"label":holding.marketcapcategory, "angle":currentValue});
                            }
                            return sectors;
},{"sector":[{"label":"Cash", "angle":state.holding.cashBalance}], "mcap":[{"label":"Cash", "angle":state.holding.cashBalance}]});
    
    console.log("sectordata = "+JSON.stringify(sectordata));
    return sectordata;
}

const mapStateToProps = state => {
  return {
    sectordata: getSectorCapData(state)
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const MobilePortfolioCompositionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobilePortfolioComposition)

export default MobilePortfolioCompositionContainer
