import { connect } from 'react-redux'
import Rankings from "../jsx/Rankings";
import {closeSnackMessage, showRankings} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    currentChallenge: state.challenge.currentChallenge
    ,rankings: state.rankings
  }
}

const mapDispatchToProps = dispatch => {
    return {
    showRankings: data => {
      dispatch(showRankings(data))
    }
    }
}   

const RankingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Rankings)

export default RankingsContainer
