import React from 'react';
import ReactDataGrid from 'react-data-grid';
import _ from "lodash";
import PieChart, {
   Title,
    Font,
  Series,
  Label,
  Size,
  Connector
  ,Tooltip
}  from 'devextreme-react/pie-chart';

export default class PortfolioComposition extends React.Component {
          
    constructor(props) {
        super(props);
    }

    customizeLabel(point) {
        return `${point.argumentText }: ${ point.valueText }`;
    }
  
    shouldComponentUpdate(nextProps, nextState)
    {
        return !(_.isEqual( this.props.sectordata, nextProps.sectordata ));
    }
    
    customizeTooltip(data) {
    console.log("data = "+JSON.stringify(Object.keys(data)));
    return {
        text: `<span class="city">${data.argument}</span> (${data.valueText})` 
        };
    }
    
    render(){
       return (    
        <div className="card_half_contain">
            <div className="halfTableleft">
                   <PieChart
                    type="doughnut"
                    dataSource={this.props.sectordata.sector}
                    innerRadius={0.35}
                    diameter={0.4}
                    sizeGroup="pc"
                    legend={{visible: false}}
                   >          
                   <Size
                    height={200}
                    width={450}
                    />         
                    <Title text="Sector">
                        <Font size="15" color="#ffffff"/>
                    </Title>
                    <Series argumentField="label" valueField="angle">
                      <Label
                        visible={true}
                        format="fixedPoint"
                        customizeText={this.customizeLabel}
                      >
                        <Connector visible={true} width={1} />
                      </Label>
                    </Series>
                      <Tooltip
                        enabled={true}
                        format="fixedPoint"
                        customizeTooltip={this.customizeTooltip}
                      />
                  </PieChart>
            </div>
            <div className="halfTableright">
                   <PieChart
                    type="doughnut"
                    dataSource={this.props.sectordata.mcap}
                    innerRadius={0.35}
                    diameter={0.4}
                    sizeGroup="pc"
                    legend={{visible: false}}
                   >         
                   <Size
                    height={200}
                    width={450}
                    />             
                    <Title text="Market Cap">
                        <Font size="15" color="#ffffff"/>
                    </Title>
                    <Series argumentField="label" valueField="angle">
                      <Label
                        visible={true}
                        format="fixedPoint"
                        customizeText={this.customizeLabel}
                      >
                        <Connector visible={true} width={1} />
                      </Label>
                    </Series>
                      <Tooltip
                        enabled={true}
                        format="fixedPoint"
                        customizeTooltip={this.customizeTooltip}
                      />
                  </PieChart>
                <div>                
                   
                </div>
            </div>
        </div>
  );
  }
}
