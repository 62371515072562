import React from 'react';
import PropTypes from 'prop-types';
import './CustomCheckbox.scss';

class CheckboxForTable extends React.Component {
  static checkedStateClass = "containercheck";
  static uncheckedStateClass = "containerNotChecked";
  constructor(props) {
    super(props);
  }
  render() {
    return <span className="container">
                <span className={this.props.checkClass[this.props.value]}></span>
         </span>
    }
}

export default CheckboxForTable;