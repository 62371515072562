import WebSocketCallback from './WebSocketCallback'
import {STATUS} from './actions/actiontypes'

export default class ServiceConnector 
{
    wsUrl = 'wss://finedge.com/academy/academyserverclient'
    ws;
    cbHandler;
    constructor(store) 
    {        
        //this.store.subscribe(handleChange);
        this.cbHandler = new WebSocketCallback(store); 
        this.connect();   
        this.state = {
            openSnack : false
        } 
        this.submitMessage = this.submitMessage.bind(this);
        this.sendPing      = this.sendPing.bind(this);
        this.connect       = this.connect.bind(this);
        this.reconnect     = this.reconnect.bind(this);
        this.getPreference = this.getPreference.bind(this);
    }
    
    connect(){
        if (window.location.hostname === "localhost") 
        {
			//make it the admin as this is where the raw command interface sets the cookie
            this.wsUrl = 'wss://finedge.com/academy/academyserverclient';
        }
        this.ws = new WebSocket(this.wsUrl);
        this.setupHandlers();
    }
        
    setupHandlers(){
        this.ws.onopen =()=> {
          // on connecting, set the ping interval every 30s
          console.log('connected')
          setInterval(this.sendPing, 30 *1000);
          //get userpreference
         // this.getPreference();
          this.cbHandler.setWebsocketState(STATUS.OPEN);

          
        }

        this.ws.onmessage=(evt)=> {
          // on receiving a message, add it to the list of messages
          const message = JSON.parse(evt.data)
          this.processMessage(message)
          //console.log('websocket received '+JSON.stringify(message))
        }

        this.ws.onclose=()=> {
          console.log('disconnected')
          // automatically try to reconnect after 3 seconds on connection loss 
          this.cbHandler.setWebsocketState(STATUS.CLOSE);
          setTimeout( this.reconnect, 3000); 
        }
         
        this.ws.onerror=()=> {
            this.cbHandler.setWebsocketState(STATUS.ERROR);
            let errormessage = "WebSocket error observed:";
            console.error(errormessage);
            this.cbHandler.handleError(errormessage);
        }
    }
    
    processMessage(message) {
        if (message.type === "error")
        {
            if (message.message === "invalid cookie" 
                || message.message === "not authorized, insufficient credentials"
            )
            {
                //storeMessage(message.message);
           //     this.addMessage(message.message + " received, logging in, setting cookie, and retrying...", "received");
           //     this.login();
            }
        }
        else if (message.status === "success")
        {
            this.cbHandler.handle(message.type, message);
        }
        else if (message.status === "failed")
        {
            this.cbHandler.handleError(message.errormessage);
        }
    }
        
    submitMessage(message){
        if (this.ws.readyState !== WebSocket.OPEN)
        {
            console.log("websocket not open so cannot send message");
            return;
        }
        this.ws.send(JSON.stringify(message))
    }
    
    reconnect(){
        try
        {
            this.ws.close();
        }
        catch(err)
        {
            //this.addMessage(err);
        }
        this.connect();
    }
    
    sendPing(){
        let ping = {"action":"heartbeat","param":"ping"};
        this.submitMessage(ping);
    }
    getPreference(){
        let preference = {"action":"getpreference","param":"ping"};
        this.submitMessage(preference);
    }
    
    populateField(ck){
        var command = document.getElementById('commandselect').value;
        this.addCommandMessage(command)
    }
    
   // handleChange=()=>{
   //     state = this.store.getState();        
   // }
};
