import { connect } from 'react-redux'
import MarketHistory from "../jsx/client/MarketHistory";

const mapStateToProps = state => {
  return {
    chartData : Object.values(state.holding.chartDataSeries)
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const MarketHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketHistory)

export default MarketHistoryContainer
