import React from 'react';
import ReactDataGrid from 'react-data-grid';
import {STATUS} from '../../actions/actiontypes'
import LTFSelect from '../components/LTFSelect';
import _ from "lodash";
import { ReactReduxContext } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { green, red, yellow, teal } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PctDisplay from '../components/PctDisplay';
import CommaFormatter from '../components/CommaFormatter';
import DecimalFormatter from '../components/DecimalFormatter';

import { Toolbar, Data, Filters, Draggable } from "react-data-grid-addons";

const GreenRadio = withStyles({
root: {
color: green[400],
'&$checked': {
  color: green[600],
},
},
checked: {},
})(props => <Radio color="default" {...props} />);

const RedRadio = withStyles({
root: {
color: red[400],
'&$checked': {
  color: red[600],
},
},
checked: {},
})(props => <Radio color="default" {...props} />);

const GreyRadio = withStyles({
root: {
color: "#eceff1",
'&$checked': {
  color: "#b0bec5",
},
},
checked: {},
})(props => <Radio color="default" {...props} />);


const YellowRadio = withStyles({
root: {
color: yellow[400],
'&$checked': {
  color: yellow[600],
},
}
,checked: {},
})(props => <Radio
      disableRipple
      color="default"
      {...props}
    />);
    
const WhiteFormLabel = withStyles({
root: {
padding: '5px 25px',
color: "#ffffff",
'&$focused': {
  color: teal[200],
},
},
focused: {},
})(props => <FormLabel color="default" {...props} />);

export default class ReferencePeriod extends React.Component {
    pcolumns;
    scolumns;
    unsubscribe;
    static contextType = ReactReduxContext;
    constructor(props) {
        super(props);
        
        const defaultColumnProperties = {
          resizable: true
          ,sortable: true
        };
        
        this.state = { 
            scolumns : [
               { key: 'equityname', name: 'Name' , width: 60,filterable: true, frozen: true}
              ,{ key: 'company', name: 'Company', width: 130,filterable: true, frozen: true}
              ,{ key: 'sector', name: 'Sector', width: 80,filterable: true}
              ,{ key: 'marketcapcategory', name: 'Category', width: 70,filterable: true}
              ,{ key: 'startprice', name: 'Start Price', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'volume', name: 'Volume', width: 70, formatter:<CommaFormatter/>}
              ,{ key: 'marketcap', name: 'Market Cap', width: 80, formatter:<CommaFormatter/>}
              ,{ key: 'peratio', name: 'P/E Ratio', width: 70}
              ,{ key: 'ninetydayvol', name: '90Day Vol', width: 70, formatter:<CommaFormatter/>}
              ,{ key: 'thirtydayavg', name: '30Day Avg', width: 70, formatter:<CommaFormatter/>}
              ,{ key: 'fiftytworeturn', name: '52wk Return', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'lastprice', name: 'Last Price', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'periodreturn', name: 'Period Return', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'beta', name: 'Beta', width: 60, formatter:<DecimalFormatter/>}
              ,{ key: 'eps', name: 'EPS'}
                  ].map(c => ({ ...c, ...defaultColumnProperties }))
            ,portfolioequities : this.props.holdingsReference.filter(holding=>holding.direction ==="flat")
            ,directionValue : "flat"
            ,portfolioValue : "yourportfolio"
            ,sfilters : {}
            ,filteredRows : this.props.holdingsReference.filter(holding=>holding.direction ==="flat")
            ,currentRow: -1
        }
       
        this.onGridRowClicked       = this.onGridRowClicked.bind(this);         
        this.sortRows               = this.sortRows.bind(this);   
        this.handleFilterChange     = this.handleFilterChange.bind(this);   
        this.getEquityRefrenceData  = this.getEquityRefrenceData.bind(this);        
        
        if (this.props.currentChallenge.hasOwnProperty("challengeid"))
        {
            this.loadMarketHistory(this.props.currentChallenge.challengeid);
        }
    }
    
    handleDirectionChange = event => {
        let selected = event.target.value;
               
        let selectedPortfolio = this.state.portfolioValue;
        let portfolio = (selectedPortfolio === "yourportfolio") 
                                    ? this.props.holdingsReference
                                    : this.props.allReferenceEquitiesRows; 
                                    
        this.setState(state=>
        {
            let nPortfolio = portfolio.filter(equity => equity.direction === selected);                    
            return {portfolioequities: nPortfolio
                    ,directionValue: selected};
        });
            
        this.loadMarketHistory(this.props.currentChallenge.challengeid, selected);
        this.getEquityRefrenceData(selected);
    };

    loadMarketHistory(challengeid, direction = this.state.directionValue)
    {
        let mData = {
            action:"getmireferencedata"
            ,param: {
                    challengeid:challengeid
                    ,direction: direction
                }
        };
        this.props.getReferenceMarketIndex(mData);
        let portfolio = this.state.portfolioequities;
        if (!_.isEmpty(portfolio))
        {
            let equityVal = portfolio.map(holding=>{
                let avgprice = holding.hasOwnProperty("avgbuyprice") ? holding.avgbuyprice : holding.startprice;
                return {equityid :holding.equityid, avgbuyprice: parseFloat(avgprice)};
            });
            
            let data = {
                        action:           "getreferenceholdingreturn"
                        ,param: {
                            challengeid:      challengeid
                            ,direction: direction
                            ,holding:   equityVal
                        }
            };
            this.props.getReferenceHoldingReturn(data);                
        }
        
            
    }
    
    getEquityRefrenceData(selected)
    {        
        if (this.state.currentRow === -1)
        {
            console.log("no current row");
            return;
        }
        let equity = this.state.filteredRows[this.state.currentRow];  
        if (equity === undefined)
        {
            return;
        }
        this.getSecdata(equity, selected);
    }
    
    handlePortfolioChange = event => {
        let selected = event.target.value;
        this.setState({portfolioValue: selected});
        
        if (selected === "yourportfolio")
        {            
            this.setState({portfolioequities: this.props.holdingsReference.filter(holding=>holding.direction === this.state.directionValue)});
        }
        else
        {
            this.setState({portfolioequities: this.props.allReferenceEquitiesRows.filter(holding=>holding.direction ===this.state.directionValue)});
        }            
    };

    handleFilterChange(filter) {
        this.setState(state=>
        {
              const newFilters = { ...state.sfilters };
              if (filter.filterTerm) {
                newFilters[filter.column.key] = filter;
              } else {
                delete newFilters[filter.column.key];
              }
              return {sfilters: newFilters};
        });
    }

    clearSFilters(filter) {
        this.setState(state=>
        {{sfilters: {}}
        });
    }
   
    sortRows(initialRows, sortColumn, sortDirection)
    {
        console.log("sortRows");
        let rows = initialRows;
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } 
            else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        this.setState({filteredRows: (sortDirection === "NONE" ? initialRows : [...rows].sort(comparer))});
    }
    
    getRows(rows, filters) {
        return Data.Selectors.getRows({ rows, filters });
    }
    
    getValidFilterValues(rows, columnId) {
        return rows
        .map(r => r[columnId])
        .filter((item, i, a) => {
          return i === a.indexOf(item);
        });
    }
    
    shouldComponentUpdate(nextProps, nextState)
    {
        let returnVal = true;
        if (!_.isEqual( this.state.portfolioequities, nextState.portfolioequities ))
        {
            let filters = this.state.sfilters;
            this.setState({filteredRows : this.getRows(nextState.portfolioequities, filters)});
        }
        if (!_.isEqual( this.state.filteredRows, nextState.filteredRows ))
        {
            returnVal = true;
        }
        if (!_.isEqual( this.state.sfilters, nextState.sfilters ))
        {
            this.setState({filteredRows : this.getRows(nextState.portfolioequities, nextState.sfilters)});
            returnVal = true;
        } 
        if ((!this.props.currentChallenge.hasOwnProperty("challengeid") 
            && nextProps.currentChallenge.hasOwnProperty("challengeid"))
            ||
            (this.props.currentChallenge.hasOwnProperty("challengeid") 
            && nextProps.currentChallenge.hasOwnProperty("challengeid")
            &&(this.props.currentChallenge.challengeid !== nextProps.currentChallenge.challengeid))
            )
            
        {
            this.loadMarketHistory(nextProps.currentChallenge.challengeid);
        }
        return returnVal;
    }
    
    
    clearFilters(filter) {
        this.setState(state=>
        {{sfilters: {}}
        });
    }
    
    onGridRowClicked(row) {    
        let equity = this.state.filteredRows[row];     
        console.log("onGridRowClicked "+JSON.stringify(equity));
        if (equity === undefined)
        {
            return;
        }
        this.props.setSecurityDetail(equity);
        this.getSecdata(equity, this.state.directionValue);
        
        this.setState({currentRow: row});
    }
    
    getSecdata(equity, direction)
    {
        let data = {"action":"getreferencestatsforchallenge"
                    ,"param":{"equityid":equity.equityid
                            , "challengeid": this.props.currentChallenge.challengeid
                            ,direction: direction
                   }}
        this.props.getSecurityStatsForChallenge(data);
    }
    
    render(){
        return (                    
        <div className="refgrid">
                    <FormControl component="fieldset">
                      <WhiteFormLabel component="legend">Market Direction</WhiteFormLabel>
                      <RadioGroup aria-label="direction" name="direction" value={this.state.directionValue} onChange={this.handleDirectionChange} row>
                        <FormControlLabel
                          value="flat"
                          control={<GreyRadio/>}
                          label="Flat"
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          value="up"
                          control={<GreenRadio/>}
                          label="Up"
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          value="down"
                          control={<RedRadio/>}
                          label="Down"
                          labelPlacement="bottom"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                      <WhiteFormLabel component="legend">Portfolio</WhiteFormLabel>
                      <RadioGroup aria-label="portfolio" name="portfolio" value={this.state.portfolioValue} onChange={this.handlePortfolioChange} row>
                        <FormControlLabel
                          value="universe"
                          control={<YellowRadio/>}
                          label="Universe"
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          value="yourportfolio"
                          control={<YellowRadio/>}
                          label="Your portfolio"
                          labelPlacement="bottom"
                        />
                      </RadioGroup>
                    </FormControl>
                    
            <ReactDataGrid  columns={this.state.scolumns}
                          rowGetter={i => this.state.filteredRows[i]}
                          rowsCount={this.state.filteredRows.length}
                          minHeight={200}
                          headerRowHeight={20}
                          rowHeight={28}
                          onRowClick={this.onGridRowClicked}
                          toolbar={<Toolbar enableFilter={true} />}
                          onAddFilter={filter => this.handleFilterChange(filter)}
                          onClearFilters={() => this.clearFilters({})}
                          getValidFilterValues={columnKey => this.getValidFilterValues(this.state.portfolioequities, columnKey)}                            
                          onGridSort={(sortColumn, sortDirection) =>{
                              console.log("sorting");
                                this.sortRows(this.state.filteredRows,sortColumn, sortDirection)}}
                />
            </div>
        );
    }
}