import React from "react";
import ReactDOM from "react-dom";
import ClientHeaderContainer from "./containers/ClientHeaderContainer";
import MainLayout from "./jsx/MainLayout";
import MobileClientHeaderContainer from "./containers/MobileClientHeaderContainer";
import MobileMainLayout from "./jsx/MobileMainLayout";
import Login from './login/Login'

import { Provider } from 'react-redux'
import configureStore from './configureStore'
import ServiceConnector from './ServiceConnector'
import LTFSnackBarContainer from './containers/LTFSnackBarContainer';
import LTFDialogContainer from './containers/LTFDialogContainer';
import BlurScreenContainer from './containers/BlurScreenContainer';
import RankingsContainer from './containers/RankingsContainer';

//import '../css/site.css';

var appNS = {};

appNS.getCookie = function(cname) 
{
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) 
    {
        var c = ca[i];
        while (c.charAt(0) === ' ') 
        {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) 
        {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

appNS.start = function(contentDiv, store, userinfo)
{
    const websocketServiceConnector = new ServiceConnector(store);
    store.dispatch({type:"SET_WEBSOCKET", wsocket:websocketServiceConnector});
    store.dispatch({type:"SET_USER", text:JSON.parse(userinfo)});
    
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		var element = document.getElementById('text');
		if (isMobile) 
        {
            store.dispatch({type:"SET_MOBILE_DEVICE", text:true});
            ReactDOM.render(
                            <Provider store={store}>
                                <BlurScreenContainer />
                                <MobileClientHeaderContainer />
                                <MobileMainLayout />
                                <LTFSnackBarContainer/>
                                <LTFDialogContainer/>
                            </Provider>
                            , contentDiv
            );
		} 
        else 
        {
            ReactDOM.render(
                            <Provider store={store}>
                                <BlurScreenContainer />
                                <RankingsContainer />
                                <ClientHeaderContainer />
                                <MainLayout />
                                <LTFSnackBarContainer/>
                                <LTFDialogContainer/>
                            </Provider>
                            , contentDiv
            );
        }
};

appNS.login = function(contentDiv, store)
{
    ReactDOM.render(
                    <Provider store={store}>
                        <Login store={store}/>
                        <LTFSnackBarContainer/>
                        <LTFDialogContainer/>
                    </Provider>
                    , contentDiv
    );
}

appNS.main = function()
{
    const store = configureStore()
    const contentDiv = document.getElementById("root");
    const sessiontoken = appNS.getCookie("sessiontoken")
    const userinfo = appNS.getCookie("userinfo")
    //appNS.start(contentDiv, store);
    
    //if ((window.location.hostname !== "localhost") && (sessiontoken === ""))
    if (sessiontoken === "")
    {
        console.log("no cookie, so please login");
        appNS.login(contentDiv, store);
    }
    else
    {
        appNS.start(contentDiv, store, userinfo);
    }
    
}

appNS.main();
