import { connect } from 'react-redux'
import MobileStockDetails from "../jsx/client/MobileStockDetails";
import {setSecurityDetail, getSecurityStatsForChallenge} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    stockDetail: state.securities.stockDetail
    ,stockStats: state.securities.stockStats
    ,allSecurities: state.securities.allSecurities
    ,currentChallenge:  state.challenge.currentChallenge
    ,allEquitiesRows: Object.keys(state.securities.allSecurities).map(security => state.securities.allSecurities[security])
  }
}

const mapDispatchToProps = dispatch => {
    return {
        setSecurityDetail: data => {
          dispatch(setSecurityDetail(data))
        }
        ,getSecurityStatsForChallenge: data => {
          dispatch(getSecurityStatsForChallenge(data))
        }
    }
}   

const MobileStockDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileStockDetails)

export default MobileStockDetailsContainer
