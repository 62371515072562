import React, { useState } from 'react';
import classnames from 'classnames';

import './Header.scss';
import '../Formatter.scss';

const getClass=(value)=>
{
    if (value < 0)
    {
        return "number negativedata";
    }
    return "number positivedata";
}
    
const MobileHeader = props => {
  const {counter, segmentCurrent, segmentTotal, dayCurrent, dayTotal, challengeName, portfolioValue, cashAvailable, totalValue, quizBonus, beta, portofolioReturn, openChallengeClick, signOutClick, addCardClick, viewRankingClick } = props;

  const headerClassnames = classnames('app_header')
  const [ toggleMenu, setToggleMenu ] = useState(false);

  const toggleMenuClick = () => {
    setToggleMenu(!toggleMenu)
  }
  
  const menuDropdownClass = classnames('menu_dropdown', {"active": toggleMenu})
  return (
    <header className={headerClassnames}>
      <div className="counter_contain">
        <div className="day_section">
          <p className="segment"><span className="label">Seg</span><span className="number">{segmentCurrent}/{segmentTotal}</span></p>
          <p className="day"><span className="label">Day</span><span className="number">{dayCurrent}/{dayTotal}</span></p>
        </div>
        <div className="count_section">
          <p className="count" id="countdowntimer">{counter}</p>
        </div>
        <div className="portfolio_info">
            <div className="day_section">
              <p className="segment"><span className="label">Pfolio </span><span className="number">${portfolioValue}</span></p>
              <p className="day"><span className="label">Cash </span><span className="number">${cashAvailable}</span></p>
              <p className="day"><span className="label">Total </span><span className="number">${totalValue}</span></p>
            </div>
        </div>
        <div className="return_info">
            <div className="day_section">
              <p className="segment"><span className="label">Quiz </span><span className="number">${quizBonus}</span></p>
              <p className="day"><span className="label">Beta</span><span className="number">{beta}</span></p>
              <p className="day"><span className="label">Return</span><span className={getClass(portofolioReturn)}> {portofolioReturn}%</span></p>
            </div>
        </div> 
      </div>
      <div className="tab_button">
      <span className="challengelabel"><p></p>{challengeName}</span>
      <div className="menu_button">
        <button className="header_button" onClick={toggleMenuClick}>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
        </button>

          <ul className={menuDropdownClass} onClick={toggleMenuClick}>
            <li>
              <button className="header_button dropdown_item" onClick={addCardClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                <span className="button_label">Tools</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={openChallengeClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="folder-open" className="svg-inline--fa fa-folder-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M572.694 292.093L500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z"></path></svg>
                <span className="button_label">Open Challenge</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={viewRankingClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book-open" className="svg-inline--fa fa-book-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"></path></svg>
                <span className="button_label">View Rankings</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={signOutClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" className="svg-inline--fa fa-sign-out-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
                <span className="button_label">Sign Out</span>
              </button>
            </li>
          </ul>
      </div>
      </div>
      
    </header>
  )
}

export default MobileHeader;
