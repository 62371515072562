import React, {useState} from 'react';
import classnames from 'classnames';
import './Modal.scss';  

const ModalSendMessage = props => {
  const {openModal, handleSendMessage, handleClose} = props;

  const modalClassname= classnames("modal_contain", {"active": !openModal})
  return (
    <div className={modalClassname}>
      <div className="modal">
        <div className="open_workspace">
          <h3>Send Message</h3>
          <button onClick={handleClose} aria-label='close modal' className="close_modal"> 
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
          </button>
          <div className="modal_elements">
            <input type="text" id="sendadminmessage" placeholder="name"/>
            <button type="button" onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalSendMessage;
