import { connect } from 'react-redux'
import StockDetails from "../jsx/client/StockDetails";

const mapStateToProps = state => {
  return {
    stockDetail: state.securities.stockDetail
    ,stockStats: state.securities.stockStats
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const StockDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StockDetails)

export default StockDetailsContainer
