import React from 'react';
import PropTypes from 'prop-types';

class PctDisplay extends React.Component {
  constructor(props) {
    super(props);
  }  
  
  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.props.value;
  }

  render() {
    return <div title={this.props.value}>{this.props.value !== ""? this.props.value.toFixed(2).toLocaleString()+"%" :""}</div>;
  }
}

export default PctDisplay;