import {ACTIONS, VARIANT, HANDLER} from './actiontypes'

/*
 * action creators
 */
 
 //snackbar
 export const setInfoMessage = text => ({
  type: ACTIONS.SHOW_SNACK_MESSAGE,
  text: {"variant":VARIANT.INFO,"message":text}
})
 export const setSuccessMessage = text => ({
  type: ACTIONS.SHOW_SNACK_MESSAGE,
  text: {"variant":VARIANT.SUCCESS,"message":text}
})
 export const setWarningMessage = text => ({
  type: ACTIONS.SHOW_SNACK_MESSAGE,
  text: {"variant":VARIANT.WARNING,"message":text}
})
 export const setErrorMessage = text => ({
  type: ACTIONS.SHOW_SNACK_MESSAGE,
  text: {"variant":VARIANT.ERROR,"message":text}
})
 export const closeSnackMessage = text => ({
  type: ACTIONS.CLOSE_SNACK_MESSAGE,
  text
})

 export const closeDialogMessage = text => ({
  type: ACTIONS.CLOSE_DIALOG_MESSAGE,
  text
})

 export const openDialogMessage = text => ({
  type: ACTIONS.SHOW_DIALOG_MESSAGE,
  text
})

//portfolio universe
export const insertOrder = text => ({
  type: ACTIONS.INSERT_ORDER,
  handler: HANDLER.WS,
  text
})

export const getPosition = text => ({
  type: ACTIONS.GET_PORTFOLIO_HOLDING,
  handler: HANDLER.WS,
  text: {"action":"getposition","param":{"action":"getposition","challengeid":text}}
})

export const getReturns = text => ({
  type: ACTIONS.GET_PORTFOLIO_RETURNS,
  handler: HANDLER.WS,
  text: {"action":"getreturns","param":{"action":"getreturns","challengeid":text}}
})

export const addReturns = text => ({
  type: ACTIONS.ADD_PORTFOLIO_RETURNS,
  text
})

export const updatePosition = text => ({
  type: ACTIONS.UPDATE_PORTFOLIO_HOLDING,
  text
})

export const updatePositions = text => ({
  type: ACTIONS.UPDATE_PORTFOLIO_HOLDINGS,
  text
})

 export const setPortfolioHolding = text => ({
  type: ACTIONS.SET_PORTFOLIO_HOLDING,
  text
})

 export const getEquitiesInPortfolio = text => (
 {
  type: ACTIONS.GET_ALL_SECURITIES_IN_PORTFOLIOHOLDING,
  handler: HANDLER.WS,
  text: {"action":"getallsecuritiesinportfoliouniverse","param":{"portfoliouniverseid":text}}
})

 export const addEquitiesToHolding = text => (
 {
  type: ACTIONS.ADD_SECURITY_TO_PORTFOLIO_HOLDING,
  text
})

 export const showHiddedSecuritiesInHolding = text => (
 {
  type: ACTIONS.SHOW_HIDDEN_SECURITY_IN_HOLDING,
  text
})

 export const removeEquitiesFromHolding = text => (
 {
  type: ACTIONS.REMOVE_SECURITY_FROM_PORTFOLIO_HOLDING,
  text
})

 export const modifyHolding = text => (
 {
  type: ACTIONS.MODIFY_PORTFOLIO_HOLDING,
  text
})

 export const saveTransaction = text => (
 {
  type: ACTIONS.SAVE_TRANSACTION,
  text
})

//security definition
export const getAllSecurityDefinitions = text => ({
  type: ACTIONS.GET_ALL_SECURITYDEFINITIONS,
  handler: HANDLER.WS,
  text: {"action":"getallsecuritydefinition","param":{"active":text}}
})

export const setAllSecurityDefinitions = text => ({
  type: ACTIONS.SET_ALL_SECURITYDEFINITIONS,
  text
})

export const statsUpdate = text => ({
  type: ACTIONS.SET_SECURITYSTATS,
  text
})

export const getSecuritiesStats = text => (
 {
  type: ACTIONS.GET_SECURITYSTATS,
  handler: HANDLER.WS,
  text: {"action":"getsecuritystats","param":{"equityid":text}}
})

export const setSecurityDetail = text => ({
  type: ACTIONS.SET_SECURITYDEFINITION,
  text
})

export const getSecurityStatsForChallenge = text => (
 {
  type: ACTIONS.GET_SECURITYSTATSFORCHALLENGE,
  handler: HANDLER.WS,
  text
})

export const updateSecurityHistory = text => (
 {
  type: ACTIONS.UPDATE_SECURITYHISTORY,
  text
})

export const updateSecurityReference = text => (
 {
  type: ACTIONS.UPDATE_SECURITYREFERENCE,
  text
})
export const setUniverseReferenceData = text => (
 {
  type: ACTIONS.SET_UNIVERSE_REFERENCE_DATA,
  text
})


export const updateReferenceData = text => (
 {
  type: ACTIONS.SET_REFERENCE_DATA,
  text
})

export const getReferenceMarketIndex = text => (
 {
  type: ACTIONS.GET_REFERENCE_DATA,
  handler: HANDLER.WS,
  text
})

export const getReferenceHoldingReturn = text => (
 {
  type: ACTIONS.GET_REFERENCE_HOLDING_DATA,
  handler: HANDLER.WS,
  text
})

export const catchupData = text => (
 {
  type: ACTIONS.CATCHUP_DATA,
  handler: HANDLER.WS,
  text: {"action":"catchupdata","param":{"challengeid":text}}
})

export const getUniverseReferenceData = text => (
 {
  type: ACTIONS.CATCHUP_DATA,
  handler: HANDLER.WS,
  text: {"action":"getuniversereferencedata","param":{"challengeid":text}}
})


//websocket
export const setwebSocketState = text => ({
  type: ACTIONS.SET_WEBSOCKET_STATE,
  text
})

//applist
export const setApplistStatus = text => ({
  type: ACTIONS.CHANGE_LAYOUT_STATUS,
  text
})

export const setApplist = text => ({
  type: ACTIONS.SET_APPLIST,
  text
})

export const setApplists = text => ({
  type: ACTIONS.SET_APPLISTS,
  text
})
export const restoreWorkspace = text => ({
  type: ACTIONS.RESET_WORKSPACE,
  text
})

//challenge
 export const getChallenge = text => ({
  type: ACTIONS.GET_CHALLENGE,
  handler: HANDLER.WS,
  text: {"action":"getchallenge","param":{"challengeid":text}}
})

 export const subscribeChallenge = text => ({
  type: ACTIONS.SUBSCRIBE_CHALLENGE,
  handler: HANDLER.WS,
  text: {"action":"subscribechallenge","param":{"challengeid":text}}
})

 export const getChallengeStats = text => ({
  type: ACTIONS.GET_CHALLENGE_STATS,
  handler: HANDLER.WS,
  text: {"action":"getchallengestats","param":{"challengeid":text}}
})

 export const setCurrentChallenge = text => ({
  type: ACTIONS.SET_CURRENT_CHALLENGE,
  text
})

 export const updateChallenge = text => ({
  type: ACTIONS.UPDATE_CHALLENGE,
  text
})


export const blurChallenge = text => ({
  type: ACTIONS.BLUR_SCREEN,
  handler: HANDLER.WS,
  text: {"action":"blurscreen","param":{"challengeid":text}}
})

 export const setChallengeStatus = text => ({
  type: ACTIONS.SET_CHALLENGE_STATUS,
  text
})
export const getAllChallenges = text => ({
  type: ACTIONS.GET_ALL_CHALLENGES,
  handler: HANDLER.WS,
  text: {"action":"getallchallenges","param":{"challengestatus":text}}
})

 export const setAllChallenges = text => ({
  type: ACTIONS.SET_ALL_CHALLENGES,
  text
})

 export const setChallengeChange = text => ({
  type: ACTIONS.SET_CHALLENGE_CHANGE,
  text
})

//workspace
 export const setWorkspace = text => ({
  type: ACTIONS.SET_WORKSPACE,
  text
})
 export const addWorkspace = text => ({
  type: ACTIONS.ADD_WORKSPACE,
  handler: HANDLER.WS,
  text
})
 export const saveWorkspace = text => ({
  type: ACTIONS.SAVE_WORKSPACE,
  handler: HANDLER.WS,
  text
})
 export const savePreviousWorkspace = text => ({
  type: ACTIONS.SAVE_PREVIOUS_WORKSPACE,
  text
})
 export const setWorkspaceStatus = text => ({
  type: ACTIONS.SET_WORKSPACE_STATUS,
  text
})
 export const setWorkspaceData = text => ({
  type: ACTIONS.SAVE_NEW_WORKSPACE,
  text
})
 export const deleteWorkspace = text => ({
  type: ACTIONS.DELETE_WORKSPACE,
  text: {"action":"deleteworkspace","param":text},
  handler: HANDLER.WS,
})

 export const removeWorkspaceFromList = text => ({
  type: ACTIONS.REMOVE_WORKSPACE,
  text
})

//cards
 export const addNewCard = text => ({
  type: ACTIONS.ADD_NEW_CARD,
  text
})

 export const changeCard = text => ({
  type: ACTIONS.CHANGE_CARDS,
  text
})

export const setCards = text => ({
  type: ACTIONS.SET_ALL_CARDS,
  text
})

//news
export const addNews = text => ({
  type: ACTIONS.ADD_NEWS,
  text
})

export const addEconomicIndicator = text => ({
  type: ACTIONS.ADD_ECONOMICINDICATOR,
  text
})

export const addMarketIndex = text => ({
  type: ACTIONS.ADD_MARKETINDEX,
  text
})


export const setTrendingStock = text => ({
  type: ACTIONS.SET_TRENDING_STOCKS,
  text
})

export const applyQuizBonus = text => ({
  type: ACTIONS.APPLY_QUIZ_BONUS,
  text
})


export const saveRankings = text => ({
  type: ACTIONS.SET_RANKINGS,
  text
})

export const showRankings = text => ({
  type: ACTIONS.SHOW_RANKINGS,
  text
})

export const showNonCompetitve = text => ({
  type: ACTIONS.SHOW_NON_COMPETITVE,
  text
})

export const sendAdminMessage = text => ({
  type: ACTIONS.SEND_ADMIN_MESSAGE,
  handler: HANDLER.WS,
  text: {"action":"sendadminmessage","param":text}
})

export const setCurrentCard = text => ({
  type: ACTIONS.SET_MOBILE_CARD,
  text
})

