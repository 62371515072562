import { connect } from 'react-redux'
import BlurScreen from "../jsx/BlurScreen";
import {closeSnackMessage} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    currentChallenge: state.challenge.currentChallenge
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const BlurScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlurScreen)

export default BlurScreenContainer
