import React from 'react';
import ReactDataGrid from 'react-data-grid';
import {STATUS} from '../../actions/actiontypes'
import LTFSelect from '../components/LTFSelect';
import _ from "lodash";
import { ReactReduxContext } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { green, red, yellow, teal } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PctDisplay from '../components/PctDisplay';
import CommaFormatter from '../components/CommaFormatter';
import DecimalFormatter from '../components/DecimalFormatter';

import { Toolbar, Data, Filters, Draggable } from "react-data-grid-addons";

import Chart, {
  CommonSeriesSettings
  ,Series
  ,ValueAxis
  ,ArgumentAxis
  ,Legend
  ,Tooltip
  ,Title
  ,Grid
  ,Format
  ,ZoomAndPan
} from 'devextreme-react/chart';

const GreenRadio = withStyles({
root: {
color: green[400],
'&$checked': {
  color: green[600],
},
},
checked: {},
})(props => <Radio color="default" {...props} />);

const RedRadio = withStyles({
root: {
color: red[400],
'&$checked': {
  color: red[600],
},
},
checked: {},
})(props => <Radio color="default" {...props} />);

const GreyRadio = withStyles({
root: {
color: "#eceff1",
'&$checked': {
  color: "#b0bec5",
},
},
checked: {},
})(props => <Radio color="default" {...props} />);


const YellowRadio = withStyles({
root: {
color: yellow[400],
'&$checked': {
  color: yellow[600],
},
}
,checked: {},
})(props => <Radio
      disableRipple
      color="default"
      {...props}
    />);
    
const WhiteFormLabel = withStyles({
root: {
padding: '5px 25px',
color: "#ffffff",
'&$focused': {
  color: teal[200],
},
},
focused: {},
})(props => <FormLabel color="default" {...props} />);

export default class MobileReferencePeriod extends React.Component {
    pcolumns;
    scolumns;
    unsubscribe;
    static contextType = ReactReduxContext;
    constructor(props) {
        super(props);
        
        const defaultColumnProperties = {
          resizable: true
          ,sortable: true
        };
        
        this.state = { 
            scolumns : [
               { key: 'equityname', name: 'Name' , width: 50}
              ,{ key: 'company', name: 'Company', width: 100}
              ,{ key: 'sector', name: 'Sector', width: 70}
              ,{ key: 'marketcapcategory', name: 'Category', width: 70}
              ,{ key: 'startprice', name: 'Start Price', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'dailyreturn', name: 'Daily Return', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'volume', name: 'Volume', width: 70, formatter:<CommaFormatter/>}
              ,{ key: 'marketcap', name: 'Market Cap', width: 70, formatter:<CommaFormatter/>}
              ,{ key: 'avgvolume', name: 'Avg Volume', width: 70, formatter:<CommaFormatter/>}
              ,{ key: 'peratio', name: 'P/E Ratio', width: 70}
              ,{ key: 'ninetydayvol', name: '90Day Vol', width: 70, formatter:<CommaFormatter/>}
              ,{ key: 'thirtydayavg', name: '30Day Avg', width: 70, formatter:<CommaFormatter/>}
              ,{ key: 'fiftytwoprice', name: '52wk Price', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'fiftytworeturn', name: '52wk Return', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'lastprice', name: 'Last Price', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'periodreturn', name: 'Period Return', width: 70, formatter:<DecimalFormatter/>}
              ,{ key: 'yield', name: 'Yield', width: 60, formatter:<DecimalFormatter/>}
              ,{ key: 'beta', name: 'Beta', width: 60, formatter:<DecimalFormatter/>}
              ,{ key: 'eps', name: 'EPS', width: 70}
                  ].map(c => ({ ...c, ...defaultColumnProperties }))
            ,portfolioequities : this.props.equitiesInCurrentHolding
            ,directionValue : "flat"
            ,portfolioValue : "yourportfolio"
            ,sfilters : {}
            ,filteredRows : this.props.equitiesInCurrentHolding
            ,currentRow: -1
        }
       
        this.onGridRowClicked       = this.onGridRowClicked.bind(this);         
        this.sortRows               = this.sortRows.bind(this);   
        this.getEquityRefrenceData  = this.getEquityRefrenceData.bind(this);        
        
        if (this.props.currentChallenge.hasOwnProperty("challengeid"))
        {
            this.loadMarketHistory(this.props.currentChallenge.challengeid);
        }
    }
    
    handleDirectionChange = event => {
        let selected = event.target.value;
        this.setState({directionValue: selected});
               
        let selectedPortfolio = this.state.portfolioValue;
        let portfolio = (selectedPortfolio === "yourportfolio") 
                                    ? this.props.equitiesInCurrentHolding
                                    : this.props.allEquitiesRows;            
        if (selected ==="up")
        {            
            this.setState(state=>
            {
                let nPortfolio = portfolio.map(equity=>{
                    let newequity = {...equity};
                    newequity.startprice        = 1.3 * newequity.startprice    ;
                    newequity.dailyreturn       = 1.3 * newequity.dailyreturn   ;
                    newequity.volume            = 1.3 * newequity.volume        ;
                    newequity.marketcap         = 1.3 * newequity.marketcap     ;
                    newequity.avgvolume         = 1.3 * newequity.avgvolume     ;
                    newequity.peratio           = 1.3 * newequity.peratio       ;
                    newequity.ninetydayvol      = 1.3 * newequity.ninetydayvol  ;
                    newequity.thirtydayavg      = 1.3 * newequity.thirtydayavg  ;
                    newequity.fiftytwoprice     = 1.3 * newequity.fiftytwoprice ;
                    newequity.fiftytworeturn    = 1.3 * newequity.fiftytworeturn;
                    newequity.lastprice         = 1.3 * newequity.lastprice     ;
                    newequity.periodreturn      = 1.3 * newequity.periodreturn  ;
                    newequity.yield             = 1.3 * newequity.yield         ;
                    newequity.beta              = 1.3 * newequity.beta          ;
                    newequity.eps               = 1.3 * newequity.eps           ;
                    return newequity;
                });                    
                return {portfolioequities: nPortfolio};
            });
        }
        else if (selected ==="down")
        {            
            this.setState(state=>
            {
                let nPortfolio = portfolio.map(equity=>{
                    let newequity = {...equity};
                    newequity.startprice        = -1.6 * newequity.startprice    ;
                    newequity.dailyreturn       = -1.6 * newequity.dailyreturn   ;
                    newequity.volume            = -1.6 * newequity.volume        ;
                    newequity.marketcap         = -1.6 * newequity.marketcap     ;
                    newequity.avgvolume         = -1.6 * newequity.avgvolume     ;
                    newequity.peratio           = -1.6 * newequity.peratio       ;
                    newequity.ninetydayvol      = -1.6 * newequity.ninetydayvol  ;
                    newequity.thirtydayavg      = -1.6 * newequity.thirtydayavg  ;
                    newequity.fiftytwoprice     = -1.6 * newequity.fiftytwoprice ;
                    newequity.fiftytworeturn    = -1.6 * newequity.fiftytworeturn;
                    newequity.lastprice         = -1.6 * newequity.lastprice     ;
                    newequity.periodreturn      = -1.6 * newequity.periodreturn  ;
                    newequity.yield             = -1.6 * newequity.yield         ;
                    newequity.beta              = -1.6 * newequity.beta          ;
                    newequity.eps               = -1.6 * newequity.eps           ;
                    return newequity;
                });
                    
                return {portfolioequities: nPortfolio};
            });
        }            
        else if (selected ==="flat")
        {            
            this.setState({portfolioequities: portfolio});
        }
            
        this.loadMarketHistory(this.props.currentChallenge.challengeid, selected);
        this.getEquityRefrenceData(selected);
    };

    loadMarketHistory(challengeid, direction = this.state.directionValue)
    {
        this.props.getReferenceMarketIndex(challengeid);
        let portfolio = this.state.portfolioequities;
        if (!_.isEmpty(portfolio))
        {
            let equityVal = portfolio.map(holding=>{
                let avgprice = holding.hasOwnProperty("avgbuyprice") ? holding.avgbuyprice : holding.startprice;
                return {equityid :holding.equityid, avgbuyprice: parseFloat(avgprice)};
            });
            
            let data = {
                        action:           "getreferenceholdingreturn"
                        ,param: {
                            challengeid:      challengeid
                            ,direction: direction
                            ,holding:   equityVal
                        }
            };
            this.props.getReferenceHoldingReturn(data);                
        }
        
            
    }
    
    getEquityRefrenceData(selected)
    {        
        if (this.state.currentRow === -1)
        {
            console.log("no current row");
            return;
        }
        let equity = this.state.filteredRows[this.state.currentRow];  
        if (equity === undefined)
        {
            return;
        }
        this.getSecdata(equity, selected);
    }
    
    handlePortfolioChange = event => {
        let selected = event.target.value;
        this.setState({portfolioValue: selected});
        
        if (selected === "yourportfolio")
        {            
            this.setState({portfolioequities: this.props.equitiesInCurrentHolding});
        }
        else
        {
            this.setState({portfolioequities: this.props.allEquitiesRows});
        }    
        
    };

   
    sortRows(initialRows, sortColumn, sortDirection)
    {
        console.log("sortRows");
        let rows = initialRows;
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } 
            else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        this.setState({filteredRows: (sortDirection === "NONE" ? initialRows : [...rows].sort(comparer))});
    }
    
    getRows(rows, filters) {
        return Data.Selectors.getRows({ rows, filters });
    }
        
    shouldComponentUpdate(nextProps, nextState)
    {
        let returnVal = true;
        if (!_.isEqual( this.state.portfolioequities, nextState.portfolioequities ))
        {
            let filters = this.state.sfilters;
            this.setState({filteredRows : this.getRows(nextState.portfolioequities, filters)});
        }
        if (!_.isEqual( this.state.filteredRows, nextState.filteredRows ))
        {
            returnVal = true;
        }
        if (!_.isEqual( this.state.sfilters, nextState.sfilters ))
        {
            this.setState({filteredRows : this.getRows(nextState.portfolioequities, nextState.sfilters)});
            returnVal = true;
        } 
        if ((!this.props.currentChallenge.hasOwnProperty("challengeid") 
            && nextProps.currentChallenge.hasOwnProperty("challengeid"))
            ||
            (this.props.currentChallenge.hasOwnProperty("challengeid") 
            && nextProps.currentChallenge.hasOwnProperty("challengeid")
            &&(this.props.currentChallenge.challengeid !== nextProps.currentChallenge.challengeid))
            )
            
        {
            this.loadMarketHistory(nextProps.currentChallenge.challengeid);
        }
        return returnVal;
    }
    
    
    clearFilters(filter) {
        this.setState(state=>
        {{sfilters: {}}
        });
    }
    
    onGridRowClicked(row) {    
        let equity = this.state.filteredRows[row];     
        console.log("onGridRowClicked "+JSON.stringify(equity));
        if (equity === undefined)
        {
            return;
        }
        this.props.setSecurityDetail(equity);
        this.getSecdata(equity, this.state.directionValue);        
        this.setState({currentRow: row});
    }
    
    getSecdata(equity, direction)
    {
        let data = {"action":"getreferencestatsforchallenge"
                    ,"param":{"equityid":equity.equityid
                            , "challengeid": this.props.currentChallenge.challengeid
                            ,direction: direction
                   }}
        this.props.getSecurityStatsForChallenge(data);
    }
    
    render(){
        return (                
        <div className="mobile_layer_card">    
            <div className="mobileLayer">
                    <FormControl component="fieldset">
                      <WhiteFormLabel component="legend" color="primary">Market Direction</WhiteFormLabel>
                      <RadioGroup aria-label="direction" name="direction" onChange={this.handleDirectionChange} row>
                        <FormControlLabel
                          value="flat"
                          control={<GreyRadio/>}
                          label="Flat"
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          value="up"
                          control={<GreenRadio/>}
                          label="Up"
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          value="down"
                          control={<RedRadio/>}
                          label="Down"
                          labelPlacement="bottom"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                      <WhiteFormLabel component="legend" color="primary">Portfolio</WhiteFormLabel>
                      <RadioGroup aria-label="portfolio" name="portfolio" value={this.state.portfolioValue} onChange={this.handlePortfolioChange} row>
                        <FormControlLabel
                          value="universe"
                          control={<YellowRadio/>}
                          label="Universe"
                          labelPlacement="bottom"
                        />
                        <FormControlLabel
                          value="yourportfolio"
                          control={<YellowRadio/>}
                          label="Your portfolio"
                          labelPlacement="bottom"
                        />
                      </RadioGroup>
                    </FormControl>
                    
					<ReactDataGrid  columns={this.state.scolumns}
							  rowGetter={i => this.state.filteredRows[i]}
							  rowsCount={this.state.filteredRows.length}
							  minHeight={200}
							  headerRowHeight={20}
							  rowHeight={28}
							  onRowClick={this.onGridRowClicked}                       
							  onGridSort={(sortColumn, sortDirection) =>{
								  console.log("sorting");
									this.sortRows(this.state.filteredRows,sortColumn, sortDirection)}}
					/>
			</div>
            <div className="mobileLayer">
			<Chart
                    id="LTFChart"
                    palette="Vintage"
                    onLegendClick={this.onLegendClick}
                    dataSource={this.props.referenceHistory}
                    commonSeriesSettings={{
                        argumentField:"day"
                        ,point:{visible: false}
                        ,barPadding:0.1
                    }}
                    >
                    <Series
                      axis="returnVal"
                      type="bar"
                      valueField="returnval"
                      name="P&L"
                      color="#34B8FA"
                    />
                    <Series
                      axis="returnPct"
                      type="spline"
                      valueField="indexval"
                      name="Index"
                      color="#F44336"
                    />
                    <Series
                      axis="returnPct"
                      type="spline"
                      valueField="pctval"
                      name="%Return"
                      color="#C3A80F"
                    />

                    <ValueAxis
                      name="returnPct"
                      position="left"
                      title={{text:"Return", font:{size: "11px"}}}
                      label={{format:this.pctFormatter}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ValueAxis
                      name="returnVal"
                      position="right"
                      title={{text:"P&L", font:{size: "11px"}}}
                      label={{format:{type:"currency"}}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ArgumentAxis
                      name="returnArg"
                      minorTickInterval={1}
                      defaultVisualRange={{ length: 20 }} 
                      visualRangeUpdateMode={"auto"}/>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      font={{size: "11px"}}
                    />
                    <Tooltip
                      enabled={true}
                      shared={true}
                      customizeTooltip={this.customizeTooltip}
                    >
                      <Format
                        type="largeNumber"
                        precision={1}
                      />
                    </Tooltip>
                    <ZoomAndPan argumentAxis="both" />
            </Chart>
			</div>
            <div className="mobileLayer">
				<Chart
                    id="StockDetailsChart"
                    palette="Vintage"
                    onLegendClick={this.onLegendClick}
                    dataSource={this.props.stockStats}
                    commonSeriesSettings={{
                        argumentField:"day"
                        ,point:{visible: false}
                        ,barPadding:0.1
                    }}
                    >
                    <Series
                      axis="volVal"
                      type="bar"
                      valueField="volume"
                      name="Volume"
                      color="#E6ECBB"
                    />
                    <Series
                      axis="returnPct"
                      type="spline"
                      valueField="closingprice"
                      name={this.props.stockDetail.equityname !== undefined ? this.props.stockDetail.equityname : "Equity" }
                      color="#8BC34A"
                    />
                    <ValueAxis
                      name="returnPct"
                      position="left"
                      title={{text:"Return", font:{size: "11px"}}}
                      label={{format:this.pctFormatter}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ValueAxis
                      name="volVal"
                      position="right"
                      title={{text:"Volume", font:{size: "11px"}}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ArgumentAxis
                      name="returnArg"
                      minorTickInterval={1}
                      defaultVisualRange={{ length: 20 }} 
                      visualRangeUpdateMode={"auto"}/>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      font={{size: "11px"}}
                    />
                    <Tooltip
                      enabled={true}
                      shared={true}
                      customizeTooltip={this.customizeTooltip}
                    >
                      <Format
                        type="largeNumber"
                        precision={1}
                      />
                    </Tooltip>
                    <ZoomAndPan argumentAxis="both" />
				</Chart>
            </div>
		</div>
        );
    }
}