/*
 * action types
 */

export const ACTIONS = {
    
    GET_CHALLENGE                           : 'GET_CHALLENGE'
    ,SET_CURRENT_CHALLENGE                  : 'SET_CURRENT_CHALLENGE'
    ,SET_ALL_CHALLENGES                     : 'SET_ALL_CHALLENGES'
    ,GET_CHALLENGE_STATS                    : 'GET_CHALLENGE_STATS'
    ,SUBSCRIBE_CHALLENGE                    : 'SUBSCRIBE_CHALLENGE'
    ,SET_CHALLENGE_CHANGE                   : 'SET_CHALLENGE_CHANGE'
    ,UPDATE_CHALLENGE                       : 'UPDATE_CHALLENGE'
    ,SET_CHALLENGE_STATUS                   : 'SET_CHALLENGE_STATUS'
    
    //portofolio
    ,ADD_SECURITY_TO_PORTFOLIO_HOLDING     : 'ADD_SECURITY_TO_PORTFOLIO_HOLDING'
    ,REMOVE_SECURITY_FROM_PORTFOLIO_HOLDING : 'REMOVE_SECURITY_FROM_PORTFOLIO_HOLDING'
    ,UPDATE_PORTFOLIO_HOLDING              : 'UPDATE_PORTFOLIO_HOLDING'
    ,UPDATE_PORTFOLIO_HOLDINGS              : 'UPDATE_PORTFOLIO_HOLDINGS'
    ,MODIFY_PORTFOLIO_HOLDING              : 'MODIFY_PORTFOLIO_HOLDING'
    ,GET_PORTFOLIO_HOLDING                 : 'GET_PORTFOLIO_HOLDING'
    ,GET_PORTFOLIO_RETURNS                 : 'GET_PORTFOLIO_RETURNS'
    ,ADD_PORTFOLIO_RETURNS                  :'ADD_PORTFOLIO_RETURNS'
    ,INSERT_ORDER                          : 'INSERT_ORDER'
    ,SAVE_TRANSACTION                       : 'SAVE_TRANSACTION'
    ,UPDATE_HOLDING_REFERENCE_HISTORY       : 'UPDATE_HOLDING_REFERENCE_HISTORY'
    ,SHOW_HIDDEN_SECURITY_IN_HOLDING       : 'SHOW_HIDDEN_SECURITY_IN_HOLDING'
    
    //securitydefinition
    ,GET_SECURITYDEFINITION                 : 'GET_SECURITYDEFINITION'
    ,SET_SECURITYDEFINITION                 : 'SET_SECURITYDEFINITION'
    ,GET_SECURITYSTATS                      : 'GET_SECURITYSTATS'
    ,SET_SECURITYSTATS                      : 'SET_SECURITYSTATS'
    ,GET_ALL_SECURITYDEFINITIONS            : 'GET_ALL_SECURITYDEFINITIONS'
    ,SET_ALL_SECURITYDEFINITIONS            : 'SET_ALL_SECURITYDEFINITIONS'
    ,GET_ALL_SECURITIES_IN_PORTFOLIOHOLDING : 'GET_ALL_SECURITIES_IN_PORTFOLIOHOLDING'
    ,CATCHUP_DATA                           : 'CATCHUP_DATA'
    ,GET_SECURITYSTATSFORCHALLENGE          : 'GET_SECURITYSTATSFORCHALLENGE'
    ,UPDATE_SECURITYHISTORY                 : 'UPDATE_SECURITYHISTORY'
    ,GET_MARKET_INDEX_HISTORY               : 'GET_MARKET_INDEX_HISTORY' 
    ,UPDATE_SECURITYREFERENCE               : 'UPDATE_SECURITYREFERENCE'
    ,SET_UNIVERSE_REFERENCE_DATA            : 'SET_UNIVERSE_REFERENCE_DATA'

    // general commands
    ,SUBSCRIBE_MARKETDATA                   : 'SUBSCRIBE_MARKETDATA'
    ,UNSUBSCRIBE_MARKETDATA                 : 'UNSUBSCRIBE_MARKETDATA'
    ,SUBSCRIBE_ALL_MARKETDATA               : 'SUBSCRIBE_ALL_MARKETDATA'
    ,UNSUBSCRIBE_ALL_MARKETDATA             : 'UNSUBSCRIBE_ALL_MARKETDATA'
    ,SUBSCRIBE_NEWS                         : 'SUBSCRIBE_NEWS'
    ,UNSUBSCRIBE_NEWS                       : 'UNSUBSCRIBE_NEWS'
    ,SUBSCRIBE_ALL_NEWS                     : 'SUBSCRIBE_ALL_NEWS'
    ,UNSUBSCRIBE_ALL_NEWS                   : 'UNSUBSCRIBE_ALL_NEWS'
    ,GET_ACCOUNT_TRANSACTIONS               : 'GET_ACCOUNT_TRANSACTIONS'
    ,GET_POSITION                           : 'GET_POSITION'
    ,SUBSCRIBE_POSITION                     : 'SUBSCRIBE_POSITION'
    ,UNSUBSCRIBE_POSITION                   : 'UNSUBSCRIBE_POSITION'
    ,SET_RANKINGS                           : 'SET_RANKINGS'
    ,SHOW_RANKINGS                          : 'SHOW_RANKINGS'
    ,SHOW_NON_COMPETITVE                    : 'SHOW_NON_COMPETITVE'
    ,SET_MOBILE_DEVICE                      : 'SET_MOBILE_DEVICE'
    ,SET_USER                               : 'SET_USER'
    
    //UI management
    ,CHANGE_LAYOUT_STATUS                   : 'CHANGE_LAYOUT_STATUS'
    ,SET_APPLISTS                           : 'SET_APPLISTS'
    ,SET_APPLIST                            : 'SET_APPLIST'
    ,SAVE_PREVIOUS_WORKSPACE                : 'SAVE_PREVIOUS_WORKSPACE'
    ,BLUR_SCREEN                            : 'BLUR_SCREEN'
    ,RESET_WORKSPACE                        : 'RESET_WORKSPACE'
    ,SET_MOBILE_CARD                        : 'SET_MOBILE_CARD'

    //charts
    ,GET_CHART                              : 'GET_CHART'
    ,GET_PORTFOLIO_CHART                    : 'GET_PORTFOLIO_CHART'
    ,GET_MARKET_CHART                       : 'GET_MARKET_CHART'

    //chat
    ,GET_ROOMS                              : 'GET_ROOMS'
    ,SUBSCRIBE_ROOM                         : 'SUBSCRIBE_ROOM'
    ,UNSUBSCRIBE_ROOM                       : 'UNSUBSCRIBE_ROOM'
    ,SEND_MESSAGE                           : 'SEND_MESSAGE'

    //trading
    ,INSERT_ORDER                           : 'INSERT_ORDER'

    //news
    ,ADD_NEWS                               :'ADD_NEWS'
    
    //analytics
    ,ADD_MARKETINDEX                        : 'ADD_MARKETINDEX'
    ,ADD_ECONOMICINDICATOR                  : 'ADD_ECONOMICINDICATOR'
    ,SET_TRENDING_STOCKS                    : 'SET_TRENDING_STOCKS'  
    ,SET_REFERENCE_HISTORY                  : 'SET_REFERENCE_HISTORY'  
    ,GET_REFERENCE_DATA                     : 'GET_REFERENCE_DATA'  
    ,SET_REFERENCE_DATA                     : 'SET_REFERENCE_DATA'  
    ,GET_REFERENCE_HOLDING_DATA             : 'GET_REFERENCE_HOLDING_DATA'
    
    //service commands
    ,SET_WEBSOCKET_STATE                    : 'SET_WEBSOCKET_STATE'
    ,SHOW_SNACK_MESSAGE                     : 'SHOW_SNACK_MESSAGE'
    ,CLOSE_SNACK_MESSAGE                    : 'CLOSE_SNACK_MESSAGE'
    ,ADD_WORKSPACE                          : 'ADD_WORKSPACE'
    ,SET_WORKSPACE                          : 'SET_WORKSPACE'
    ,SAVE_WORKSPACE                         : 'SAVE_WORKSPACE'
    ,SET_WORKSPACE_STATUS                   : 'SET_WORKSPACE_STATUS'
    ,SAVE_NEW_WORKSPACE                     : 'SAVE_NEW_WORKSPACE'
    ,DELETE_WORKSPACE                       : 'DELETE_WORKSPACE'
    ,REMOVE_WORKSPACE                       : 'REMOVE_WORKSPACE'
    ,SET_ALL_CARDS                          : 'SET_ALL_CARDS'
    ,ADD_NEW_CARD                           : 'ADD_NEW_CARD'
    ,CHANGE_CARDS                           : 'CHANGE_CARDS'
    ,SET_PREFERENCE                         : 'SET_PREFERENCE'
    ,SHOW_DIALOG_MESSAGE                    : 'SHOW_DIALOG_MESSAGE'
    ,CLOSE_DIALOG_MESSAGE                   : 'CLOSE_DIALOG_MESSAGE'
    
    ,SET_RESULTS                            : 'SET_RESULTS'
    ,SEND_ADMIN_MESSAGE                     : 'SEND_ADMIN_MESSAGE'
    ,APPLY_QUIZ_BONUS                       : 'APPLY_QUIZ_BONUS'
 }
 
export const STATUS = {OPEN :'OPEN', CLOSE : 'CLOSE', ERROR :'ERROR'
                       , PENDING: 'PENDING', INITIAL: 'INITIAL', ACTIVE: 'ACTIVE' 
                       , RESET: 'RESET', START:'START', PAUSE: 'PAUSE', STOP: 'STOP'
                       , RESTART: 'RESTART', SAVE: 'SAVE', PERSIST : 'PERSIST', END: 'END'
                       , BLUR: 'BLUR', RESULTS: 'RESULTS'
                       };


export const VARIANT = {INFO :'info', SUCCESS : 'success', ERROR :'error'
                       , WARNING: 'warning'
                       };
                       
export const HANDLER = {WS :'WS', ALL : 'ALL'};
export const SIDE = {BID :1, ASK : 2};
export const ORDERTYPE = {MARKET :"MARKET", LIMIT : "LIMIT", STOP_LIMIT: "STOP_LIMIT"};
export const CHECK = {CHECKMARK :"containercheck", NO_CHECKMARK : "containerNotChecked"};