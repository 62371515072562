import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import MobileCard from './components/Card/MobileCard';
import {STATUS} from '../actions/actiontypes'


import MarketHistoryContainer from '../containers/MarketHistoryContainer';
import EconomicIndicatorsContainer from '../containers/EconomicIndicatorsContainer';
import TrendingStocksContainer from '../containers/TrendingStocksContainer';
import MobileHoldingCreationContainer from '../containers/MobileHoldingCreationContainer';
import MobileStockDetailsContainer from '../containers/MobileStockDetailsContainer';
import MobilePortfolioCompositionContainer from '../containers/MobilePortfolioCompositionContainer';
import MobileNewsContainer from '../containers/MobileNewsContainer';
import MobileReferencePeriodContainer from '../containers/MobileReferencePeriodContainer';
import MobileRankingsContainer from '../containers/MobileRankingsContainer';


export default class MobileCardLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appCard: this.loadDynamicApplist(this.props.appList.mobileView)
            ,ranking: {i: '-1'}
        };
    }

    loadDynamicApplist(app)
    {
        let newapp = {...app};
        switch (app.i)
        {
            case '10':
                newapp = {view: <MarketHistoryContainer/>}
            break;
            case '11':
                newapp = {view: <EconomicIndicatorsContainer/>}
            break;
            case '12':
                newapp = {view: <TrendingStocksContainer/>}
            break;
            case '13':
                newapp = {view: <MobileHoldingCreationContainer/>}
            break;
            case '14':
                newapp = {view: <MobileStockDetailsContainer/>}
            break;
            case '15':
                newapp = {view: <MobilePortfolioCompositionContainer/>}
            break;
            case '16':
                newapp = {view: <MobileNewsContainer/>}
            break;
            case '17':
                newapp = {view: <MobileReferencePeriodContainer/>}
            break;
            case '-1':
                newapp = {view: <MobileRankingsContainer/>}
            break;
        }
        return newapp;        
    }
     
    shouldComponentUpdate(nextProps, nextState)
    {
        let returnVal = false;        
        if (!_.isEqual( this.props.appList.mobileView, nextProps.appList.mobileView ))
        {
            this.setState({
                appCard : this.loadDynamicApplist(nextProps.appList.mobileView)
            });
            this.props.showRankings(false);
            returnVal = true;
            console.log("mobileView prop changed"+ JSON.stringify(nextProps.appList.mobileView));
        }      
        else if(!_.isEqual(this.props.showRanking, nextProps.showRanking) && nextProps.showRanking === true)
        {
            this.setState({
                appCard : this.loadDynamicApplist(this.state.ranking)
            });
            returnVal = true;
            console.log("mobileView prop changed to ranking ");
        }
        if (!_.isEqual( this.state.appCard, nextState.appCard ))
        {
            returnVal = true;
            console.log("mobileView next state changed"+ JSON.stringify(nextState.appCard));
        }
        return returnVal;
    }
     
    generateDOM() {
        console.log("generateDOM called " + JSON.stringify(this.state.appCard));
        return (
        <div key={this.state.appCard.i} className={"mobileFullScreenCard"}>
            <MobileCard 
                cardid={this.state.appCard.i}
                children={this.state.appCard.view}/>          
        </div>
        );
    }
    
    render() {
        console.log("render called " + JSON.stringify(this.state.appCard));
        return (
            <div className="mobileFullScreen">
                  <div key={this.state.appCard.i} className={"mobileFullScreenCard"}>
                <MobileCard 
                    cardid={this.state.appCard.i}
                    children={this.state.appCard.view}/>          
            </div>
            </div>
        );
    }
}


