import { combineReducers } from 'redux';
import _ from "lodash";

import {ACTIONS, STATUS, VARIANT, CHECK} from './actions/actiontypes'

const dataStore = {
    wsocket: {
        wsstate : STATUS.CLOSE
        ,con : null
    }
    ,appList : {
            currentstatus: STATUS.INITIAL
            ,name: "default"
            ,id: "default"
            ,preferred: false
            ,layout: []
            ,mobileView:{x: 0, y: 8, w:12, h:8, i:'13', minW:6, minH:5}
            ,defaultlayout: [
            {x: 0, y: 0, w:4, h:8, i:'10', minW:2, minH:5}
            ,{x: 4, y: 0, w:4, h:8, i:'11', minW:2, minH:5}
            ,{x: 8, y: 0, w:4, h:8, i:'12', minW:2, minH:5}
            ,{x: 0, y: 8, w:12, h:8, i:'13', minW:6, minH:5}
            ,{x: 0, y: 16, w:6, h:8, i:'14', minW:2, minH:5}
            ,{x: 6, y: 16, w:6, h:8, i:'15', minW:2, minH:5}
            ,{x: 0, y: 24, w:12, h:8, i:'16', minW:2, minH:5}
            ]
            ,reference: [
            {x: 0, y: 0, w:8, h:24, i:'17', minW:2, minH:5}
            ,{x: 8, y: 0, w:4, h:12, i:'21', minW:2, minH:5}
            ,{x: 8, y: 10, w:4, h:12, i:'22', minW:2, minH:5}
            ]
            ,layouts : {}
    }
    ,challenge: {
        currentChallenge:{
            challengestatus:STATUS.PENDING
            ,topPerformers : []
            ,bottomPerformers : []
        }
        ,challenges: []
        ,trendingStock: []
    }
    ,cards : {}
    ,holding: {
        cash:100000
        ,cashBalance:100000
        ,holdingValue:0
        ,quizBonus:0
        ,beta:""
        ,equitiesInCurrentHolding: []
        ,transactions: []
        ,equityCheckClass: {}
        ,chartDataSeries: {}
        ,removed: new Set()
        ,nullChart: {indexval: null, pctval:null, returnval: null}

    }
    ,news: []
    ,economicindicators: []
    ,transactions: []
    ,securities: {
        allSecurities: {}
        ,stockDetail: {}
        ,stockReferenceDetail: {}
        ,stockStats: []
        ,referenceStats: []
        ,universeReference: []
    }
    ,snackbar: {
        openSnack:false
        ,snackMessage :"academy service"
        ,variant : VARIANT.INFO
    }
    ,popupDialog:{
        openDialog:false
        ,dialogTitle :"academy service"
        ,dialogMessage :""
        ,confirmMessage : true
        ,proceedFunction :null
    }
    ,workspace:{
        currentstatus : STATUS.INITIAL
        ,workspaces: {"default" :"default", reference: "reference"} 
    }
    ,preference:{}
    ,referenceHistory :[]
    ,rankings: {
        currentRanking:[]   
        ,showRanking:false
    }
    ,isMobile: false
    ,userinfo: {}
};

//utlity///
function getSegmentDuration(data)
{
    let tickfreq = data.tickfreq;
    if (data.hasOwnProperty("segmentmodulus") && data.segmentmodulus > 0)
    {
        tickfreq = data.segmentmodulus;
    }
    let min = Math.floor(tickfreq/60);
    let sec = tickfreq%60;
    return String(min).padStart(2,"0") + ":" + String(sec).padStart(2,"0");    
}
/////////

function holding(state = dataStore.holding, action) 
{    
  switch (action.type) 
  {
    case ACTIONS.SET_ALL_PORTFOLIO_UNIVERSES:
        let newState = {}
        action.text.forEach((portfolio) =>{
                        newState[portfolio.portfoliouniverseid] =  portfolio});
        return {...state, portfoliouniverses : newState};
    case ACTIONS.SET_PORTFOLIO_UNIVERSE:
      let newState1 = {};
      Object.assign(newState1, state, {});
      newState1.portfoliouniverses[action.text.portfoliouniverseid] = action.text;
      return newState1;
    case ACTIONS.ADD_SECURITY_TO_PORTFOLIO_HOLDING:
        //remove the old version  if it exists   
        let newStateA = _.cloneDeep(state);
        let newPU = newStateA.equitiesInCurrentHolding.filter(equity => equity.equityid !== action.text.equityid);
        newPU.push({...action.text});
        newStateA.equitiesInCurrentHolding = newPU;
        newStateA.removed.delete(action.text.equityid);
        newStateA.equityCheckClass[action.text.equityid] = CHECK.CHECKMARK;
        return newStateA;
    case ACTIONS.REMOVE_SECURITY_FROM_PORTFOLIO_HOLDING:    
        let newStateR = _.cloneDeep(state);
        newStateR.removed.add(action.text.equityid);
        newStateR.equityCheckClass[action.text.equityid] = CHECK.NO_CHECKMARK;
        return newStateR;
    case ACTIONS.SHOW_HIDDEN_SECURITY_IN_HOLDING:   
        let newStateS = _.cloneDeep(state);
        newStateS.removed.delete(action.text.equityid);
        newStateS.equityCheckClass[action.text.equityid] = CHECK.CHECKMARK;
        return newStateS;
    case ACTIONS.MODIFY_PORTFOLIO_HOLDING:
        let holdingdata = action.text;
        return {  
            ...state
            ,equitiesInCurrentHolding: state.equitiesInCurrentHolding.map(equity =>
                  equity.equityid === holdingdata.equityid
                    ? {...equity, ...holdingdata}
                    : equity
                )
        }
    case ACTIONS.UPDATE_PORTFOLIO_HOLDING:
        let holding = action.text;
        let cashBal = state.cashBalance + holding.realizedpandl;
        let holdingVal = state.holdingValue + holding.equityvalue;
        holding.pctportfolio = parseInt((holding.value/state.cash)*10000)/100.0; 
        
        let newHoldings1 = [];
        let found  = state.equitiesInCurrentHolding.find(equity => holding.equityid === equity.equityid);
        if (found === undefined)
        {
            newHoldings1 = state.equitiesInCurrentHolding.concat(holding);
        }
        else
        {
            newHoldings1 = state.equitiesInCurrentHolding.map(equity => {
                         if (holding.equityid === equity.equityid)
                         {
                             cashBal = cashBal - (equity.hasOwnProperty("realizedpandl") ? equity.realizedpandl : 0);
                             holdingVal = holdingVal - (equity.hasOwnProperty("equityvalue") ? equity.equityvalue : 0);
                             return {...equity, ...holding};
                         }
                         return equity;
                        })
        }
        return {  
                ...state
                ,cashBalance :cashBal
                ,holdingValue :holdingVal
                ,equitiesInCurrentHolding: newHoldings1
        };
    case ACTIONS.UPDATE_PORTFOLIO_HOLDINGS:
        let newH = action.text;
        if (newH.length < 1)
        {
            return {  
                ...state
                ,equitiesInCurrentHolding: []
            };
        }
        let newHoldings = [];
        let ecps = {...state.equityCheckClass};
        let pBeta = 0;
        newH.position.forEach((holding)=> {
            holding.pctportfolio = parseInt((holding.value/newH.cash)*10000)/100.0; 
            newHoldings.push(holding);
            if (!state.removed.has(holding.equityid))
            {
                ecps[holding.equityid] = CHECK.CHECKMARK;
            }
            pBeta = pBeta + (holding.beta * (holding.value/newH.holdingvalue));
        });
        let sday = String(newH.day);
        let oldData = state.chartDataSeries.hasOwnProperty(sday) ? state.chartDataSeries[sday] : state.nullChart;
        
        console.log("old data = " + JSON.stringify(oldData));
        let newPV1 = {};
        newPV1[String(newH.day)] = {...oldData, ...newH.preturn};
        return {  
                ...state
                ,equityCheckClass: ecps 
                ,cashBalance :newH.cashbalance
                ,holdingValue :newH.holdingvalue
                ,quizBonus :newH.cashaward
                ,equitiesInCurrentHolding: newHoldings
                ,chartDataSeries: {...state.chartDataSeries, ...newPV1}
                ,beta: pBeta
        };
    case ACTIONS.SAVE_TRANSACTION:
          return {  
                ...state
                ,transactions : state.transactions.concat(action.text)
          }
    case ACTIONS.ADD_MARKETINDEX:
            let newCDS = _.cloneDeep(state.chartDataSeries);
            Object.values(action.text).forEach((data)=> {
                             let sday = String(data.day);
                             let olddata = newCDS.hasOwnProperty(sday) ? newCDS[sday] : state.nullChart; 
                             newCDS[sday] = {...olddata, ...data};
            });
            return{...state,
                    chartDataSeries: newCDS
            };
    case ACTIONS.ADD_PORTFOLIO_RETURNS:
            let newData = _.cloneDeep(state.chartDataSeries);
            Object.values(action.text.returns).forEach((data)=> {
                             let sday = String(data.day);
                             let olddata = newData.hasOwnProperty(sday) ? newData[sday] : state.nullChart; 
                             newData[sday] = {...olddata, ...data};
            });
            return{...state,
                    chartDataSeries: newData
            };
    case ACTIONS.APPLY_QUIZ_BONUS:
            let newAward = action.text.award - state.quizBonus;
            
            //remove old amount and set new to make it idempotent
            return{...state
                    ,cash: state.cash + newAward
                    ,cashBalance: state.cashBalance + newAward
                    ,quizBonus: newAward
            };
    default:
      return state
  }
}

function securities(state = dataStore.securities, action) 
{    
  switch (action.type) 
  {
    case ACTIONS.SET_ALL_SECURITYDEFINITIONS:
        let newState = {...state, allSecurities:{}
        };
        action.text.equities.forEach((equity)=> {
            
            newState.allSecurities[equity.equityid] = equity;
        });
        return newState;
    case ACTIONS.SET_SECURITYSTATS:
    
        let newState1 = {...state};
        let day = action.text.day;
        action.text.stats.forEach((stat)=> {
            stat.periodreturn = newState1.allSecurities[stat.equityid].hasOwnProperty("lastprice") ? ((stat.lastprice - newState1.allSecurities[stat.equityid].lastprice)/newState1.allSecurities[stat.equityid].lastprice) *100 : "";
            if (newState1.stockDetail.hasOwnProperty("equityid") && (stat.equityid === newState1.stockDetail.equityid))
            {
                newState1.stockStats.push({day: day, closingprice:stat.lastprice, volume: stat.volume, periodreturn: stat.periodreturn});
            }
            newState1.allSecurities[stat.equityid] = {...newState1.allSecurities[stat.equityid], ...stat};
        });
        return newState1;
    case ACTIONS.SET_SECURITYDEFINITION:      
        return {...state, 
                stockDetail: action.text}
    case ACTIONS.UPDATE_SECURITYHISTORY:  
            let stats = action.text.stats;
        return {...state, 
                stockStats: stats}
    case ACTIONS.UPDATE_SECURITYREFERENCE:  
            let rstats = action.text.stats;
        return {...state, 
                referenceStats: rstats}
    case ACTIONS.SET_UNIVERSE_REFERENCE_DATA:
            let hstats = action.text;
        return {...state, 
                universeReference: hstats};        
    default:
      return state;
  }
}

function news(state = dataStore.news, action) 
{    
  switch (action.type) 
  {
    case ACTIONS.ADD_NEWS:
            return action.text.concat(state);
    case ACTIONS.SET_CHALLENGE_CHANGE:
      if (action.text.challengestatus === STATUS.RESET)
      {
          return [];
      }
    default:
      return state;
  }
}

function economicindicators(state = dataStore.economicindicators, action) 
{    
  switch (action.type) 
  {
    case ACTIONS.ADD_ECONOMICINDICATOR:
            return action.text.sort((e1, e2) =>  {
                            const indicatornameA = e1.indicatorname.toUpperCase(); // ignore upper and lowercase
                              const indicatornameB = e2.indicatorname.toUpperCase(); // ignore upper and lowercase
                              if (indicatornameA > indicatornameB) {
                                return -1;
                              }
                              if (indicatornameA < indicatornameB) {
                                return 1;
                              }
                              // names must be equal
                              return 0;
            });
    default:
      return state;
  }
}

function challenge(state = dataStore.challenge, action) 
{    
  switch (action.type)
  {
    case ACTIONS.ADD_NEW_CHALLENGE:
        let newState = {};
        
        //remove the old version in the challengesarray if it exists
        let newChallenges = state.challenges.filter(challenge => challenge.challengeid !== action.text.challengeid);
        newChallenges.push(action.text);
        
        let segmentduration = getSegmentDuration(action.text);
        //update the current state if it's name matches the new challenge
        if (state.currentChallenge.hasOwnProperty("challengename") && 
                action.text.challengename === state.currentChallenge.challengename)
        {
            newState = 
            {                
                ...state 
                ,currentChallenge:Object.assign({}, state.currentChallenge, action.text)
            }
        }
        newState.challenges = newChallenges;
        newState.currentChallenge.segmentduration = segmentduration;
      return newState;
    case ACTIONS.CLONE_CURRENT_CHALLENGE:
        return { 
            ...state
            ,currentChallenge:{
                        ...state.currentChallenge
                        ,challengename:null
                        ,challengeid:null
                        ,challengestatus: STATUS.PENDING
                        }
      }
    case ACTIONS.SET_ALL_CHALLENGES:
      return {  
            ...state
            ,challenges: action.text
      }
    case ACTIONS.SET_CURRENT_CHALLENGE:
        //remove the old version in the challengesarray if it exists
        let newChallenges1 = state.challenges.filter(challenge => challenge.challengeid !== action.text.challengeid)
        newChallenges1.push(action.text)
      return {  
            ...state
            ,currentChallenge:{
                        ...state.currentChallenge
                        ,...action.text
                       ,segmentduration:   getSegmentDuration(action.text)
            }
            ,challenges: newChallenges1
      }
    case ACTIONS.SET_CURRENT_CHALLENGE_NAME:
      return { 
            ...state
            ,currentChallenge:{
                        ...state.currentChallenge
                        ,challengename:action.text
                        }
      }
    case ACTIONS.SET_CURRENT_CHALLENGEID:
      return {  
            ...state
            ,currentChallenge:{
                        ...state.currentChallenge
                        ,challengeid:action.text
                        }
      }
    case ACTIONS.SET_CHALLENGE_STATUS:
      if (state.currentChallenge.hasOwnProperty("challengeid") 
          && (action.text.challengeid === state.currentChallenge.challengeid))
          {
              return {  
                    ...state
                    ,currentChallenge:{
                                ...state.currentChallenge
                                ,challengestatus:action.text.challengestatus
                                }
              }
          }
          return state;
    case ACTIONS.SET_CHALLENGE_CHANGE:
      if (state.currentChallenge.hasOwnProperty("challengeid") 
          && (action.text.challengeid === state.currentChallenge.challengeid))
          {
              return {  
                    ...state
                    ,currentChallenge:{
                                ...state.currentChallenge
                                ,challengestatus:   action.text.challengestatus
                                ,challengeid:       action.text.challengeid
                                ,currentday:        action.text.currentday
                                ,currentsegment:    action.text.currentsegment
                                ,tickfreq:          action.text.tickfreq
                                ,segmentduration:   getSegmentDuration(action.text)
                                }
              }
          }
          return state;
    case ACTIONS.SET_ALL_CHALLENGES:
      return {  
            ...state
            ,challenges: action.text
      }
    case ACTIONS.ADD_TEAM_TO_CHALLENGE:
      return {  
            ...state
            ,currentChallenge:{
              ...state.currentChallenge
              ,challengeTeams: [...state.currentChallenge.challengeTeams, action.text]
            }
      }
    case ACTIONS.REMOVE_TEAM_FROM_CHALLENGE:
      return {  
            ...state
            ,currentChallenge:{
              ...state.currentChallenge
              ,challengeTeams: state.currentChallenge.challengeTeams.filter(item => item.teamid !== action.text)
            }
      }
    case ACTIONS.MODIFY_TEAM_IN_CHALLENGE:
      return {  
            ...state
            ,currentChallenge:{
              ...state.currentChallenge
              ,challengeTeams: state.currentChallenge.challengeTeams.map(team =>
                  team.teamid === action.text.teamid
                    ? action.text
                    : team
                )
            }
      }
    case ACTIONS.SET_ALL_TEAMS_IN_CHALLENGE:
      if (state.currentChallenge.hasOwnProperty("challengeid") 
          && (action.text.challengeid === state.currentChallenge.challengeid))
          {
              return {  
                    ...state
                    ,currentChallenge:{
                                ...state.currentChallenge
                                ,challengeTeams:   action.text.teams
                                ,teamsstatus: STATUS.RESET
                                }
              }
          }
    case ACTIONS.SET_TEAMS_IN_CHALLENGE_STATUS:
      return {  
            ...state
            ,currentChallenge:{
                        ...state.currentChallenge
                        ,teamsstatus:action.text
                        }
      }
    case ACTIONS.UPDATE_CHALLENGE:
        if (state.currentChallenge.hasOwnProperty("challengeid") && 
                action.text.challengeid === state.currentChallenge.challengeid)
        {
            return {  
                ...state
                ,currentChallenge:{
                            ...state.currentChallenge
                            ,...action.text
                           ,segmentduration:   getSegmentDuration(action.text)
                }
            }
        }
        return state;
    case ACTIONS.SET_TRENDING_STOCKS:
        if (state.currentChallenge.hasOwnProperty("challengeid") && 
                action.text.challengeid === state.currentChallenge.challengeid)
        {
            return {  
                ...state
                ,trendingStock:action.text.data
                };
        }
        return state;
    default:
      return state;
  }
}

function appList(state = dataStore.appList, action) 
{    
  switch (action.type) 
  {
    case ACTIONS.SET_APPLIST:
        let data = state.layouts[action.text];
        if (action.text === "reference")
        {
            data = {layout: state.reference
                ,name: "reference"
                ,id: "reference"
               };
        }
        else if (_.isEmpty(data))
        {
            data = {layout: state.defaultlayout
                ,name: "default"
                ,id: "default"
               };
        }
        return Object.assign({}, state
              , data
              ,{currentstatus: STATUS.RESET});
    case ACTIONS.SET_APPLISTS:
        if (_.isEmpty(action.text))
        {
            return Object.assign({}, state, {
              ...state
              ,layout : state.defaultlayout
              ,currentstatus: STATUS.RESET
            });
        }
        let newState = Object.assign({}, state);
        action.text.forEach((preference)=> {
            if (preference.preferred)
            {
                Object.assign(newState,preference,{currentstatus: STATUS.RESET});
            }
            newState.layouts[preference.id] = preference;
        });
        
        //if user has no preferred workspace then use default
        if (_.isEmpty(newState.layout))
        {
            newState.layout = state.defaultlayout;
            newState.currentstatus = STATUS.RESET;
        }
        return newState;
    case ACTIONS.CHANGE_LAYOUT_STATUS:
      return Object.assign({}, state, {
            currentstatus: action.text
      })
    case ACTIONS.SAVE_NEW_WORKSPACE:
        //remove old preferred if this is the new one
        let newWS = Object.assign({}, state
            ,{id: action.text.id
            ,name: action.text.name
            ,preferred: action.text.preferred
            ,preferencetype: "client"
            });
        if (action.text.preferred)
        {       
            let lo;     
            for (lo in newWS.layouts)
            {
                newWS.layouts[lo].preferred = false;             
            };
        }
        return newWS;
    case ACTIONS.SAVE_PREVIOUS_WORKSPACE:
        let prevState = Object.assign({}, state);
        prevState.layouts["previous"] = action.text;
        return prevState;
    case ACTIONS.ADD_NEW_CARD:
            return Object.assign({}, state, {
              layout : [...state.layout, action.text]
              ,currentstatus: STATUS.RESET
            });
    case ACTIONS.CHANGE_CARDS:
            return Object.assign({}, state, {
              layout : action.text
              ,currentstatus: STATUS.RESET
            });
    case ACTIONS.RESET_WORKSPACE:
        if (state.id === "default")
        {
          return Object.assign({}, state 
                ,{layout: state.defaultlayout
                 ,currentstatus: STATUS.RESET}
          );                 
        }
      return Object.assign({}, state 
            ,state.layouts[state.id]
           ,{currentstatus: STATUS.RESET}
      );
    case ACTIONS.SET_MOBILE_CARD:
            return Object.assign({}, state, {
              mobileView : action.text
            });
    default:
      return state;
  }
}

function wsocket(state = dataStore.wsocket, action) 
{     
  switch (action.type) 
  {
    case "SET_WEBSOCKET":
        //let tmp = JSON.parse(JSON.stringify(state.con));
        //let tmp = action.wsocket;
      return Object.assign({}, state, {
            con: action.wsocket
            })
    case ACTIONS.SET_WEBSOCKET_STATE:
      return Object.assign({}, state, {
            wsstate: action.text
            })
    default:
      return state;
  }
}

function snackbar(state = dataStore.snackbar, action) 
{     
  switch (action.type) 
  {
    case ACTIONS.SHOW_SNACK_MESSAGE:
      return Object.assign({}, state, {
            openSnack: true
            ,snackMessage :action.text.message
            ,variant : action.text.variant
            })
    case ACTIONS.CLOSE_SNACK_MESSAGE:
      return Object.assign({}, state, {
            openSnack: action.text
            })
    default:
      return state;
  }
}

function popupDialog(state = dataStore.popupDialog, action) 
{   
  switch (action.type) 
  {
    case ACTIONS.SHOW_DIALOG_MESSAGE:
      return Object.assign({}, state, {
            openDialog: true
            ,dialogTitle :action.text.title
            ,dialogMessage :action.text.message
            ,confirmMessage: action.text.hasOwnProperty("confirmtype") ? action.text.confirmtype : state.confirmMessage
            ,proceedFunction: action.text.hasOwnProperty("proceedFunction") ? action.text.proceedFunction : null
            ,proceedArg: action.text.hasOwnProperty("proceedArg") ? action.text.proceedArg : null
            })
    case ACTIONS.CLOSE_DIALOG_MESSAGE:
      return Object.assign({}, state, {
            openDialog: false
            })
    default:
      return state;
  }
}

function workspace(state = dataStore.workspace, action) 
{     
  switch (action.type) 
  {
    case ACTIONS.SET_WORKSPACE:
    
        let newState = Object.assign({}, state);
        action.text.forEach(workspace=>{
            newState.workspaces[workspace.id] = workspace.name;
        });
        return newState;
    case ACTIONS.GET_WORKSPACES:
        return state
    case ACTIONS.SET_WORKSPACE_STATUS:
      return Object.assign({}, state, {
            currentstatus: action.text
      });
    case ACTIONS.REMOVE_WORKSPACE:
        let newState1 = Object.assign({}, state);
        delete newState1.workspaces[action.text.id];
        return newState1;
    default:
      return state;
  }
}

function cards(state = dataStore.cards, action) 
{    
  switch (action.type) 
  {
    case ACTIONS.SET_ALL_CARDS:
        let newState = {};
        action.text.forEach(item=> {
            newState[item.i] = item.title;
        });
        return newState;
    default:
      return state;
  }
}

function preference(state = dataStore.preference, action)
{
  switch (action.type) 
  {
    case ACTIONS.SET_PREFERENCE:
        return action.text;
    default:
      return state;
  }
}

function referenceHistory(state = dataStore.referenceHistory, action)
{
  switch (action.type) 
  {
    case ACTIONS.SET_REFERENCE_DATA:        
        let newHistory = {...state};
        Object.values(action.text).forEach((data)=> {
                         let sday = String(data.day);
                         let nd = state.hasOwnProperty(sday) ? state[sday] : {}; 
                         newHistory[sday] = {...nd, ...data};
        });
        return newHistory
    default:
      return state;
  }
}

function rankings(state = dataStore.rankings, action)
{
  switch (action.type) 
  {
    case ACTIONS.SET_RANKINGS:
        return {showRanking:true, currentRanking: action.text};
    case ACTIONS.SHOW_RANKINGS:
        return {...state, showRanking:action.text};
    default:
      return state;
  }
}

function isMobile(state = dataStore.isMobile, action)
{
  switch (action.type) 
  {
    case ACTIONS.SET_MOBILE_DEVICE:
        return action.text;
    default:
      return state;
  }
}

function userinfo(state = dataStore.userinfo, action)
{
  switch (action.type) 
  {
    case ACTIONS.SET_USER:
        return action.text;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  holding
  ,challenge
  ,securities
  ,wsocket
  ,appList
  ,news
  ,snackbar
  ,popupDialog
  ,workspace
  ,cards
  ,preference
  ,economicindicators
  ,referenceHistory
  ,rankings
  ,isMobile
  ,userinfo
})

export default rootReducer;