import { connect } from 'react-redux'
import MobileReferencePeriod from "../jsx/client/MobileReferencePeriod";
import {getAllSecurityDefinitions,getAllMobileReferencePeriods ,
        getSecuritiesStats,setSecurityDetail,getReferenceHoldingReturn
        ,getSecurityStatsForChallenge, getReferenceMarketIndex} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    wsstate : state.wsocket.wsstate
    ,equitiesInCurrentHolding: state.holding.equitiesInCurrentHolding
    ,allEquitiesRows: Object.keys(state.securities.allSecurities).map(security => state.securities.allSecurities[security])
    ,currentChallenge:          state.challenge.currentChallenge
    ,stockDetail: state.securities.stockDetail
    ,stockStats: state.securities.stockStats
    ,referenceHistory: Object.values(state.referenceHistory)
  }
}

const getEquitiesForPortfolio =(securities, portfolioequities) =>{
    return securities.filter(security=> portfolioequities.indexOf(security.equityid) != -1)
}

const mapDispatchToProps = dispatch => {
  return {
    loadAllSecurities: data => {
      dispatch(getAllSecurityDefinitions(data))
    }
    ,getSecuritiesStats: data => {
      dispatch(getSecuritiesStats(data))
    }
    ,setSecurityDetail: data => {
      dispatch(setSecurityDetail(data))
    }  
    ,getSecurityStatsForChallenge: data => {
      dispatch(getSecurityStatsForChallenge(data))
    }    
    ,getReferenceMarketIndex: data => {
      dispatch(getReferenceMarketIndex(data))
    }     
    ,getReferenceHoldingReturn: data => {
      dispatch(getReferenceHoldingReturn(data))
    }  
  }
}   

const MobileReferencePeriodContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileReferencePeriod)

export default MobileReferencePeriodContainer
