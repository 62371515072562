import { connect } from 'react-redux'
import HoldingCreation from "../jsx/client/HoldingCreation";
import {insertOrder
        ,addEquitiesToHolding, removeEquitiesFromHolding
        ,setSecurityDetail,openDialogMessage
        ,modifyHolding,setInfoMessage, setWarningMessage
        ,setErrorMessage, getSecurityStatsForChallenge
        ,showHiddedSecuritiesInHolding} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    allEquitiesRows: Object.keys(state.securities.allSecurities).map(security => state.securities.allSecurities[security])
    ,equitiesInCurrentHolding:  state.holding.equitiesInCurrentHolding
    ,cash:                      state.holding.cash
    ,cashBalance:               state.holding.cashBalance
    ,holdingValue:              state.holding.holdingValue
    ,wsstate:                   state.wsocket.wsstate
    ,currentChallenge:          state.challenge.currentChallenge
    ,equityCheckClass:          state.holding.equityCheckClass
    ,agree:                     state.popupDialog.agree
    ,isMobile:                  state.isMobile
    ,removed:                   state.holding.removed
  }
}

const mapDispatchToProps = dispatch => {
  return {
    insertOrder: data => {
      dispatch(insertOrder(data))
    }
    ,addEquitiesToHolding: data => {
      dispatch(addEquitiesToHolding(data))
    }
    ,removeEquitiesFromHolding: data => {
      dispatch(removeEquitiesFromHolding(data))
    }
    ,modifyHolding: data => {
      dispatch(modifyHolding(data))
    } 
    ,setInfoMessage: data => {
      dispatch(setInfoMessage(data))
    }  
    ,setWarningMessage: data => {
      dispatch(setWarningMessage(data))
    }  
    ,setErrorMessage: data => {
      dispatch(setErrorMessage(data))
    } 
    ,setSecurityDetail: data => {
      dispatch(setSecurityDetail(data))
    }  
    ,openDialogMessage: data => {
      dispatch(openDialogMessage(data))
    }  
    ,getSecurityStatsForChallenge: data => {
      dispatch(getSecurityStatsForChallenge(data))
    }  
    ,showHiddedSecuritiesInHolding: data => {
      dispatch(showHiddedSecuritiesInHolding(data))
    }  
  }
}   

const HoldingCreationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HoldingCreation)

export default HoldingCreationContainer
