import React from 'react';
import CardHeader from './CardHeader';

import './Card.scss';

const Card = props => {
  const { onDeleteClick, onMaximizeClick, onMinimizeClick, cardTitle, isMaximized, cardid, color } = props;
  return (
    <div className="app_card" id={cardid}>
      <CardHeader 
        onDeleteClick={onDeleteClick}
        onMaximizeClick={onMaximizeClick}
        onMinimizeClick={onMinimizeClick}
        cardTitle={cardTitle}
        isMaximized={isMaximized}
        color={color}
      />
      <div className="card_body">
        {props.children}
      </div>
    </div>
  )
}

export default Card;
