import React from 'react';
import MobileHeader from "../components/Header/MobileHeader";
import ModalOpenWorkspace from '../components/Modal/ModalOpenWorkspace';
import imageFile from '../../img/finEDge_logo.svg';
import {STATUS} from '../../actions/actiontypes'

export default class MobileClientHeader extends React.Component {
    image;
    btns;
    counterInterval;
    distance;
    counterColor;
    constructor(props) {
        super(props);
        this.state = { 
        counter         : this.initialCounter
        ,openChallengeDlg : false
        }

        this.image = {
            src: imageFile,
            alt: 'Finedge, Navigating Financial Decisions',
        };

        this.btns = [
        {
          label: 'Reference Period',
          button: 'reference'
        }
        ];
        this.startcounter               = this.startcounter.bind(this);
        this.enableCountdown            = this.enableCountdown.bind(this);
        this.openAddCardDlg             = this.openAddCardDlg.bind(this);
        this.handleCloseModalCardDlg    = this.handleCloseModalCardDlg.bind(this);
        this.handleCloseModalChallengeDlg= this.handleCloseModalChallengeDlg.bind(this);
        this.handleAddCard              = this.handleAddCard.bind(this);
        this.openChallenge              = this.openChallenge.bind(this);
        this.handleOpenChallenge        = this.handleOpenChallenge.bind(this);
        this.confirmAction              = this.confirmAction.bind(this);
        this.viewRanking                = this.viewRanking.bind(this);
        this.displayCards               = this.displayCards.bind(this);
    }
        
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if ((!prevProps.currentChallenge.hasOwnProperty("challengeid")
                && this.props.currentChallenge.hasOwnProperty("challengeid"))
            || 
            (prevProps.currentChallenge.hasOwnProperty("challengeid")
                && this.props.currentChallenge.hasOwnProperty("challengeid")
                && (prevProps.currentChallenge.challengeid !== this.props.currentChallenge.challengeid))
            )
        {
            if (this.props.currentChallenge.challengestatus === STATUS.START)
            {
                this.restartCountdown();
            }
            else
            {
                this.resetCountdown();
            }
        }
        else if ((prevProps.currentChallenge.hasOwnProperty("challengeid")
            && this.props.currentChallenge.hasOwnProperty("challengeid")
            && (prevProps.currentChallenge.challengeid === this.props.currentChallenge.challengeid))
            && ((prevProps.currentChallenge.challengestatus !== this.props.currentChallenge.challengestatus)
            ))
        {
            switch (this.props.currentChallenge.challengestatus)
            {
                case STATUS.INITIAL:  
                case STATUS.ACTIVE: 
                case STATUS.RESET:  
                    this.resetCountdown();
                break;  
                case STATUS.START: 
                    this.restartCountdown(); 
                break;
                case STATUS.PAUSE:
                case STATUS.STOP:
                case STATUS.BLUR:
                    this.disableCountdown();
                break;
                default:
                break;
            }
        }
        else if (this.props.currentChallenge.hasOwnProperty("challengeid")
                 &&(this.props.currentChallenge.challengestatus === STATUS.START)
                 &&(prevProps.currentChallenge.hasOwnProperty("currentsegment")
                 && this.props.currentChallenge.hasOwnProperty("currentsegment")
                 && prevProps.currentChallenge.currentsegment !== this.props.currentChallenge.currentsegment))
        {
            this.restartCountdown();
        }
        else if ((prevProps.currentChallenge.hasOwnProperty("challengeid")
                && this.props.currentChallenge.hasOwnProperty("challengeid")
                && (prevProps.currentChallenge !== this.props.currentChallenge))
            )
        {
            if (this.props.currentChallenge.challengestatus === STATUS.ACTIVE)
            {
                this.resetCountdown();
            }
        }
    }
    enableCountdown()
    {            
        if (this.counterInterval !== undefined)
        {
            this.disableCountdown();
        }
        let [minStr, secStr] = this.state.counter.split(":");
        let min = parseInt(minStr);
        let sec = parseInt(secStr);
        
        let countDownDate = new Date().getTime() + (min*60*1000) + (sec*1000) ;
        let now = new Date().getTime();
        this.distance = (countDownDate - now)/1000;
        
        this.counterInterval = setInterval(this.startcounter, 1000);
    }
    
    startcounter()
    { 
        --this.distance;        
        // Time calculations for hours, minutes and seconds
        let minutes = Math.floor((this.distance/ 60));
        let seconds = Math.floor(this.distance % 60);
        
        let oldCounterColor = this.counterColor;
        this.counterColor = this.colorSelect(minutes, seconds);
        //some optimization 
        if (this.counterColor !== oldCounterColor)
        {
            document.getElementById("countdowntimer").style.color = this.counterColor;
            oldCounterColor = this.counterColor;
        }
        
        if (minutes < 0 && seconds < 0)
        {
            this.disableCountdown();
            this.greyset = false;
            return;
        }
        this.setState({counter : String(minutes).padStart(2,"0")
                                + ":"+String(seconds).padStart(2,"0")
            });
        if (minutes === 0 && seconds === 0)
        {
            this.disableCountdown();
            return;
        }
    }    
    
    colorSelect(minutes, seconds)
    {
        if (minutes > 0)
        {
            return "";
        }
        else if (minutes === 0)
        {
            if (seconds > 30)
            {
            return "";
            }
            else if ((seconds < 31) && (seconds > 10))
            {
                return "#FFFF00";
            }
            else if (seconds < 11)
            {
                return "#FF0000";
            }
        }
    }
    
    resetCountdown()
    {   
        this.disableCountdown();
        this.initCountdown();        
    }
    
    disableCountdown()
    {        
        if (this.counterInterval !== undefined)
        {
            clearInterval(this.counterInterval);
        }
    }
    
    setInitColor()
    {
        let [minStr, secStr] = this.props.currentChallenge.segmentduration.split(":");
        let min = parseInt(minStr);
        let sec = parseInt(secStr);
        let initColor = this.colorSelect(min, sec);
        document.getElementById("countdowntimer").style.color = initColor;
    }
    
    initCountdown()
    {
        if (this.props.currentChallenge.hasOwnProperty("segmentduration"))
        {
            this.setInitColor();
            this.setState({counter : this.props.currentChallenge.segmentduration});
        }
    }
    
    restartCountdown()
    {
        if (this.props.currentChallenge.hasOwnProperty("segmentduration"))
        {
            this.setInitColor();
            this.setState({counter : this.props.currentChallenge.segmentduration}, this.enableCountdown);
        }
    }
    
    isValid()
    {
        if (!this.props.currentChallenge.hasOwnProperty("challengeid"))
        {
            this.props.setErrorMessage("Invalid challengeid or challenge not created ");
            return false;
        }
        return true;
    }
    
    signOut()
    {
        document.cookie = "sessiontoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        document.cookie = "userinfo= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.reload(true);
        console.log("signout");
    }
    
    viewRanking()
    {
        console.log("viewRanking");
        this.props.showRankings(true);
    }

    openAddCardDlg()
    {
        console.log("openAddCardDlg");
        this.setState({openCardDlg: true});
    }
    
    handleAddCard(id)
    {
        console.log("handleAddCard");
        let data = {x: 0, y: 0, w:6, h:8, i:id, minW:2, minH:5};
        this.props.setCurrentCard(data);
        this.setState({openCardDlg: false});
    }    
    
    handleCloseModalCardDlg()
    {
        console.log("handleCloseModalCardDlg");
        this.setState({openCardDlg: false});
    }
    
    displayCards()
    {
        let cards = [];
        Object.keys(this.props.cards).forEach((cardkey) => {
                cards.push({k: cardkey, v: this.props.cards[cardkey]});
        });
        return cards;
    }
    displayAllChallenges()
    {
        let challengesObj = this.props.challenges.map(challenge=>(
            {k: challenge.challengeid, v: challenge.challengename}));
        return challengesObj;
    }
    
    openChallenge()
    {
        console.log("openChallenge");
        this.props.getAllChallenges("ACTIVE");
        this.setState({openChallengeDlg: true});
    }
    
    handleOpenChallenge(id)
    {
        console.log("handleOpenChallenge for "+id);
        let challengeid = parseInt(id);
        this.props.getChallenge(challengeid);
        let challenge = this.props.challenges.find(data=>{
                              return data.challengeid === challengeid;
                            });
        this.props.getEquitiesInPortfolio(challenge.portfoliouniverseid);
        this.props.getChallengeStats(challengeid);
        this.props.subscribeChallenge(challengeid);
        this.props.getPosition(challengeid);
        this.props.getReturns(challengeid);
        this.props.catchupData(challengeid);
        this.setState({openChallengeDlg: false});
    }
    handleCloseModalChallengeDlg()
    {
        console.log("handleCloseModalChallengeDlg");
        this.setState({openChallengeDlg: false});
    }
    
    handleReferenceToggle()
    {        
        this.props.setApplist("reference");
    }
    
    confirmAction()
    {
        console.log("confirmAction");
        let dialogData = {
            title: "Run command"
            ,message: "Are you sure you want to run this command?"
        }
        this.props.openDialogMessage(dialogData);
    }
    
    render()
    {
        let totalValue = this.props.holding.holdingValue + this.props.holding.cashBalance;
        const headerprops = {
        logoLink         :this.image.src
        ,logoAlt          :this.image.alt
        ,counter          :this.state.counter
        ,segmentCurrent   :this.props.currentChallenge.currentsegment
        ,segmentTotal     :this.props.currentChallenge.totalsegment
        ,dayCurrent       :this.props.currentChallenge.currentday
        ,dayTotal         :this.props.currentChallenge.totaldays
        ,buttonData       :this.btns
        ,challengeName    :this.props.currentChallenge.challengename
        ,portfolioValue   :this.props.holding.holdingValue.toLocaleString()
        ,cashAvailable    :this.props.holding.cashBalance.toLocaleString()
        ,totalValue       :totalValue.toLocaleString()
        ,quizBonus        :this.props.holding.quizBonus.toLocaleString()
        ,beta             :this.props.holding.beta.toLocaleString()
        ,portofolioReturn :(((totalValue - this.props.holding.cash)/this.props.holding.cash) * 100).toLocaleString()
        ,signOutClick             : this.signOut
        ,addCardClick             : this.openAddCardDlg 
        ,openChallengeClick       : this.openChallenge
        ,referenceClick           : this.handleReferenceToggle
        ,viewRankingClick         : this.viewRanking
        };
        return (
        <div>
        <MobileHeader {...headerprops}/>
            <ModalOpenWorkspace 
                        data={this.displayAllChallenges()} 
                        handleOpenWorkspace={this.handleOpenChallenge} 
                        openModal={this.state.openChallengeDlg}
                        handleClose = {this.handleCloseModalChallengeDlg}
                        title={"Open Challenge"}
                        canDelete={false}
            />
            <ModalOpenWorkspace 
                        data={this.displayCards()} 
                        handleOpenWorkspace={this.handleAddCard} 
                        openModal={this.state.openCardDlg}
                        handleClose = {this.handleCloseModalCardDlg}
                        title={"Open Tool"}
                        canDelete={false}
            />
        </div>
        )
    }
}   