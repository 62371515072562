import { connect } from 'react-redux'
import TrendingStocks from "../jsx/client/TrendingStocks";

const mapStateToProps = state => {
  return {
    currentChallenge : state.challenge.currentChallenge
    ,trendingStock: state.challenge.trendingStock
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const TrendingStocksContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrendingStocks)

export default TrendingStocksContainer
