import { connect } from 'react-redux'
import LTFSnackBar from "../jsx/LTFSnackBar";
import {closeSnackMessage} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    openSnack : state.snackbar.openSnack
    ,snackMessage : state.snackbar.snackMessage
    ,variant : state.snackbar.variant
  }
}

const mapDispatchToProps = dispatch => {
    return {
        closeSnackMessage: data => {
            dispatch(closeSnackMessage(data))
        }
    }
}   

const LTFSnackBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LTFSnackBar)

export default LTFSnackBarContainer
