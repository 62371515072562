import React from 'react';
import ReactDataGrid from 'react-data-grid';
import CheckboxForTable from './components/CheckboxForTable';
import '../css/table.css';
import classnames from 'classnames';
import './BlurScreen.scss';  

export default class Rankings extends React.Component { 
    constructor(props)
    {
        super(props);
        const defaultColumnProperties = {
          resizable: true
          ,sortable: true
        };
        
        let competitiveCheck ={0:CheckboxForTable.uncheckedStateClass,  1 : CheckboxForTable.checkedStateClass}
        this.state = {
            scolumns : [
                  { key: 'school', name: 'School', width: 320}
                  ,{ key: 'teamname', name: 'Team', width: 320} 
                  ,{ key: 'competitive', name: 'Competitive', width: 100, formatter:<CheckboxForTable checkClass={competitiveCheck}/>} 
                  ,{ key: 'rank', name: 'Rank', width: 120} 
                  ,{ key: 'total', name: 'Total', width: 150} 
                  ,{ key: 'return', name: 'Return', width: 250} 
                  ].map(c => ({ ...c, ...defaultColumnProperties }))    
            ,filteredRows : this.props.rankings.currentRanking.filter(rows=> rows.competitive==1)
            ,includeNonCompetitve : false

        }   
        this.sortRows = this.sortRows.bind(this);
        this.backToPortfolio = this.backToPortfolio.bind(this);
        this.competitiveBoxHandler  = this.competitiveBoxHandler.bind(this);
        this.showNonCompetitve       = this.showNonCompetitve.bind(this);
    }  
    
    backToPortfolio()
    {
        this.props.showRankings(false);
    }
            
    sortRows(initialRows, sortColumn, sortDirection)
    {
        let rows = initialRows;
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                if (typeof myVar === 'string')
                {
                    return a[sortColumn].toLowerCase() > b[sortColumn].toLowerCase() ? 1 : -1;
                }
                else
                {
                    return a[sortColumn] > b[sortColumn] ? 1 : -1;
                }
            } 
            else if (sortDirection === "DESC") {
                if (typeof myVar === 'string')
                {
                    return a[sortColumn].toLowerCase() < b[sortColumn].toLowerCase() ? 1 : -1;
                }
                else
                {
                    return a[sortColumn] < b[sortColumn] ? 1 : -1;
                }
            }
        };
        this.setState({filteredRows: (sortDirection === "NONE" ? initialRows : [...rows].sort(comparer))});
    }
    
    competitiveBoxHandler() {
        if (document.getElementById("ranking.noncompetitive").checked)
        {
            this.showNonCompetitve();
        }
        else
        {
                this.setState(state=>
                {
                      let newRows = this.props.rankings.currentRanking.filter(rows=> rows.competitive===1);
                      return {
                          filteredRows: newRows
                        ,includeNonCompetitve: false
                      };
                });
        }
    }

    showNonCompetitve() {
        this.setState(state=>
        {
              let newRows = this.props.rankings.currentRanking;
              return {
                  filteredRows: newRows
                  ,includeNonCompetitve: true
              };
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps.rankings.currentRanking !== this.props.rankings.currentRanking)
        {
            if (!this.state.includeNonCompetitve)
            {
                this.setState(state=>
                {
                      let newRows = this.props.rankings.currentRanking.filter(rows=> rows.competitive==1);
                      return {
                          filteredRows: newRows
                      };
                });
            }
            else
            {
                this.setState(state=>
                {
                      let newRows = this.props.rankings.currentRanking;
                      return {
                          filteredRows: newRows
                      };
                });
            }
        }            
    }

    render(){
        const modalClassname= classnames("rankingbg", {"active": !this.props.rankings.showRanking});  
        return (          
            <div className={modalClassname}>
                <div className="rankingCard">
                    <div className="rankingHeader">
                        <span className="heading" >Rankings</span>
                        <label className="rankbox">Include non competitive
                            <input type="checkbox" id="ranking.noncompetitive" onClick={this.competitiveBoxHandler}/>
                            <span className="checkmark"></span>
                        </label >
                        <span className="btn" onClick={this.backToPortfolio}>Close</span>
                    </div>
                    <div className="rankingGrid">
                        <ReactDataGrid  columns={this.state.scolumns}
                              rowGetter={i => this.state.filteredRows[i]}
                              rowsCount={18}                          
                              onGridSort={(sortColumn, sortDirection) =>{
                                    this.sortRows(this.state.filteredRows,sortColumn, sortDirection)}}
                           minHeight={200}
                           headerRowHeight={20}
                           rowHeight={28}
                      />
                    </div>
                </div>
            </div>
        );
    }
}


