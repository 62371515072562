import { connect } from 'react-redux'
import ClientHeader from "../jsx/client/ClientHeader";
import {getChallenge, setApplistStatus, subscribeChallenge
        ,getChallengeStats, setWorkspaceStatus, setApplist
        ,saveWorkspace, setWorkspaceData
        ,addNewCard, getAllChallenges, getEquitiesInPortfolio
        ,openDialogMessage, setErrorMessage, restoreWorkspace
        ,getPosition, getReturns, catchupData, sendAdminMessage
        ,deleteWorkspace, showRankings, getUniverseReferenceData} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    challenges: state.challenge.challenges
    ,currentChallenge: state.challenge.currentChallenge
    ,workspace : state.workspace
    ,appList: state.appList
    ,cards: state.cards
    ,holding: state.holding
    ,rankings:state.rankings
    ,userinfo:state.userinfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getChallenge: id => {
      dispatch(getChallenge(id));
    }
    ,resetApplistStatus: data => {
      dispatch(setApplistStatus(data))
    }
    ,setWorkspaceStatus: data => {
      dispatch(setWorkspaceStatus(data))
    }
    ,setApplist: data => {
      dispatch(setApplist(data))
    }
    ,saveWorkspace: data => {
      dispatch(saveWorkspace(data))
    }
    ,setWorkspaceData: data => {
      dispatch(setWorkspaceData(data))
    }
    ,addNewCard: data => {
      dispatch(addNewCard(data))
    }   
    ,getAllChallenges: data => {
      dispatch(getAllChallenges(data))
    }   
    ,getEquitiesInPortfolio: data => {
      dispatch(getEquitiesInPortfolio(data))
    }  
    ,openDialogMessage: data => {
      dispatch(openDialogMessage(data))
    }  
    ,setErrorMessage: data => {
      dispatch(setErrorMessage(data))
    }    
    ,restoreWorkspace: data => {
      dispatch(restoreWorkspace(data))
    }  
    ,subscribeChallenge: data => {
      dispatch(subscribeChallenge(data))
    }  
    ,getChallengeStats: data => {
      dispatch(getChallengeStats(data))
    }  
    ,getPosition: data => {
      dispatch(getPosition(data))
    }  
    ,getReturns: data => {
      dispatch(getReturns(data))
    }  
    ,catchupData: data => {
      dispatch(catchupData(data))
    }  
    ,sendAdminMessage: data => {
      dispatch(sendAdminMessage(data))
    }  
    ,deleteWorkspace: data => {
      dispatch(deleteWorkspace(data))
    } 
    ,showRankings: data => {
      dispatch(showRankings(data))
    } 
    ,getUniverseReferenceData: data => {
      dispatch(getUniverseReferenceData(data))
    } 
  }
}   

const ClientHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientHeader)

export default ClientHeaderContainer
