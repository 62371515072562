import * as AC from "./actions/actionCreators";

export default class WebSocketCallback
{
    store;
    dataHandler = {};
    constructor(store)
    {
        this.store = store;
        
        this.dataHandler = 
        {
            //portfolio
            orderupdate:                                     this.saveTransaction
            ,getportfolioholding:                            this.setAllPortfolioHolding
            ,getposition:                                    this.updateAllPortfolioHolding
            ,portfolioholdingupdate:                         this.updatePortfolioHolding
            ,allportfolioholdingupdate:                      this.updateAllPortfolioHolding
            ,getreturns:                                     this.addReturns
            ,getreferenceholdingreturn:                      this.updateReferenceData
            ,getuniversereferencedata:                       this.setUniverseReferenceData
            
            //challenge
            ,getallchallenges:                               this.setAllChallenges
            ,getchallenge:                                   this.setCurrentChallenge
            ,blurchallenge:                                  this.setChallengeStatus
            ,challengechange:                                this.setChallengeChange
            ,updatechallenge:                                this.updateChallenge
            ,rankingstatus:                                  this.showRankings
            ,getrankings:                                    this.saveRankings
            
            //security definition
            ,getsecuritystats:                               this.addSecurityStats
            ,getallsecuritiesinportfoliouniverse:            this.setAllSecurityDefinitions
            ,statsupdate:                                    this.statsUpdate                    
            ,getchallengestats:                              this.statsUpdate    
            ,getsecuritystatsforchallenge:                   this.updateSecurityHistory
            ,getreferencestatsforchallenge:                  this.updateSecurityReference
            ,getmireferencedata:                             this.updateReferenceData
            
            // general commands                          
            ,saveworkspace:                                  this.setApplist
            ,newsupdate:                                     this.addNews   
            ,eiupdate:                                       this.addEconomicIndicator   
            ,miupdate:                                       this.addMarketIndex    
            ,deleteworkspace:                                this.deleteWorkspace
                                                             
            //analytics                                  
            ,trendingstock:                                  this.setTrendingStock
            ,setcards:                                       this.setCards
            
            //quiz
            ,cashaward:                                      this.applyQuizBonus
            
        }
    }
    handleError=(errormessage)=>{        
        this.store.dispatch(AC.setErrorMessage(errormessage));
    }
    handle=(mtype, message)=>{
        if (!this.dataHandler.hasOwnProperty(mtype))
        {
            this.noop(mtype, message);
        }
        else
        {
            this.dataHandler[mtype](mtype, message);
        }
    }
    
    noop=(mtype, message)=>{
        console.log("doing nothing for: "+ mtype+ " message is: "+message);
    }
    
    notifyonly=(mtype, message)=>{
        this.store.dispatch(AC.setSuccessMessage(mtype + " was successful"));
    }
    
    setWebsocketState=(state)=>{
        this.store.dispatch(AC.setwebSocketState(state));
    }
    
    setAllSecurityDefinitions=(mtype, message)=>{
        this.store.dispatch(AC.setAllSecurityDefinitions(message.data));
    }
    
    addSecurityStats=(mtype, message)=>{
  //      this.store.dispatch(AC.setSecuritiesStats(message.data.stats));
    }
    
    statsUpdate=(mtype, message)=>{
        console.log("statsUpdate: "+ mtype+ " message is: "+message);
        this.store.dispatch(AC.statsUpdate(message));
    }
    
    addPortfolioUniverse=(mtype, message)=>{
    //    this.store.dispatch(AC.setPortfolioUniverse(message.data));
        this.store.dispatch(AC.setSuccessMessage("portfolio universe ("+message.data.portfolioname +") successfully created. portfoliouniverseid = "+message.data.portfoliouniverseid));
    }
    addAllPortfolioUniverses=(mtype, message)=>{
  //      this.store.dispatch(AC.setAllPortfolioUniverses(message.data));
    }    
  
    updateChallenge=(mtype, message)=>{
        this.store.dispatch(AC.updateChallenge(message.data));
    }
    
    setChallengeStatus=(mtype, message)=>{
        this.store.dispatch(AC.setChallengeStatus(message.data));
    }
    
    setChallengeChange=(mtype, message)=>{
        this.store.dispatch(AC.setChallengeChange(message.data));
    }
    
    setApplist=(mtype, message)=>{
        this.store.dispatch(AC.setApplists(message.data));
        this.store.dispatch(AC.setWorkspace(message.data));
    }
    
    
    setCards=(mtype, message)=>{
        this.store.dispatch(AC.setCards(message.data));
    }   

    setAllChallenges=(mtype, message)=>{
        this.store.dispatch(AC.setAllChallenges(message.data));
    }   
    setCurrentChallenge=(mtype, message)=>{
        this.store.dispatch(AC.setCurrentChallenge(message.data));
    }     
    addNews=(mtype, message)=>{
        this.store.dispatch(AC.addNews(message.news));
    }      
    addEconomicIndicator=(mtype, message)=>{
        this.store.dispatch(AC.addEconomicIndicator(message.data));
    }      
    addMarketIndex=(mtype, message)=>{
        this.store.dispatch(AC.addMarketIndex(message.data));
    }     
    saveTransaction=(mtype, message)=>{
        this.store.dispatch(AC.saveTransaction(message.data));
    }       
    updatePortfolioHolding=(mtype, message)=>{
        let securities = this.store.getState().securities.allSecurities;
        let data = {...securities[message.data.equityid], ...message.data};
        this.store.dispatch(AC.updatePosition(data));
    }   
    updateAllPortfolioHolding=(mtype, message)=>{
        let securities = this.store.getState().securities.allSecurities;
        let newData = message.data.position.map(position=>
            {
                let np = {...securities[position.equityid], ...position};
                return np;
            }
        );
        message.data.position = newData;
        this.store.dispatch(AC.updatePositions(message.data));
    }     
    setTrendingStock=(mtype, message)=>{
        this.store.dispatch(AC.setTrendingStock(message));
    }      
    addReturns=(mtype, message)=>{
        this.store.dispatch(AC.addReturns(message.data));
    }   
    updateSecurityHistory=(mtype, message)=>{
        this.store.dispatch(AC.updateSecurityHistory(message.data));
    }    
    updateSecurityReference=(mtype, message)=>{
        this.store.dispatch(AC.updateSecurityReference(message.data));
    }      
    updateReferenceData=(mtype, message)=>{
        this.store.dispatch(AC.updateReferenceData(message.data));
    }       
    setUniverseReferenceData=(mtype, message)=>{
        this.store.dispatch(AC.setUniverseReferenceData(message.data));
    }  
    
    applyQuizBonus=(mtype, message)=>{
        this.store.dispatch(AC.applyQuizBonus(message));
    }        
    
    showRankings=(mtype, message)=>{
        console.log("showRankings rankings = " +JSON.stringify(message));
    //  this.store.dispatch(AC.setChallengeStatus(message.data));
    //  this.store.dispatch(AC.setApplist("rankings"));
        this.store.dispatch(AC.showRankings(true));
        
    }
    
    saveRankings=(mtype, message)=>{
        console.log("saveRankingsnew rankings = " +JSON.stringify(message));
        this.store.dispatch(AC.saveRankings(message.data));
    }
    deleteWorkspace=(mtype, message)=>{
        this.store.dispatch(AC.removeWorkspaceFromList(message.data));
    }    
    
}