import { connect } from 'react-redux'
import News from "../jsx/client/News";

const mapStateToProps = state => {
  return {
    news: state.news
    ,holding: state.holding.equitiesInCurrentHolding
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const NewsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(News)

export default NewsContainer
