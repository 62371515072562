import { connect } from 'react-redux'
import ReferenceChart from "../jsx/client/ReferenceChart";

const mapStateToProps = state => {
  return {
    referenceHistory: Object.values(state.referenceHistory)
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const ReferenceChartContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceChart)

export default ReferenceChartContainer
