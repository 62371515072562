import React from 'react';
import ReactDataGrid from 'react-data-grid';
import _ from "lodash";
//import DataGrid from 'devextreme-react/data-grid';

export default class MobileNews extends React.Component {
          
  constructor(props) {
    super(props);
    const divHighlight = {
      backgroundColor: '#FFFF99'
    };
    const blank = {
    };
    const defaultColumnProperties = {
      resizable: true
      ,sortable: true
    };
    
    this.state = {
        scolumns : [
              { key: 'day', name: 'Day', width: 40}
              ,{ key: 'ticker', name: 'Ticker', width: 50} 
              ,{ key: 'title', name: 'Title', width: 200} 
              ,{ key: 'article', name: 'Article', width: 1500} 
              ].map(c => ({ ...c, ...defaultColumnProperties })) 
        ,filteredRows : this.props.news
    }   

    
    this.RowRenderer            = this.RowRenderer.bind(this);          
    this.sortRows              = this.sortRows.bind(this);    
  }
  
    RowRenderer({ renderBaseRow, ...rowprops }){
        let found = this.props.holding.find(equity => equity.equityid === rowprops.row.equityid);
        let data = renderBaseRow(rowprops);
        let color = found ? "#FFEB3B" : "";
        return <div style={{ color: color }}>{renderBaseRow(rowprops)}</div>;
    }
    
    shouldComponentUpdate(nextProps, nextState)
    {
        let returnVal = false;
        if (!_.isEqual( this.props.news, nextProps.news ))
        {
            let filters = this.state.filters;
            this.setState({filteredRows : nextProps.news});
        }
        if (!_.isEqual( this.state.filteredRows, nextState.filteredRows ))
        {
            returnVal = true;
        }
        return returnVal;
    }
            
    sortRows(initialRows, sortColumn, sortDirection)
    {
        let rows = initialRows;
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } 
            else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        this.setState({filteredRows: (sortDirection === "NONE" ? initialRows : [...rows].sort(comparer))});
    }
    
    render(){
       return (
            <div className="card_full_contain news">
                <ReactDataGrid  columns={this.state.scolumns}
                              rowGetter={i => this.state.filteredRows[i]}
                              rowsCount={this.state.filteredRows.length}
                              minHeight={200}
                              headerRowHeight={20}
                              rowHeight={28}
                              rowRenderer={this.RowRenderer}                    
                              onGridSort={(sortColumn, sortDirection) =>{
                                    this.sortRows(this.state.filteredRows,sortColumn, sortDirection)}}
                    />
                </div>

  );
  }
}
