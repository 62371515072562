import { connect } from 'react-redux'
import MobileClientHeader from "../jsx/client/MobileClientHeader";
import {getChallenge, setApplistStatus, subscribeChallenge
        ,getChallengeStats 
        ,addNewCard, getAllChallenges, getEquitiesInPortfolio
        ,setErrorMessage,getPosition, getReturns, catchupData
        ,sendAdminMessage, showRankings,setCurrentCard} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    challenges: state.challenge.challenges
    ,currentChallenge: state.challenge.currentChallenge
    ,workspace : state.workspace
    ,appList: state.appList
    ,cards: state.cards
    ,holding: state.holding
    ,rankings:state.rankings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getChallenge: id => {
      dispatch(getChallenge(id));
    }
    ,resetApplistStatus: data => {
      dispatch(setApplistStatus(data))
    }
    ,addNewCard: data => {
      dispatch(addNewCard(data))
    }   
    ,getAllChallenges: data => {
      dispatch(getAllChallenges(data))
    }   
    ,getEquitiesInPortfolio: data => {
      dispatch(getEquitiesInPortfolio(data))
    } 
    ,setErrorMessage: data => {
      dispatch(setErrorMessage(data))
    }    
    ,subscribeChallenge: data => {
      dispatch(subscribeChallenge(data))
    }  
    ,getChallengeStats: data => {
      dispatch(getChallengeStats(data))
    }  
    ,getPosition: data => {
      dispatch(getPosition(data))
    }  
    ,getReturns: data => {
      dispatch(getReturns(data))
    }  
    ,catchupData: data => {
      dispatch(catchupData(data))
    }  
    ,sendAdminMessage: data => {
      dispatch(sendAdminMessage(data))
    }  
    ,showRankings: data => {
      dispatch(showRankings(data))
    } 
    ,setCurrentCard: data => {
      dispatch(setCurrentCard(data))
    } 
  }
}   

const MobileClientHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileClientHeader)

export default MobileClientHeaderContainer
