import React from 'react';    
import _ from "lodash";
    
import Chart, {
  CommonSeriesSettings
  ,Series
  ,ValueAxis
  ,ArgumentAxis
  ,Legend
  ,Tooltip
  ,Title
  ,Grid
  ,Format
  ,ZoomAndPan
} from 'devextreme-react/chart';
import './LTFChart.scss';  

export default class ReferenceChart extends React.Component {
          
    constructor(props) {
        super(props);
        
        this.palette =['#F44336','#c3a80f','#4fc620']; 
        this.state = {
        lastDrawLocation: null
        ,crosshairValues: []
        };
    }

    
    pctFormatter(value)
    {
        return `${value}%`;
    }
    
    customizeTooltip(pointInfo) 
    {
        const items = pointInfo.valueText.split('\n');
        const color = pointInfo.point.getColor();

        items.forEach((item, index) => {
        if(item.indexOf(pointInfo.seriesName) === 0) {
          const element = document.createElement('span');

          element.textContent = item;
          element.style.color = color;
          element.className = 'active';

          items[index] = element.outerHTML;
        }
        });

        return { text: items.join('\n') };
    }
     
    onLegendClick({ target: series }) 
    {
        if(series.isVisible()) 
        {
            series.hide();
        } 
        else 
        {
            series.show();
        }
    }

    render(){
        let colorIndx = 0;
        let lastDrawLocation = this.state.lastDrawLocation;
        return (
            <Chart
                    id="LTFChart"
                    palette="Vintage"
                    onLegendClick={this.onLegendClick}
                    dataSource={this.props.referenceHistory}
                    commonSeriesSettings={{
                        argumentField:"day"
                        ,point:{visible: false}
                        ,barPadding:0.1
                    }}
                    >
                    <Series
                      axis="returnPct"
                      type="spline"
                      valueField="indexval"
                      name="Index"
                      color="#F44336"
                    />
                    <Series
                      axis="returnPct"
                      type="spline"
                      valueField="pctval"
                      name="%Return"
                      color="#C3A80F"
                    />

                    <ValueAxis
                      name="returnPct"
                      position="left"
                      title={{text:"Return", font:{size: "11px"}}}
                      label={{format:this.pctFormatter}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ArgumentAxis
                      name="returnArg"
                      minorTickInterval={1}
                      defaultVisualRange={{ length: 125 }} 
                      visualRangeUpdateMode={"auto"}/>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      font={{size: "11px"}}
                    />
                    <Tooltip
                      enabled={true}
                      shared={true}
                      customizeTooltip={this.customizeTooltip}
                    >
                      <Format
                        type="largeNumber"
                        precision={1}
                      />
                    </Tooltip>
                    <ZoomAndPan argumentAxis="both" />
            </Chart>
        );
    }
}
