import { connect } from 'react-redux'
import CardGridLayout from "../jsx/CardGridLayout";
import {setApplistStatus, setWorkspaceStatus, saveWorkspace
        ,changeCard, savePreviousWorkspace} from "../actions/actionCreators";

const mapStateToProps = (state, ownProps) => {
  return {
    appList: state.appList
    ,workspace: state.workspace
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setApplistStatus: data => {
      dispatch(setApplistStatus(data))
    }
    ,setWorkspaceStatus: data => {
      dispatch(setWorkspaceStatus(data))
    }
    ,saveWorkspace: data => {
      dispatch(saveWorkspace(data))
    }
    ,changeCard: data => {
      dispatch(changeCard(data))
    }
    ,savePreviousWorkspace: data => {
      dispatch(savePreviousWorkspace(data))
    }
  }
}   

const CardGridLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardGridLayout)

export default CardGridLayoutContainer
