import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { blueGrey, green, teal, grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

export default class LTFDialog extends React.Component { 
    classes;
    constructor(props)
    {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        
        this.classes = {
          dialog: {
            backgroundColor: blueGrey[100],
            fontSize: 50,
              height: 10,
          },
          title: {
            backgroundColor: blueGrey[900],
            color: grey[50],
            alignItems: 'center',
            fontSize: 10,
            ml: 120,
          },
          content: {
            color: grey[900],
            fontSize: 12,
          },
        };
    }
    
    handleConfirm() {
        this.props.proceedFunction(this.props.proceedArg);
        this.handleClose();
    }
        
    handleClose() {
        this.props.closeDialogMessage(true);
    };
  
    render(){
        return (
            <div>
              <Dialog
                open={this.props.openDialog}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle style={this.classes.title} id="alert-dialog-title">{this.props.dialogTitle}</DialogTitle>
                <DialogContent >
                  <DialogContentText style={this.classes.content} id="alert-dialog-description">
                  {this.props.dialogMessage}
                  </DialogContentText>
                </DialogContent>
                  {this.props.confirmMessage ? ( 
                <DialogActions>
                  <Button onClick={this.handleClose.bind(false)} color="primary" autoFocus>
                    Cancel
                  </Button>
                  <Button onClick={this.handleConfirm} color="primary">
                    Continue
                  </Button>
                </DialogActions>
                  )
                  :(
                <DialogActions>
                  <Button onClick={this.handleClose.bind(false)} color="primary">
                    Ok
                  </Button>
                </DialogActions>
                  )}
              </Dialog>
            </div>
        );
    }
}
