import React, { useState } from 'react';
import classnames from 'classnames';

import './Header.scss';
import '../Formatter.scss';

const getClass=(value)=>
{
    if (value < 0)
    {
        return "number negativedata";
    }
    return "number positivedata";
}
    
const Header = props => {
  const {logoLink, imgAlt, counter, segmentCurrent, segmentTotal, dayCurrent, dayTotal, challengeName, topPerformers, bottomPerformers, portfolioValue, cashAvailable, totalValue, quizBonus, beta, portofolioReturn, signOutClick, addCardClick, openWorkspaceClick, restoreWorkspaceClick, saveWorkspaceClick, saveWorkspaceAsClick, openChallengeClick, referenceToggleName, referenceClick, sendMessageClick, viewRankingClick, openDoc, username } = props;

  const headerClassnames = classnames('app_header')
  const [ toggleMenu, setToggleMenu ] = useState(false);

  const toggleMenuClick = () => {
    setToggleMenu(!toggleMenu)
  }
  
  const menuDropdownClass = classnames('menu_dropdown', {"active": toggleMenu})
  return (
    <header className={headerClassnames}>
      <div className="logo">
        <a href="/"><img src={logoLink} alt={imgAlt} /></a>
      </div>
      <div className="counter_contain">
        <div className="day_section">
          <p className="segment"><span className="label">Segment</span><span className="number">{segmentCurrent}/{segmentTotal}</span></p>
          <p className="day"><span className="label">Day</span><span className="number">{dayCurrent}/{dayTotal}</span></p>
        </div>
        <div className="count_section">
          <p className="count" id="countdowntimer">{counter}</p>
        </div>
        <div className="portfolio_info">
            <div className="day_section">
              <p className="segment"><span className="label">Portfolio Value</span><span className="number">${portfolioValue}</span></p>
              <p className="day"><span className="label">Cash Available</span><span className="number">${cashAvailable}</span></p>
              <p className="day"><span className="label">Total Value</span><span className="number">${totalValue}</span></p>
            </div>
        </div>
        <div className="return_info">
            <div className="day_section">
              <p className="segment"><span className="label">Quiz Bonus</span><span className="number">${quizBonus}</span></p>
              <p className="day"><span className="label">Beta</span><span className="number">{beta}</span></p>
              <p className="day"><span className="label">Return</span><span className={getClass(portofolioReturn)}> {portofolioReturn}%</span></p>
            </div>
        </div>        
        <div className="top_bottom_performers">
            {(topPerformers !== undefined) ? (
            <div className="top_performers">
            { 
                    topPerformers.map((item, i) => (
                    <p className="performers">
                        <span className="label">{item.k}</span>
                        <span className="number">{item.v}</span>
                    </p>
                    ))
            }
            </div>
            ) : (<span></span>)}
            {(topPerformers !== undefined) ? (
            <div className="bottom_performers">
            { 
                    bottomPerformers.map((item, i) => (
                    <p className="performers">
                        <span className="label">{item.k}</span>
                        <span className="number">{item.v}</span>
                    </p>
                    ))
            }
            </div>
            ) : (<span></span>)}
        </div>
      </div>
      <div className="tab_button">
      <span className="challengelabel"><p></p>[ {username} ] &nbsp; &nbsp; {challengeName}</span>
      <button className="header_button" onClick={referenceClick}>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book-open" className="svg-inline--fa fa-book-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"></path></svg>
        <span className="button_label">{referenceToggleName}</span>
      </button>
      <div className="menu_button">
        <button className="header_button" onClick={toggleMenuClick}>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
          <span className="button_label">Menu</span>
        </button>

          <ul className={menuDropdownClass} onClick={toggleMenuClick}>
            <li>
              <button className="header_button dropdown_item" onClick={referenceClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book-open" className="svg-inline--fa fa-book-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"></path></svg>
                <span className="button_label">{referenceToggleName}</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={addCardClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                <span className="button_label">Add Card</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={openWorkspaceClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book-open" className="svg-inline--fa fa-book-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"></path></svg>
                <span className="button_label">Open Workspace</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={saveWorkspaceClick}>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="save" className="svg-inline--fa fa-save fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"></path></svg><span className="button_label">Save Workspace</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={saveWorkspaceAsClick}>
              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="save" className="svg-inline--fa fa-save fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM272 80v80H144V80h128zm122 352H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h42v104c0 13.255 10.745 24 24 24h176c13.255 0 24-10.745 24-24V83.882l78.243 78.243a6 6 0 0 1 1.757 4.243V426a6 6 0 0 1-6 6zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 128c-22.056 0-40-17.944-40-40s17.944-40 40-40 40 17.944 40 40-17.944 40-40 40z"></path></svg>
              <span className="button_label">Save Workspace as</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={restoreWorkspaceClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="redo-alt" className="svg-inline--fa fa-redo-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"></path></svg>
                <span className="button_label">Restore Workspace</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={openChallengeClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="folder-open" className="svg-inline--fa fa-folder-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M572.694 292.093L500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z"></path></svg>
                <span className="button_label">Open Challenge</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={sendMessageClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book-open" className="svg-inline--fa fa-book-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"></path></svg>
                <span className="button_label">Send Message...</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={viewRankingClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book-open" className="svg-inline--fa fa-book-open fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"></path></svg>
                <span className="button_label">View Rankings</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={openDoc}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book" className="svg-inline--fa fa-book fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M1639 1058q40 -57 18 -129l-275 -906q-19 -64 -76.5 -107.5t-122.5 -43.5h-923q-77 0 -148.5 53.5t-99.5 131.5q-24 67 -2 127q0 4 3 27t4 37q1 8 -3 21.5t-3 19.5q2 11 8 21t16.5 23.5t16.5 23.5q23 38 45 91.5t30 91.5q3 10 0.5 30t-0.5 28q3 11 17 28t17 23 q21 36 42 92t25 90q1 9 -2.5 32t0.5 28q4 13 22 30.5t22 22.5q19 26 42.5 84.5t27.5 96.5q1 8 -3 25.5t-2 26.5q2 8 9 18t18 23t17 21q8 12 16.5 30.5t15 35t16 36t19.5 32t26.5 23.5t36 11.5t47.5 -5.5l-1 -3q38 9 51 9h761q74 0 114 -56t18 -130l-274 -906 q-36 -119 -71.5 -153.5t-128.5 -34.5h-869q-27 0 -38 -15q-11 -16 -1 -43q24 -70 144 -70h923q29 0 56 15.5t35 41.5l300 987q7 22 5 57q38 -15 59 -43zM575 1056q-4 -13 2 -22.5t20 -9.5h608q13 0 25.5 9.5t16.5 22.5l21 64q4 13 -2 22.5t-20 9.5h-608q-13 0 -25.5 -9.5 t-16.5 -22.5zM492 800q-4 -13 2 -22.5t20 -9.5h608q13 0 25.5 9.5t16.5 22.5l21 64q4 13 -2 22.5t-20 9.5h-608q-13 0 -25.5 -9.5t-16.5 -22.5z"></path></svg>
                <span className="button_label">Documentation</span>
              </button>
            </li>
            <li>
              <button className="header_button dropdown_item" onClick={signOutClick}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" className="svg-inline--fa fa-sign-out-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
                <span className="button_label">Sign Out</span>
              </button>
            </li>
          </ul>
      </div>
      </div>
      
    </header>
  )
}

export default Header;
