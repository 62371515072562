import React from "react";
import imageFile from '../img/finEDge_logo.svg';
import './login.css';
import crypto from 'crypto'
import {setErrorMessage} from "../actions/actionCreators";

//*****************************************
// //\\ login form
//*****************************************
class Login extends React.Component {

    constructor(props)
    {
        super(props);
        this.processLogin       = this.processLogin.bind(this);
        this.sendLogin          = this.sendLogin.bind(this);
        this.saveCookie         = this.saveCookie.bind(this);
    }
    processLogin()
    {
                console.log("processLogin....")
        let username = document.getElementById("username").value;
        let upassword = document.getElementById("password").value;
        let passwdHashHex = crypto.createHash('sha1').update(upassword).digest('hex')
        this.sendLogin(username, passwdHashHex);
    }
    
    sendLogin(username, passwdHashHex)
    {
        
        let self_ = this;
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function(){
        if (this.readyState == 4 && this.status == 200) 
        {
            var parsed = JSON.parse(this.responseText);
            console.log(parsed);
            if (parsed.status === 0)
            {
                self_.saveCookie(parsed);
                console.log("redirecting....")
                window.location.reload(true);
            }
            else
            {
                let errormessage = "Invalid username or password";
                document.getElementById("login.errormsg").innerHTML = errormessage;
                self_.props.store.dispatch(setErrorMessage(errormessage));
            }    
        }
        };
        let login = (window.location.hostname !== "localhost") ? "/auth/login" : "https://finedge.leveltradingfield.com/auth/login";
        xhttp.open("POST", login, true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send("username="+username+"&password="+passwdHashHex);  
        
        this.setState({openSnack: true});
        console.log(" sent login details", "sent");
    }
    
    saveCookie(ck)
    {
        /* 
         * expire cookie after browser closes (omit the expire)
        */
        /*
        var d = new Date();
        d.setTime(d.getTime() + (360*24*60*60*1000));
        var expires = ";expires="+ d.toUTCString();
        document.cookie = "sessiontoken=" + ck.sessiontoken + expires + ";path=/";
        document.cookie = "userinfo=" + JSON.stringify(ck.userinfo) + expires + ";path=/";
        */
        document.cookie = "sessiontoken=" + ck.sessiontoken + ";path=/";
        document.cookie = "userinfo=" + JSON.stringify(ck.userinfo) + ";path=/";
        console.log(" saved cookie. sessiontoken=" + ck.sessiontoken 
                        + "userinfo=" + JSON.stringify(ck.userinfo) 
                        + "json received: " + JSON.stringify(ck) + " try rerunning", "sent");
    }
    
    render() {
        const image = {
            src: imageFile,
            alt: 'Finedge, Navigating Financial Decisions',
        };

        return (
            <div className="log-form">            
                <div className="errormsg" id="login.errormsg"></div><p/>
                <div className="logo">
                    <a href="/"><img src={image.src} alt={image.alt} /></a>
                </div>  
                <input type="text" id="username"
                       title="username" placeholder="username" />
                <input type="password" id="password"
                       title="password" placeholder="password" />
                <button className="submit btn" onClick={this.processLogin}>Login</button>
                    {
                        //<button className="forgot">Forgot Username?</button> -->
                    }
            </div>
        );
    }
}
export default Login;
//*****************************************
// \\// login form
//*****************************************

