import React from "react";

import MobileCardLayoutContainer from "../containers/MobileCardLayoutContainer";

export default class MobileMainLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="maingrid">
        <MobileCardLayoutContainer/>
      </div>
    );
  }
}