import React from "react";

import CardGridLayoutContainer from "../containers/CardGridLayoutContainer";

export default class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { layout: [] };
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  onLayoutChange(layout) {
    this.setState({ layout: layout });
  }

  render() {
    return (
      <div className="maingrid">
        <CardGridLayoutContainer onLayoutChange={this.onLayoutChange} />
      </div>
    );
  }
}