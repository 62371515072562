import React from 'react';
import ReactDataGrid from 'react-data-grid';

export default class Transactions extends React.Component {
          
  constructor(props) {
    super(props);
    
    const defaultColumnProperties = {
      resizable: true
    };
    this.state = {
        scolumns : [
              { key: 'indicatorname', name: 'Name', width: 130}
           //   ,{ key: 'trend', name: 'Trend', width: 80 } 
              ,{ key: 'threemonth', name: '-3M', width: 80 } 
              ,{ key: 'twomonth', name: '-2M', width: 80 } 
              ,{ key: 'onemonth', name: '-1M', width: 80 } 
              ,{ key: 'start', name: 'Start'} 
              ].map(c => ({ ...c, ...defaultColumnProperties }))  
    }        
  }
  render(){
       return (
            <ReactDataGrid  columns={this.state.scolumns}
                      rowGetter={i => this.props.economicindicators[i]}
                      rowsCount={this.props.economicindicators.length}
                      minHeight={200} 
                      headerRowHeight={20}
                      rowHeight={28}
            />
  );
  }
}
