import React from 'react';
import _ from "lodash";

class LTFSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  getOptions=()=>{      
        let optionsval = [];             
        let k = Object.keys(this.props.optionslist)
        for (var key in this.props.optionslist) {
            if (this.props.optionslist.hasOwnProperty(key)) {
                optionsval.push(<option key={key} 
                                value={this.props.optionslist[key][this.props.valueKey]}>
                                {this.props.optionslist[key][this.props.displayName]}
                            </option>) 
            }
        }
        return (optionsval);
                
  }
  
  shouldComponentUpdate(nextProps) {
    return nextProps.optionslist !== this.props.optionslist;
  }
  
  render() {
    return (
            <select id={this.props.selectid} onChange={this.props.onchangecb}>
                {this.getOptions()}
            </select>
    )
  }
}

export default LTFSelect;