import React from 'react';
import ReactDataGrid from 'react-data-grid';
import LTFSelect from "../components/LTFSelect";
import PctDisplay from '../components/PctDisplay';
import CommaFormatter from '../components/CommaFormatter';
import DecimalFormatter from '../components/DecimalFormatter';
import _ from "lodash";

import Chart, {
  CommonSeriesSettings
  ,Series
  ,ValueAxis
  ,ArgumentAxis
  ,Legend
  ,Tooltip
  ,Title
  ,Grid
  ,Format
  ,ZoomAndPan
} from 'devextreme-react/chart';

export default class MobileStockDetails extends React.Component {
          
    constructor(props) {
        super(props);

        this.palette =['#F44336','#c3a80f','#4fc620']; 
        this.state = {
            lastDrawLocation: null
            ,crosshairValues: []
            ,filteredRows : props.allEquitiesRows
        };

        this.stockSelected = this.stockSelected.bind(this);         
        this.onEquityRowClicked     = this.onEquityRowClicked.bind(this);   

    }  
    
    getCols()
    {
        let columns = [
			{ key: 'equityname', name: 'Name' , width: 80, frozen:true, sortable: true} 
                ,{ key: 'lastprice', name: 'Price', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
                ,{ key: 'startprice', name: 'Start Px', width: 60, formatter:<DecimalFormatter/>, sortable: true} 
                ,{ key: 'dailyreturn', name: 'Daily Return', width: 80, formatter:<PctDisplay/>, sortable: true} 
                ,{ key: 'volume', name: 'Volume', width: 59, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'company', name: 'Company', width: 130, sortable: true} 
          ,{ key: 'marketcapcategory', name: 'Category', width: 50, sortable: true} 
          ,{ key: 'marketcap', name: 'Market Cap', width: 50, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'avgvolume', name: 'Avg Volume', width: 50, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'peratio', name: 'P/E Ratio', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'ninetydayvol', name: '90 Day Vol', width: 50, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'thirtydayavg', name: '30 Day Avg', width: 50, formatter:<CommaFormatter/>, sortable: true} 
          ,{ key: 'fiftytwoprice', name: '52 wk Price', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'fiftytworeturn', name: '52 Return', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'periodreturn', name: 'Period Return', width: 50, formatter:<PctDisplay/>, sortable: true} 
          ,{ key: 'yield', name: 'Yield', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'beta', name: 'Beta', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
          ,{ key: 'eps', name: 'EPS', width: 50, formatter:<DecimalFormatter/>, sortable: true} 
            ].map(c => ({ ...c, ...this.defaultColumnProperties }));
        return columns;
    }
       
    pctFormatter(value)
    {
        return `${value}%`;
    }
    
    
    shouldComponentUpdate(nextProps, nextState)
    {
        let returnVal = false;
        if (!_.isEqual( this.props.allEquitiesRows, nextProps.allEquitiesRows ))
        {
            this.setState({filteredRows : nextProps.allEquitiesRows});
            returnVal = true;
        }
        if (!_.isEqual( this.state.filteredRows, nextState.filteredRows))
        {
            returnVal = true;
        }
		if (!_.isEqual(this.props.stockDetail, nextProps.stockDetail))
		{
			returnVal = true;
		}
        return returnVal;
    }
    
    customizeTooltip(pointInfo) 
    {
        const items = pointInfo.valueText.split('\n');
        const color = pointInfo.point.getColor();

        items.forEach((item, index) => {
        if(item.indexOf(pointInfo.seriesName) === 0) {
          const element = document.createElement('span');

          element.textContent = item;
          element.style.color = color;
          element.className = 'active';

          items[index] = element.outerHTML;
        }
        });

        return { text: items.join('\n') };
    }
 
    stockSelected() {   
        let selectOption = document.getElementById("sd_select").value;
        let equity = this.props.allSecurities[selectOption];
        if (equity === undefined)
        {
            return;
        }
        this.props.setSecurityDetail(equity);
        let data = {"action":"getsecuritystatsforchallenge"
                    ,"param":{"equityid":equity.equityid
                            , "challengeid": this.props.currentChallenge.challengeid
                   }}
        this.props.getSecurityStatsForChallenge(data);
    }
    
    onEquityRowClicked(row)
    {
        let equity = this.props.allEquitiesRows[row];
        console.log("onGridRowClicked "+JSON.stringify(equity));
        if (equity === undefined)
        {
            return;
        }
        this.props.setSecurityDetail(equity);
        let data = {"action":"getsecuritystatsforchallenge"
                    ,"param":{"equityid":equity.equityid
                            , "challengeid": this.props.currentChallenge.challengeid
                   }}
        this.props.getSecurityStatsForChallenge(data);
    }
    
    onLegendClick({ target: series }) 
    {
        if(series.isVisible()) 
        {
            series.hide();
        } 
        else 
        {
            series.show();
        }
    }
    
    sortRows(initialRows, sortColumn, sortDirection)
    { 
        this.setState({filteredRows: this.sortNow(initialRows, sortColumn, sortDirection)});
    }
    sortNow(initialRows, sortColumn, sortDirection)
    {
        let rows = initialRows;
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } 
            else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return (sortDirection === "NONE" ? initialRows : [...rows].sort(comparer));
    }
    
    render(){
       return (
        <div className="mobile_layer_card">
            <div className="mobileLayer">
              <ReactDataGrid  columns={this.getCols()}
                          rowGetter={i => this.state.filteredRows[i]}
                          rowsCount={this.state.filteredRows.length}
                          minHeight={230} 
                          rowHeight={20}
                          onRowClick={this.onEquityRowClicked}
                          onGridSort={(sortColumn, sortDirection) =>{
                            this.sortRows(this.state.filteredRows,sortColumn, sortDirection)}
                          }
                />
            </div>
			<div  className="mobileLayer">
                {this.props.stockDetail.hasOwnProperty("equityid") ?
                (<div className="stock_info_contain"><p/>
                    <span className="stock_title">{this.props.stockDetail.equityname}</span><p/>
                    <div className="sd_section">
                        <span className="sd_label"> P/E Ratio:</span><span className="sd_data"> {this.props.stockDetail.peratio.toLocaleString()}</span>
                        <span className="sd_label"> EPS:</span><span className="sd_data"> {this.props.stockDetail.eps.toLocaleString()}</span>
                        <span className="sd_label"> Market Cap:</span><span className="sd_data"> {this.props.stockDetail.marketcap.toLocaleString()}</span>
                        <span className="sd_label"> Avg Volume:</span><span className="sd_data"> {this.props.stockDetail.avgvolume.toLocaleString()}</span>
                        <span className="sd_label"> Beta:</span><span className="sd_data"> {this.props.stockDetail.beta.toLocaleString()}</span>
                    </div><p/>
                    <div className="sd_description">
                        description of stock: {this.props.stockDetail.longdescription}
                    </div>
                </div>
                ) : (<span></span>)
                }
			</div>
            <div className="mobileLayer">
            <Chart
                    id="StockDetailsChart"
                    palette="Vintage"
                    onLegendClick={this.onLegendClick}
                    dataSource={this.props.stockStats}
                    commonSeriesSettings={{
                        argumentField:"day"
                        ,point:{visible: false}
                        ,barPadding:0.1
                    }}
                    >
                    <Series
                      axis="volVal"
                      type="bar"
                      valueField="volume"
                      name="Volume"
                      color="#E6ECBB"
                    />
                    <Series
                      axis="returnPct"
                      type="spline"
                      valueField="closingprice"
                      name={this.props.stockDetail.equityname !== undefined ? this.props.stockDetail.equityname : "Equity" }
                      color="#8BC34A"
                    />
                    <ValueAxis
                      name="returnPct"
                      position="left"
                      title={{text:"Return", font:{size: "11px"}}}
                      label={{format:this.pctFormatter}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ValueAxis
                      name="volVal"
                      position="right"
                      title={{text:"Volume", font:{size: "11px"}}}
                    >
                      <Grid visible={true} />
                    </ValueAxis>
                    <ArgumentAxis
                      name="returnArg"
                      minorTickInterval={1}
                      defaultVisualRange={{ length: 20 }} 
                      visualRangeUpdateMode={"auto"}/>
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                      font={{size: "11px"}}
                    />
                    <Tooltip
                      enabled={true}
                      shared={true}
                      customizeTooltip={this.customizeTooltip}
                    >
                      <Format
                        type="largeNumber"
                        precision={1}
                      />
                    </Tooltip>
                    <ZoomAndPan argumentAxis="both" />
            </Chart>
            </div>
        </div>
    );
    }
}
