import React from 'react';
import { Toolbar, Data, Filters} from "react-data-grid-addons";
import ReactDataGrid from 'react-data-grid';
import _ from "lodash";
//import DataGrid from 'devextreme-react/data-grid';

export default class News extends React.Component {
          
  constructor(props) {
    super(props);
    const divHighlight = {
      backgroundColor: '#FFFF99'
    };
    const blank = {
    };
    const defaultColumnProperties = {
      resizable: true
      ,sortable: true
      ,filterable: true
    };
    const selectors = Data.Selectors;
    const {
      NumericFilter,
      AutoCompleteFilter,
      MultiSelectFilter,
      SingleSelectFilter
    } = Filters;
    
    this.state = {
        scolumns : [
              { key: 'day', name: 'Day', width: 80, filterRenderer: this.NumericFilter}
              ,{ key: 'newstype', name: 'Type', width: 80, filterRenderer: this.MultiSelectFilter} 
              ,{ key: 'source', name: 'Source', width: 80, filterRenderer: this.MultiSelectFilter} 
              ,{ key: 'sector', name: 'Sector', width: 80, filterRenderer: this.MultiSelectFilter} 
              ,{ key: 'ticker', name: 'Ticker', width: 50, filterRenderer: this.AutoCompleteFilter} 
              ,{ key: 'title', name: 'Title', width: 250, filterRenderer: this.AutoCompleteFilter} 
              ,{ key: 'article', name: 'Article', width: 1250, filterRenderer: this.AutoCompleteFilter} 
              ].map(c => ({ ...c, ...defaultColumnProperties }))  
        ,filters : {}
        ,filteredRows : this.props.news
    }   

    
    this.RowRenderer            = this.RowRenderer.bind(this); 
    this.getValidFilterValues   = this.getValidFilterValues.bind(this);    
    this.handleFilterChange     = this.handleFilterChange.bind(this);      
    this.getRows                = this.getRows.bind(this);       
    this.clearFilters           = this.clearFilters.bind(this);           
    this.sortRows              = this.sortRows.bind(this);
    
    console.log("constructo called");
  }
  
    onHeaderDrop = (source, target) => {
        console.log("dropped");
        const stateCopy = Object.assign({}, this.state);
        const columnSourceIndex = this.state.columns.findIndex(
          i => i.key === source
        );
        const columnTargetIndex = this.state.columns.findIndex(
          i => i.key === target
        );

        stateCopy.columns.splice(
          columnTargetIndex,
          0,
          stateCopy.columns.splice(columnSourceIndex, 1)[0]
        );

        const emptyColumns = Object.assign({}, this.state, { columns: [] });
        this.setState(emptyColumns);

        const reorderedColumns = Object.assign({}, this.state, {
          columns: stateCopy.columns
        });
        this.setState(reorderedColumns);
    }
    
    RowRenderer({ renderBaseRow, ...rowprops }){
        let found = this.props.holding.find(equity => equity.equityid === rowprops.row.equityid);
        let data = renderBaseRow(rowprops);
        let color = found ? "#FFEB3B" : "";
        return <div style={{ color: color }}>{renderBaseRow(rowprops)}</div>;
    }
    
    handleFilterChange(filter) {
        this.setState(state=>
        {
              const newFilters = { ...state.filters };
              if (filter.filterTerm) {
                newFilters[filter.column.key] = filter;
              } else {
                delete newFilters[filter.column.key];
              }
              return {filters: newFilters};
        });
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        let returnVal = false;
        if (!_.isEqual( this.props.news, nextProps.news ))
        {
            let filters = this.state.filters;
            this.setState({filteredRows : this.getRows(nextProps.news, filters)});
        }
        if (!_.isEqual( this.state.filteredRows, nextState.filteredRows ))
        {
            returnVal = true;
        }
        if (!_.isEqual( this.state.filters, nextState.filters ))
        {
            this.setState({filteredRows : this.getRows(nextProps.news, nextState.filters)});
            returnVal = true;
        }
        return returnVal;
    }
    
    clearFilters(filter) {
        this.setState(state=>
        {{filters: {}}
        });
    }
        
    sortRows(initialRows, sortColumn, sortDirection)
    {
        let rows = initialRows;
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } 
            else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        this.setState({filteredRows: (sortDirection === "NONE" ? initialRows : [...rows].sort(comparer))});
    }
    
    getRows(rows, filters) {
        return Data.Selectors.getRows({ rows, filters });
    }
    
    getValidFilterValues(rows, columnId) {
        return rows
        .map(r => r[columnId])
        .filter((item, i, a) => {
          return i === a.indexOf(item);
        });
    }
    
    render(){
       return (
            <div className="card_full_contain news">
                <ReactDataGrid  columns={this.state.scolumns}
                              rowGetter={i => this.state.filteredRows[i]}
                              rowsCount={this.state.filteredRows.length}
                              minHeight={200}
                              headerRowHeight={20}
                              rowHeight={28}
                              rowRenderer={this.RowRenderer}
                              toolbar={<Toolbar enableFilter={true} />}
                              onAddFilter={filter => this.handleFilterChange(filter)}
                              onClearFilters={() => this.clearFilters({})}
                              getValidFilterValues={columnKey => this.getValidFilterValues(this.props.news, columnKey)}                              
                              onGridSort={(sortColumn, sortDirection) =>{
                                    this.sortRows(this.state.filteredRows,sortColumn, sortDirection)}}
                    />
                </div>

  );
  }
}
