import { connect } from 'react-redux'
import LTFDialog from "../jsx/LTFDialog";
import {closeDialogMessage} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    openDialog : state.popupDialog.openDialog
    ,dialogMessage : state.popupDialog.dialogMessage
    ,dialogTitle : state.popupDialog.dialogTitle
    ,confirmMessage : state.popupDialog.confirmMessage
    ,proceedFunction : state.popupDialog.proceedFunction
    ,proceedArg : state.popupDialog.proceedArg
  }
}

const mapDispatchToProps = dispatch => {
    return {
        closeDialogMessage: data => {
            dispatch(closeDialogMessage(data))
        }
    }
}   

const LTFDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LTFDialog)

export default LTFDialogContainer
