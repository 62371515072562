import { connect } from 'react-redux'
import MobileRankings from "../jsx/client/MobileRankings";
import {closeSnackMessage, showRankings, showNonCompetitve} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    currentChallenge: state.challenge.currentChallenge
    ,rankings: state.rankings
  }
}

const mapDispatchToProps = dispatch => {
    return {
        showRankings: data => {
          dispatch(showRankings(data))
        }
        ,showNonCompetitve: data => {
          dispatch(showNonCompetitve(data))
        }
    }
}   

const MobileRankingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileRankings)

export default MobileRankingsContainer
