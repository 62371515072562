import React from 'react';
import './Card.scss';

const CardHeader = props => {
  const { cardTitle, onMinimizeClick, isMaximized, onMaximizeClick, onDeleteClick, color } = props;
  const barColor = {
    borderColor: color,
    borderBottom: '1px solid #68677C'
  }
  return (
    <div className="app_card_header" style={barColor}>
      <h3>{cardTitle}</h3>
      <div className="buttons_contain">
        {isMaximized ? (
          <button onClick={onMinimizeClick} aria-label={`minimize ${cardTitle}`}>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="window-minimize" className="svg-inline--fa fa-window-minimize fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 352H48c-26.5 0-48 21.5-48 48v32c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-32c0-26.5-21.5-48-48-48z"></path></svg>
          </button>
        ) : (
          <button onClick={onMaximizeClick} aria-label={`maximize ${cardTitle}`}>
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="window-maximize" className="svg-inline--fa fa-window-maximize fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V192h416v234z"></path></svg>
          </button>
        )}
        <button onClick={onDeleteClick} aria-label={`delete ${cardTitle}`} > 
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
        </button>
      </div>
    </div>
  )
}

export default CardHeader;