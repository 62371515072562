import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Card from './components/Card/Card';
import {STATUS} from '../actions/actiontypes'


import MarketHistoryContainer from '../containers/MarketHistoryContainer';
import EconomicIndicatorsContainer from '../containers/EconomicIndicatorsContainer';
import TrendingStocksContainer from '../containers/TrendingStocksContainer';
import HoldingCreationContainer from '../containers/HoldingCreationContainer';
import StockDetailsContainer from '../containers/StockDetailsContainer';
import PortfolioCompositionContainer from '../containers/PortfolioCompositionContainer';
import NewsContainer from '../containers/NewsContainer';
import ReferencePeriodContainer from '../containers/ReferencePeriodContainer';
import ReferenceChartContainer from '../containers/ReferenceChartContainer';
import StockReferenceContainer from '../containers/StockReferenceContainer';

import { Responsive, WidthProvider } from "react-grid-layout";
const ResponsiveReactGridLayout = WidthProvider(Responsive);


export default class CardGridLayout extends React.Component {
    static propTypes = {
      onLayoutChange: PropTypes.func.isRequired
    };
 
    defW = 6;
    defH = 8;
    minW = 0;
    minH = 0;
    persistedLayouts;
    
    static defaultProps = {
        className: "layout",
        rowHeight: 20,
        onLayoutChange: function() {},
        cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
        ,minWidth: 0
        ,minHeight: 0
    };
    resetList = "0px";
    prevHeight = "0px";
    prevWidth = "0px";
    persistedlayoutdata;
    constructor(props) {
        super(props);
        this.persistedLayouts = {lg: this.props.appList.layout}
        this.state = {
          currentBreakpoint: "lg"
          ,compactType: "vertical"
          ,mounted: false
          ,layouts: this.persistedLayouts          
          ,dynamicAppList: this.loadDynamicApplist(this.persistedLayouts.lg)
        };

        this.resetList = this.state.dynamicAppList;
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onNewLayout = this.onNewLayout.bind(this);
    }

    loadDynamicApplist(fromList)
    {
        let newList = _.map(fromList, app=>{
                            let addExtras = {...app, onDeleteClick: this.deleteCard, onMaximizeClick: this.maximizeCard, onMinimizeClick: this.minimizeCard, isMaximized:false}; 
                            let extra;
                            switch (app.i)
                            {
                                case '10':
                                    extra = {title: "Market History", color: '#F44336', view: <MarketHistoryContainer/>}
                                break;
                                case '11':
                                    extra = {title: "Economic Indicators", color: '#1E88E5', view: <EconomicIndicatorsContainer/>}
                                break;
                                case '12':
                                    extra = {title: "Trending Stocks", color: '#FF9800', view: <TrendingStocksContainer/>}
                                break;
                                case '13':
                                    extra = {title: "Holding", color: '#9C27B0', view: <HoldingCreationContainer/>}
                                break;
                                case '14':
                                    extra = {title: "Stock Details", color: '#8BC34A', view: <StockDetailsContainer/>}
                                break;
                                case '15':
                                    extra = {title: "Portfolio Composition", color: '#FFEB3B', view: <PortfolioCompositionContainer/>}
                                break;
                                case '16':
                                    extra = {title: "News", color: '#F44336', view: <NewsContainer/>}
                                break;
                                case '17':
                                    extra = {title: "Reference Period", color: '#1E88E5', view: <ReferencePeriodContainer/>}
                                break;
                                case '21':
                                    extra = {title: "Reference Chart", color: '#1E88E5', view: <ReferenceChartContainer/>}
                                break;
                                case '22':
                                    extra = {title: "Stock Reference", color: '#8BC34A', view: <StockReferenceContainer/>}
                                break;
                            }
                            addExtras = {...addExtras, ...extra}
                            return addExtras;
                            })
        return newList        
    }
    
    componentDidMount() {
        this.setState({ mounted: true });
    }
  
    updatePropLayout()
    {
        let data = this.state.layouts.lg.map(card=>{
                                   return {x: card.x, y: card.y, w:card.w, h:card.h, i:card.i, minW:card.minW, minH:card.minH}
                                });
        this.props.changeCard(data);
    }
    
    deleteCard=(cardkey)=>{
        //this.setState({ dynamicAppList: _.filter(this.state.dynamicAppList, app => app.i!==cardkey) });  
      
        let newApplist = [] 
        for (let i=0; i < this.state.dynamicAppList.length; ++i) 
        {
            let app = this.state.dynamicAppList[i];
            if (app.i !== cardkey) 
            {
                app = {...app, w:this.defW, h:this.defH}; 
                document.getElementById(app.title).parentElement.style.display = "initial";
                newApplist.push(app)
            }   
            else
            {
                document.getElementById(app.title).parentElement.style.display = "none";
            }    
        }
        this.setState(state=>{ 
            const dynamicAppList = newApplist;
            const layouts = { lg: dynamicAppList}; 
            return{dynamicAppList, layouts}}, this.updatePropLayout);     
    }

    minimizeCard=(cardkey)=>{  
        let currentCard = null; 
        for (let i=0; i < this.state.dynamicAppList.length; ++i) 
        {
            let app = this.state.dynamicAppList[i];
            if (app.i === cardkey) 
            {
                currentCard = app
                app = {...app, x:0,y:0,w:12, isMaximized:false};   
            }
            else
            {
                document.getElementById(app.title).parentElement.style.display = "initial";
            }
        }      
      
        let source = document.getElementById(currentCard.title).parentElement;
        // leave 2% around the edges
        source.style.width = this.prevWidth;
        source.style.height = this.prevHeight;
          
        this.setState(state=>{ 
            const dynamicAppList = this.resetList;
            const layouts = { lg: dynamicAppList}; 
            return{dynamicAppList, layouts}});                
    }

    maximizeCard=(cardkey)=>{ 
        let newApplist = []
        let currentCard = null; 
        for (let i=0; i < this.state.dynamicAppList.length; ++i) 
        {
            let app = this.state.dynamicAppList[i];
            if (app.i === cardkey) 
            {
                currentCard = app
                app = {...app, x:0,y:0,w:12, isMaximized:true};  
            }
            else{
                app = {...app, w:0,h:0,minW:0, minH:0};  
              document.getElementById(app.title).parentElement.style.display = "none";
            }
            newApplist.push(app)       
        }
        this.resetList = this.state.dynamicAppList;
        this.setState(state=>{ 
                const dynamicAppList = newApplist;
                const layouts = { lg: dynamicAppList}; 
                
                return{dynamicAppList, layouts}
        });   
                
        // leave 2% around the edges
        let source = document.getElementById(currentCard.title).parentElement;
        this.prevWidth  =source.style.width;
        this.prevHeight = source.style.height;
        source.style.width = "98%";
        source.style.height = "98%";    
    }   
  
    generateDOM() {
        return this.state.dynamicAppList.map(function(appCard) {
          return (
            <div key={appCard.i} className={""}>
                <Card 
                    onDeleteClick={appCard.onDeleteClick.bind(this,appCard.i)} 
                    onMaximizeClick={appCard.onMaximizeClick.bind(this,appCard.i)} 
                    onMinimizeClick={appCard.onMinimizeClick.bind(this,appCard.i)} 
                    cardTitle={appCard.title} 
                    cardid={appCard.title}
                    isMaximized={appCard.isMaximized}
                    children={appCard.view}
                    color={appCard.color}/>          
            </div>
          );
        });
    }

    onBreakpointChange(breakpoint) {
        this.setState({
          currentBreakpoint: breakpoint
        });
    }

    onLayoutChange(layout, nlayouts) {
        //const resizeEvent = document.createEvent("HTMLEvents");
        //resizeEvent.initEvent('resize', true, false);
        //window.dispatchEvent(resizeEvent);
        //setTimeout(() => {  window.dispatchEvent(resizeEvent);
        //console.log("resize event sent"); }, 5000);
      
       // setTimeout(this.fireWindowResize, 3000);
        this.fireWindowResize();
        this.props.onLayoutChange(layout, nlayouts);
        // save here to persist layout changes evertime the user adjusts cards
        //this.persistLayout(layouts);   
            this.setState(state=>{ 
                const layouts = nlayouts; 
                return{layouts}
            });
    }
  
    onNewLayout() {
        this.setState({
            layouts: { lg: this.state.dynamicAppList }
        });
    }
    
    fireWindowResize()
    {
        var rootElem = document.documentElement;
       // rootElem.clientWidth = rootElem.clientWidth -1;
       // rootElem.clientHeight = rootElem.clientHeight -1;
      //  console.log("fireWindowResize called width = "+rootElem.clientWidth+ " height = "+rootElem.clientHeight);
        window.dispatchEvent(new Event('resize'));
    }
    
    shouldComponentUpdate(nextProps, nextState)
    {
        if (nextProps.appList.currentstatus === STATUS.RESET)
        {
            this.setState(state=>{ 
                const dynamicAppList =this.loadDynamicApplist(nextProps.appList.layout);
                const layouts = { lg: dynamicAppList}; 
                return{dynamicAppList, layouts}
            });
            this.props.setApplistStatus(STATUS.INITIAL);
            
            // if switching to reference or rankings save the previous state so we can toggle back
            if (nextProps.appList.id === "reference" || nextProps.appList.id === "rankings" )
            {
                let app = this.props.appList;
                let data = {
                    name : app.name
                    ,id : app.id
                    ,preferencetype:"client"
                    ,preferred: app.preferred
                    ,currentstatus : STATUS.INITIAL
                    ,layout :this.state.layouts.lg.map(card=>{
                               return {x: card.x, y: card.y, w:card.w, h:card.h, i:card.i, minW:card.minW, minH:card.minH}
                            })
                }
                this.props.savePreviousWorkspace(data);
            }
        }
        if (nextProps.workspace.currentstatus === STATUS.PERSIST)
        {
            let data = {
                    action:"saveworkspace"
                    ,param: {
                        name : nextProps.appList.name
                        ,id : nextProps.appList.id
                        ,preferencetype:"client"
                        ,preferred: nextProps.appList.preferred
                        ,currentstatus : STATUS.INITIAL
                        ,layout :this.state.layouts.lg.map(card=>{
                                   return {x: card.x, y: card.y, w:card.w, h:card.h, i:card.i, minW:card.minW, minH:card.minH}
                                })
                    }
            }
            this.props.saveWorkspace(data);
            this.props.setWorkspaceStatus(STATUS.INITIAL);
        }
        return true;
    }
    
    render() {
        return (
            <React.Fragment>
                <ResponsiveReactGridLayout
                  {...this.props}
                  layouts={this.state.layouts}
                  onBreakpointChange={this.onBreakpointChange}
                  onLayoutChange={this.onLayoutChange}
                  // WidthProvider option
                  measureBeforeMount={false}
                  // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                  // and set `measureBeforeMount={true}`.
                  useCSSTransforms={this.state.mounted}
                  compactType={this.state.compactType}
                  preventCollision={false}
                  draggableHandle=".app_card_header" 
                >
                  {this.generateDOM()}
                </ResponsiveReactGridLayout>
            </React.Fragment>
        );
    }
}


