import { connect } from 'react-redux'
import ReferencePeriod from "../jsx/client/ReferencePeriod";
import {getAllSecurityDefinitions,getAllReferencePeriods ,
        getSecuritiesStats,setSecurityDetail,getReferenceHoldingReturn
        ,getSecurityStatsForChallenge, getReferenceMarketIndex} from "../actions/actionCreators";

const mapStateToProps = state => {
  return {
    wsstate : state.wsocket.wsstate
    ,holdingsReference: getHoldingsReference(state.securities.universeReference, state.holding.equitiesInCurrentHolding)
    ,allReferenceEquitiesRows: state.securities.universeReference
    ,currentChallenge:          state.challenge.currentChallenge
  }
}

const getHoldingsReference =(universe, holdings) =>{
    let holdingEquity = holdings.map((equity)=>{ return equity.equityid;});
    return universe.filter(security=> holdingEquity.indexOf(security.equityid) != -1)
}

const mapDispatchToProps = dispatch => {
  return {
    loadAllSecurities: data => {
      dispatch(getAllSecurityDefinitions(data))
    }
    ,getSecuritiesStats: data => {
      dispatch(getSecuritiesStats(data))
    }
    ,setSecurityDetail: data => {
      dispatch(setSecurityDetail(data))
    }  
    ,getSecurityStatsForChallenge: data => {
      dispatch(getSecurityStatsForChallenge(data))
    }    
    ,getReferenceMarketIndex: data => {
      dispatch(getReferenceMarketIndex(data))
    }     
    ,getReferenceHoldingReturn: data => {
      dispatch(getReferenceHoldingReturn(data))
    }  
  }
}   

const ReferencePeriodContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferencePeriod)

export default ReferencePeriodContainer
