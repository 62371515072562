import React from 'react';
import {STATUS} from "../actions/actiontypes";
import * as d3 from "d3";
import classnames from 'classnames';
import './BlurScreen.scss';  

export default class BlurScreen extends React.Component { 
    constructor(props)
    {
        super(props);
        let svg = undefined;
        let i = 0;
        
        this.displayFollower = this.displayFollower.bind(this);
        this.particle = this.particle.bind(this);
    }  

    displayFollower()
    {
        let width = Math.max(960, window.innerWidth),
            height = Math.max(500, window.innerHeight);


        this.svg = d3.select("#blurscreen")
            .attr("width", width)
            .attr("height", height);

        this.rect = d3.select("#rectshape")
            .attr("width", width)
            .attr("height", height)
            .on("ontouchstart" in document ? "touchmove" : "mousemove", this.particle);
            this.i = 0;
            console.log("ivcvc = "+this.i);

    }
    
    particle() {
      let m = d3.mouse(this.svg.node());

      this.svg.insert("circle", "rect")
          .attr("cx", m[0])
          .attr("cy", m[1])
          .attr("r", 1e-6)
          .style("stroke", d3.hsl((this.i = (this.i + 1) % 360), 1, .5))
          .style("stroke-opacity", 1)
        .transition()
          .duration(2000)
          .ease(Math.sqrt)
          .attr("r", 100)
          .style("stroke-opacity", 1e-6)
          .remove();

      d3.event.preventDefault();
    }
    
    render(){
        const modalClassname= classnames("blur_everything", {"active": !(this.props.currentChallenge.challengestatus === STATUS.BLUR)});
        return (
                <div className={modalClassname}>
                    {this.displayFollower()}
                    <svg id={"blurscreen"}>
                        <rect id={"rectshape"}></rect>
                    </svg>
                    <span className="working" ></span>
                </div>
        );
    }
}


