import React from 'react';
import ReactDataGrid from 'react-data-grid';
import '../../css/table.css';
import CheckboxForTable from '../components/CheckboxForTable';
import RemoveFromTable from '../components/RemoveFromTable';
import PctDisplay from '../components/PctDisplay';
import CommaFormatter from '../components/CommaFormatter';
import DecimalFormatter from '../components/DecimalFormatter';

import {STATUS, SIDE, ORDERTYPE} from '../../actions/actiontypes'
import { ReactReduxContext } from 'react-redux'
import _ from "lodash";
import { Toolbar, Data, Filters, Draggable } from "react-data-grid-addons";
//import '../../css/react-data-grid.css';

export default class HoldingCreation extends React.Component {
          
    static contextType = ReactReduxContext;
    constructor(props) {
        super(props);
        this.defaultColumnProperties = {
            sortable: true
            ,resizable: true
        };
            
        const {
            NumericFilter,
            AutoCompleteFilter,
            MultiSelectFilter,
            SingleSelectFilter
        } = Filters;

        this.state = {
            sfilters : {}
            ,hfilters : {}
            ,filteredSRows : this.props.allEquitiesRows
            ,filteredHRows : this.props.equitiesInCurrentHolding
            ,sortColumnH: null
            ,sortColumnS: null
            ,sortDirectionS: null
            ,sortDirectionH: null
        };
        
        this.onGridRowsUpdated      = this.onGridRowsUpdated.bind(this);           
        this.onEquityRowClicked     = this.onEquityRowClicked.bind(this);            
        this.onHoldingRowClicked    = this.onHoldingRowClicked.bind(this);     
        this.onGridRowClicked       = this.onGridRowClicked.bind(this);
        this.tradeOut               = this.tradeOut.bind(this);          
        this.sortSRows              = this.sortSRows.bind(this);
        this.sortHRows              = this.sortHRows.bind(this);
        this.sortNow                = this.sortNow.bind(this);
        this.removeHolding          = this.removeHolding.bind(this);
        this.setHRows               = this.setHRows.bind(this);
        this.setSRows               = this.setSRows.bind(this);
    }    
        
    getHoldingCols()
    {  
        let columns = [
          { key: 'equityid', name: 'Remove', formatter:<RemoveFromTable/>, events:{
                                                                              onClick:(ev,data)=>{this.handleRemove(ev, data); }}, width: 55
																			  ,filterable: true, filterRenderer: this.NumericFilter}
          ,{ key: 'equityname', name: 'Name' , width: 80,filterable: true, filterRenderer: this.AutoCompleteFilter}
          ,{ key: 'company', name: 'Company', width: 160,filterable: true, filterRenderer: this.AutoCompleteFilter} 
          ,{ key: 'sector', name: 'Sector', width: 50,filterable: true, filterRenderer: this.MultiSelectFilter} 
          ,{ key: 'lastprice', name: 'Price', width: 50,filterable: true, formatter:<DecimalFormatter/>, filterRenderer: this.NumericFilter} 
          ,{ key: 'quantity', name: 'Quantity', width: 50, editable: true, formatter:<CommaFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'pctportfolio', name: '% of Portfolio', width: 50, editable: true, formatter:<PctDisplay/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'value', name: 'Value', width: 50, editable: true, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'segmentpl', name: 'Segment P&L', width: 100, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'totalpl', name: 'Total P&L', width: 100, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ].map(c => ({ ...c, ...this.defaultColumnProperties }));  
          
        return columns;
    }
    
    getCols()
    {
        let columns = [
          { key: 'equityid', name: 'Add', frozen:true, formatter:<CheckboxForTable checkClass={this.props.equityCheckClass}/>, events:{
                                                                              onClick:(ev,data)=>{this.handleChange(ev, data); }}, width: 45
																			  ,filterable: true, filterRenderer: this.NumericFilter}
          ,{ key: 'equityname', name: 'Name' , width: 80, frozen:true,filterable: true, filterRenderer: this.AutoCompleteFilter}
          ,{ key: 'company', name: 'Company', width: 160, frozen:true,filterable: true, filterRenderer: this.AutoCompleteFilter} 
          ,{ key: 'sector', name: 'Sector', width: 50, filterable: true, filterRenderer: this.MultiSelectFilter} 
          ,{ key: 'marketcapcategory', name: 'Category', width: 50,filterable: true, filterRenderer: this.MultiSelectFilter} 
          ,{ key: 'startprice', name: 'Starting Price', width: 50, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'dailyreturn', name: 'Daily Return', width: 50, formatter:<PctDisplay/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'volume', name: 'Volume', width: 50, formatter:<CommaFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'marketcap', name: 'Market Cap', width: 50, formatter:<CommaFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'avgvolume', name: 'Avg Volume', width: 50, formatter:<CommaFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'peratio', name: 'P/E Ratio', width: 50, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'ninetydayvol', name: '90 Day Vol', width: 50, formatter:<CommaFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'thirtydayavg', name: '30 Day Avg', width: 50, formatter:<CommaFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'fiftytwoprice', name: '52 wk Price', width: 50, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'fiftytworeturn', name: '52 Return', width: 50, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'lastprice', name: 'Last Price', width: 50, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'periodreturn', name: 'Period Return', width: 50, formatter:<PctDisplay/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'yield', name: 'Yield', width: 50, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'beta', name: 'Beta', width: 50, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ,{ key: 'eps', name: 'EPS', width: 50, formatter:<DecimalFormatter/>,filterable: true, filterRenderer: this.NumericFilter} 
          ].map(c => ({ ...c, ...this.defaultColumnProperties }));
          
        return columns;
    }
       
    handleChange (ev,data)  {    
        const idx = data.rowIdx;
        const equitydata = this.state.filteredSRows[idx];
        if (this.props.removed.has(equitydata.equityid))
        {            
            this.props.showHiddedSecuritiesInHolding(equitydata);
            const remainData = this.props.equitiesInCurrentHolding.filter(item => !this.props.removed.has(item.equityid));
            this.setHRows(remainData);
        }
        else
        {
            const pudata = this.state.filteredHRows.filter(item => item.equityid === equitydata.equityid)
            if  (pudata.length == 0)
            {
                this.props.addEquitiesToHolding(equitydata);           
            }
            else
            {
                this.checkPosition(pudata[0]);
                //this.props.removeEquitiesFromHolding(equitydata);
            }
        }
    }
          
    savePortfolioUniverse=()=>{
        let equities = this.props.equitiesInCurrentHolding.map(function(equity, i) {
            return equity.equityid;
        });

         const pname = document.getElementById('portfolioUniverseName').value;
         let data = {action:"createportfoliouniverse"
                        ,param:{"portfolioname":pname, "equities":equities}};
        this.props.onCreatePortfolioClick(data);
    }
 
    handleRemove (ev,data)  {   
        const idx = data.rowIdx;
        const equitydata = this.state.filteredHRows[idx];   
        this.checkPosition(equitydata)
            //this.props.removeEquitiesFromHolding(equitydata);
    }

    
    shouldComponentUpdate(nextProps, nextState)
    {
        let d = this.props.removed.values() ;
        let c = nextProps.removed.values() ;
        let returnVal = false;
        if (!_.isEqual(this.props.removed, nextProps.removed))
        {
            const remainData = nextProps.equitiesInCurrentHolding.filter(item => !nextProps.removed.has(item.equityid));
            this.setHRows(remainData);
            returnVal = true;
        }
        if (!_.isEqual( this.props.allEquitiesRows, nextProps.allEquitiesRows ))
        {
            this.setSRows(nextProps.allEquitiesRows);
            returnVal = true;
        }
        if (!_.isEqual( this.props.equitiesInCurrentHolding, nextProps.equitiesInCurrentHolding ))
        {
            const remainData = nextProps.equitiesInCurrentHolding.filter(item => !nextProps.removed.has(item.equityid));
            this.setHRows(remainData);
            returnVal = true;
        }
        if (!_.isEqual( this.state.filteredSRows, nextState.filteredSRows )
            || !_.isEqual( this.state.filteredHRows, nextState.filteredHRows ))
        {
            returnVal = true;
        }
        if (!_.isEqual( this.state.sfilters, nextState.sfilters ))
        {
            this.setSRows(nextProps.allEquitiesRows);
            returnVal = true;
        }
        if (!_.isEqual( this.state.hfilters, nextState.hfilters ))
        {
            const remainData = nextProps.equitiesInCurrentHolding.filter(item => !nextProps.removed.has(item.equityid));
            this.setHRows(remainData);
            returnVal = true;
        }
        return returnVal;
    }
    
    checkPosition(equitydata)
    {
        if (!(equitydata.hasOwnProperty("quantity") 
            && equitydata.quantity !== undefined
            && equitydata.quantity > 0
        ))
        {
            this.removeHolding(equitydata);
            return;
        }
        
        let dialogData = {
            title: "Trade out?"
            ,message: "You can only remove a holding if the quantity is zero. Are you sure you want to sell: "
                        +equitydata.quantity+" shares of "+equitydata.equityname+"?"
            ,proceedFunction: this.tradeOut
            ,proceedArg: equitydata
        }
        this.props.openDialogMessage(dialogData);  
    }
    
    removeHolding(equitydata)
    {
        this.props.removeEquitiesFromHolding(equitydata);        
        const remainData = this.state.filteredHRows.filter(item => item.equityid !== equitydata.equityid);
        this.setHRows(remainData);  
    }
    
    tradeOut(equitydata)
    {
        this.executeTrade(equitydata, equitydata.quantity, SIDE.ASK); 
        this.removeHolding(equitydata);       
    }    
    
    sortSRows(initialRows, sortColumn, sortDirection)
    { 
        this.setState({
            filteredSRows: this.sortNow(initialRows, sortColumn, sortDirection)
            ,sortColumnS: sortColumn
            ,sortDirectionS: sortDirection
        });
    }
  
    sortHRows(initialRows, sortColumn, sortDirection)
    { 
        this.setState({
            filteredHRows: this.sortNow(initialRows, sortColumn, sortDirection)
            ,sortColumnH: sortColumn
            ,sortDirectionH: sortDirection
        });
    }
  
    sortNow(initialRows, sortColumn, sortDirection)
    {
        let rows = initialRows;
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } 
            else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return (sortDirection === "NONE" ? initialRows : [...rows].sort(comparer));
    }
    
    validateTrade(holdingdata)
    {
        if (holdingdata.pctportfolio > 10)
        {
            this.props.setWarningMessage("Single stock can't comprise more that 10% of portfolio " +holdingdata.equityname+" is "+holdingdata.pctportfolio+"%");
            holdingdata.pctportfolio = 10;
            this.setFromPctValue(holdingdata);
        }
        
        // check they arent spending more than they have
        let additionalCost = holdingdata.value;
        if (holdingdata.hasOwnProperty("oldcost"))
        {
            additionalCost = additionalCost - holdingdata.oldcost;            
        }
        if (additionalCost > this.props.cashBalance)
        {
            this.props.setWarningMessage("cash balance exceeded! resetting to quantity to reflect balance for " +holdingdata.equityname+" is "+holdingdata.pctportfolio+"%");
            holdingdata.value = this.props.cashBalance;            
            this.setFromStockValue(holdingdata);
        }
        holdingdata.oldcost = holdingdata.value;
    }
  
    setFromStockValue(holdingdata)
    {
        holdingdata.quantity = parseInt(holdingdata.value/holdingdata.lastprice);
        //update value again
        holdingdata.value = (holdingdata.quantity * holdingdata.lastprice);
        holdingdata.pctportfolio = parseInt((holdingdata.value/this.props.cash)*10000)/100.0;
    }
    
    setFromPctValue(holdingdata)
    {
        holdingdata.value = (this.props.cash *(holdingdata.pctportfolio/100));
        this.setFromStockValue(holdingdata);
    }
    
    onGridRowsUpdated({ fromRow, toRow, updated }) {    
            if (updated === undefined)
            {
                return;
            }
        for (let i = fromRow; i <= toRow; i++) 
        {
            let currentHolding = this.state.filteredHRows[i];
            let previousQty = currentHolding.hasOwnProperty("quantity") ? currentHolding.quantity : 0;
            let holdingdata = {...currentHolding, ...updated};
            if (currentHolding.lastprice === undefined)
            {
                this.props.setWarningMessage("Market isn't available for "+currentHolding.equityname);
                continue;
            }
            if (holdingdata.hasOwnProperty("quantity"))
            {
                if (isNaN(holdingdata.quantity))
                {
                    this.props.setErrorMessage(`quantity (${holdingdata.quantity}) is not a number`);
                    continue;
                }
                else if (Number(holdingdata.quantity) > 1000000000)
                {
                    this.props.setErrorMessage(`quantity (${holdingdata.quantity}) cannot be greater than 1,000,000,000`);
                    continue;
                }
                else if (Number(holdingdata.quantity) < 0)
                {
                    this.props.setErrorMessage(`quantity (${holdingdata.quantity}) cannot be less than zero`);
                    continue;
                }
            }
            if (holdingdata.hasOwnProperty("value"))
            {
                if (isNaN(holdingdata.value))
                {
                    this.props.setErrorMessage("value is not a number "+holdingdata.quantity);
                    continue;
                }
                else if (Number(holdingdata.value) < 0)
                {
                    this.props.setErrorMessage(`value (${holdingdata.value}) cannot be less than zero`);
                    continue;
                }
            }
            if (holdingdata.hasOwnProperty("pctportfolio"))
            {
                if (isNaN(holdingdata.pctportfolio))
                {
                    this.props.setErrorMessage("percent is not a number "+holdingdata.pctportfolio);
                    continue;
                }
                else if (Number(holdingdata.pctportfolio) > 11) // allow to go over 10% but not more than 11
                {
                    this.props.setErrorMessage(`percent (${holdingdata.pctportfolio}) cannot be greater than 10%`);
                    continue;
                }
                else if (Number(holdingdata.pctportfolio) < 0)
                {
                    this.props.setErrorMessage(`percent (${holdingdata.pctportfolio}) cannot be less than zero`);
                    continue;
                }
            }
            if (currentHolding.quantity !== holdingdata.quantity && holdingdata.quantity !== undefined)
            {
                holdingdata.value = (holdingdata.quantity * currentHolding.lastprice);
                holdingdata.pctportfolio = parseInt((holdingdata.value/this.props.cash)*10000)/100.0;
            }
            else if (currentHolding.pctportfolio !== holdingdata.pctportfolio && holdingdata.pctportfolio !== undefined)
            {
                if (holdingdata.pctportfolio > 100)
                {
                    this.props.setErrorMessage("Single stock can't be greater than 100% of portfolio " +holdingdata.equityname+" is "+holdingdata.pctportfolio+"%");
                    return;
                }
                this.setFromPctValue(holdingdata);
            }
            else if (currentHolding.value !== holdingdata.value && holdingdata.value !== undefined)
            {
                this.setFromStockValue(holdingdata);
            }
            
            this.validateTrade(holdingdata);
            let quantity = Math.abs(holdingdata.quantity - previousQty);
            if (quantity === 0)
            {
                this.props.setWarningMessage("cannot trade zero (0) quantity");
                return;                
            }
            let side = holdingdata.quantity > previousQty ? SIDE.BID : SIDE.ASK;
            this.executeTrade(holdingdata, quantity, side);
        }      
    }
    
    executeTrade(holdingdata, quantity, side)
    {
        let tradeData = {
                    action:           "insertorder"
                    ,param: {
                        equityid:   holdingdata.equityid
                        ,equityname:     holdingdata.equityname
                        ,company:        holdingdata.company
                        ,sector:         holdingdata.sector
                        ,lastprice:      holdingdata.lastprice
                        ,challengeid:   this.props.currentChallenge.challengeid
                        ,price:         holdingdata.price
                        ,quantity:      quantity
                        ,side:          side
                        ,tradetype:    ORDERTYPE.MARKET
                        }
        };
        
        this.props.modifyHolding(holdingdata);
        this.props.insertOrder(tradeData);
        this.props.setInfoMessage("sent order to " + (side == SIDE.BID ? "buy " : "sell ") + quantity + " of "+holdingdata.equityname);        
    }
    
    onEquityRowClicked(row)
    {
        this.onGridRowClicked(this.state.filteredSRows[row]);
    }
    
    onHoldingRowClicked(row)
    {
        this.onGridRowClicked(this.state.filteredHRows[row]);
    }
    
    onGridRowClicked(equity) {         
        if (equity === undefined)
        {
            return;
        }
        this.props.setSecurityDetail(equity);
        let data = {"action":"getsecuritystatsforchallenge"
                    ,"param":{"equityid":equity.equityid
                            , "challengeid": this.props.currentChallenge.challengeid
                   }}
        this.props.getSecurityStatsForChallenge(data);
    }
    
    handleSFilterChange(filter) {
        this.setState(state=>
        {
              const newFilters = { ...state.sfilters };
              if (filter.filterTerm) {
                newFilters[filter.column.key] = filter;
              } else {
                delete newFilters[filter.column.key];
              }
              return {sfilters: newFilters};
        });
    }

    clearSFilters(filter) {
        this.setState(state=>
        {{sfilters: {}}
        });
    }

    handleHFilterChange(filter) {
        this.setState(state=>
        {
              const newFilters = { ...state.hfilters };
              if (filter.filterTerm) {
                newFilters[filter.column.key] = filter;
              } else {
                delete newFilters[filter.column.key];
              }
              return {hfilters: newFilters};
        });
    }

    clearHFilters(filter) {
        this.setState(state=>
        {{hfilters: {}}
        });
    }

    setHRows(rows) {
        let filter = this.state.hfilters;
        let data =  Data.Selectors.getRows({ rows, filter});
        if (this.state.sortColumnH !== null)
        {
            data = this.sortHRows(data, this.state.sortColumnH, this.state.sortDirectionH);
        }
        else
        {
            this.setState({filteredHRows : data});  
        }
    }
    
    setSRows(rows) {
        let filter = this.state.sfilters;
        let data =  Data.Selectors.getRows({ rows, filter});
        if (this.state.sortColumnS !== null)
        {
            data = this.sortSRows(data, this.state.sortColumnS, this.state.sortDirectionS);
        }
        else
        {
            this.setState({filteredSRows : data});  
        }
    }
    
    getValidFilterValues(rows, columnId) {
        return rows
        .map(r => r[columnId])
        .filter((item, i, a) => {
          return i === a.indexOf(item);
        });
    }
    
    render(){
        return (
        <div className="card_half_contain holding_card">
            <div className="halfTableleft">
            <p></p><span className="label"> Stock Universe</span>
                <ReactDataGrid  columns={this.getCols()}
                          rowGetter={i => this.state.filteredSRows[i]}
                          rowsCount={this.state.filteredSRows.length}
                          minHeight={230} 
                          rowHeight={20}
                          onRowClick={this.onEquityRowClicked}
                          onCellKeyDown={this.onEquityRowClicked}
                          onGridSort={(sortColumn, sortDirection) =>{
                            this.sortSRows(this.state.filteredSRows,sortColumn, sortDirection)}
                          }
                          toolbar={<Toolbar enableFilter={true} />}
                          onAddFilter={filter => this.handleSFilterChange(filter)}
                          onClearFilters={() => this.clearSFilters({})}
                          getValidFilterValues={columnKey => this.getValidFilterValues(this.props.news, columnKey)}
                />
            </div>
            <div className="halfTableright">
            <div><p></p><span className="label">Portfolio</span>
                      <span className="label"> | Total Value: </span><span className="summary_value"> ${(this.props.holdingValue + this.props.cashBalance).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                    })} </span>
                <span className="label"> | Cash Available: </span><span className="summary_value"> ${this.props.cashBalance.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })} </span>
            </div>
            <ReactDataGrid  columns={this.getHoldingCols()}
                          rowGetter={i => this.state.filteredHRows[i]}
                          rowsCount={this.state.filteredHRows.length}
                        minHeight={200} 
                        rowHeight={20}
                        onRowClick={this.onHoldingRowClicked}
                        onCellKeyDown={this.onHoldingRowClicked}
                        onGridSort={(sortColumn, sortDirection) =>
                                this.sortHRows(this.state.filteredHRows,sortColumn, sortDirection)
                        }
                        onGridRowsUpdated={this.onGridRowsUpdated}
                        enableCellSelect={true}
                        toolbar={<Toolbar enableFilter={true} />}
                        onAddFilter={filter => this.handleHFilterChange(filter)}
                        onClearFilters={() => this.clearHFilters({})}
              />
            </div>
        </div>
    );
    }
}