import { connect } from 'react-redux'
import StockReference from "../jsx/client/StockReference";

const mapStateToProps = state => {
  return {
    stockDetail: state.securities.stockDetail
    ,referenceStats: state.securities.referenceStats
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const StockReferenceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StockReference)

export default StockReferenceContainer
