import { connect } from 'react-redux'
import MobileNews from "../jsx/client/MobileNews";

const mapStateToProps = state => {
  return {
    news: state.news
    ,holding: state.holding.equitiesInCurrentHolding
  }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}   

const MobileNewsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNews)

export default MobileNewsContainer
